import React, { useEffect, useMemo, useState } from "react";
import type { MenuProps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { attributeConstants } from "../../../../common/constants/constants";
import { ConfigMenuStyled } from "./ConfigMenuStyled";
import { useGetDetailsFormValuesPublisher } from "@am/hooks/useGetDetailsFormValuesPublisher";
import { useAttributesStore } from "@am/state/stores/attributesStore";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

export const ConfigMenu: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const activeRoute = params[attributeConstants.childRouterSelector];
  const [current, setCurrent] = useState(activeRoute ?? "");
  const getDetailsFormValuesPublisher = useGetDetailsFormValuesPublisher();
  const { isEditing } = useAttributesStore().ui;
  const { t } = useCommonUiStore();

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "options-value" && isEditing) {
      getDetailsFormValuesPublisher(() => navigate(e.key));
    } else {
      navigate(e.key);
    }

    setCurrent(e.key);
  };

  useEffect(() => {
    if (!activeRoute) return;
    setCurrent(activeRoute);
  }, [activeRoute]);

  const items: MenuProps["items"] = useMemo(() => ([
    {
      label: (
        <span data-test={dMap["menu-config"]}>{t["common.config"]}</span>
      ),
      key: "config"
    },
    {
      label: (
        <span data-test={dMap["menu-optionValue"]}>
          {t["common.optionsValue"]}
        </span>
      ),
      key: "options-value"
    }
  ]), [t]);

  return (
    <ConfigMenuStyled
      className="config-menu"
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
    />
  );
};
