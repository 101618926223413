import { useUIStateReadOnly } from "@pm/hooks/useUIState";
import React, {
  useCallback,
  useMemo,
  useState
} from "react";
import styled from "styled-components";
import { FlagChip } from "@pm/ui/molecules/FlagChip";
import { langToFlag } from "@pm/mock/flags";
import {
  Button,
  Flex,
  Table,
  TableProps,
  Tag
} from "antd";
import { useCommonStore } from "../../../../../common/domain/state/stores/useCommonStore";
import { GetLocaleResponse } from "../../../../../common/domain/valueObjects/GetLocaleResponse";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import useProductEntity from "@pm/hooks/useProductEntity";
import { GetProductResponse } from "@pm/models/ProductEntity";
import { productsApiService } from "@pm/api/ProductsApiService";
import ChannelMinimumInfo from "../../../../../common/domain/valueObjects/ChannelMinimumInfo";
import { showNotifications } from "../../../../../common/modules/showNotifications";

type Props = {
  channel: string;
}

const Container = styled.div`
  border: 1px solid #0000000F;
  background-color: white;
  border-radius: 4px;

  .header {
    padding: 12px;
    border-bottom: 1px solid var(--colorSplit, rgba(0, 0, 0, 0.06));
  }

  .table .ant-table-cell {
    background: rgba(0, 0, 0, 0.02);
  }
`;

type TableData = {
  name: string;
  code: string;
  completeness: number;
  state?: string;
}

function getCompleteness(product: GetProductResponse | null, locale: string, channel: string): number {
  const target = product?.locales?.find(x => x.channel == channel && x.locale == locale);
  return target?.completeness || 0;
}

export const ProductPublicationLocale: React.FC<Props> = (props: Props) => {
  const { channel } = props;
  const { isEditMode } = useUIStateReadOnly();
  const { productState: { product, isUpdated }, loadProduct } = useProductEntity();
  const productId = product?.id;

  const { ui } = useCommonStore();
  const { locales, channels, t } = ui;
  const channelData = channels.find(x => x.code === channel);
  const [working, setWorking] = useState(false);

  const dataSet = useMemo<TableData[]>(() => {
    return locales.map<TableData>(x => ({
      name: x.name,
      code: x.code,
      completeness: getCompleteness(product, x.code, channel),
      state: product?.status,
    }));
  }, [locales, product, channel]);

  const handlePublish = useCallback(async (locale: string) => {
    if (!productId || !channelData) return;
    if (isUpdated) {
      showNotifications(t["product.edit.cannotPublishUnsaved"], "error");
      return;
    }
    const channels: ChannelMinimumInfo[] = [{
      id: channelData.id,
      code: channelData.code,
      name: channelData.name,
    }];

    try {
      setWorking(true);
      await productsApiService.publishProduct(productId, [locale], channels);
      await loadProduct();
    } finally {
      setWorking(false);
    }
  }, [channelData, productId, loadProduct, isUpdated, t]);

  const handleUnpublish = useCallback(async (locale: string) => {
    console.log("unpublishing...", productId);
    if (!productId) return;
    try {
      setWorking(true);
      await productsApiService.unpublishProduct(productId, locale, channel);
      await loadProduct();
    } finally {
      setWorking(false);
    }
  }, [channel, productId, loadProduct]);

  const columns = useMemo(() => {
    const colmuns: TableProps<TableData>["columns"] = [
      {
        title: "Name",
        key: "value",
        dataIndex: "name",
        render: (_, r) => (
          <Flex align="center" gap={10}>
            <FlagChip marginTop={0} flag={langToFlag(r.code as any)} /> {r.name} - {r.code}
          </Flex>
        )
      },
      {
        title: "Content filled",
        key: "completeness",
        dataIndex: "completeness",
        render: (_, r) => <span>{t["product.details.contentFilled"]}: {r.completeness}%</span>
      },
      // {
      //   title: "State",
      //   key: "state",
      //   dataIndex: "state",
      //   align: isEditMode ? 'center' : 'right',
      //   render: (_, r) => <Tag color={r.state === 'published' ? 'cyan' : undefined}>{r.state}</Tag>
      // }
    ];
    if (isEditMode === true) {
      colmuns.push({
        title: "Action",
        key: "action",
        width: 20,
        render: (_, r) => (
          <Flex vertical={false} gap={10}>
            <Button icon={<EyeInvisibleFilled/>} onClick={() => handleUnpublish(r.code)} disabled={working}>{t["common.unpublish"]}</Button>
            <Button type="primary" icon={<EyeFilled/>} onClick={() => handlePublish(r.code)} disabled={working}>{t["common.publish"]}</Button>
          </Flex>
        )
      });
    }
    return colmuns;
  }, [isEditMode, t, handlePublish, handleUnpublish, working]);

  return (
    <Container>
      <Flex justify="space-between" align="center" className="header">
        <span><b>{channelData?.name}</b> - {channelData?.code}</span>
        {/* <span>0/2 Locales published => Could not count yet!</span> */}
      </Flex>
      <div className="table">
        <Table
          rowKey="code"
          columns={columns}
          dataSource={dataSet}
          pagination={false}
          showHeader={false}
        />
      </div>
    </Container>
  );
};
