import { AssetMinimumInfo, DependentValue } from "@pm/models/ProductEntity";
import { Button, Table, TablePaginationConfig } from "antd";
import pagination from "antd/es/pagination";
import React, { Key, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { VariantListItem } from "./VariantListItem";
import { useCommonUiStore } from "../../../../../common/domain/state/stores/CommonUiStore";
import ProductDetailContext from "../../pages/productDetailsPage/context"
import getLocalizedValue from "@pm/helpers/getLocalizedValue";

const Container = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }

  .custom-cell {
    padding: 8px !important;
  }

  .action-cell {
    padding: 0 !important;
    text-align: center;
  }

  .id-cell {
    width: 220px;
    word-break: break-all;

    @media screen and (max-width: 1100px) {
      width: 100px;
    }
  }

  .ant-table-cell,
  .ant-table-container {
    border-radius: 0 !important;
  }

  .ant-pagination-options {
    margin-inline-start: 8px;
  }

  .ant-table-selection-column {
    padding: 0 !important;

    .ant-checkbox-wrapper {
      display: none;
    }
  }
`;

export interface TableDataType {
  key: Key;
  name: DependentValue<string>[] | null;
  thumbnail: DependentValue<AssetMinimumInfo>[] | null;
  attributes: { label?: string; value?: string }[];
}

interface Props {
  isEdit: boolean;
  dataSource?: TableDataType[];
  loading?: boolean,
  onRowClick: (id: string) => void;
  selectedVariantId?: string;
  onDeleteVariant: (id: string) => void;
}

export const VariantsTable: React.FC<Props> = ({
  isEdit,
  dataSource,
  loading,
  selectedVariantId,
  onRowClick,
  onDeleteVariant
}) => {
  const { t, defaultLocale } = useCommonUiStore();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const productContext = useContext(ProductDetailContext);
  const effectiveLocale: string | null = productContext.locale === defaultLocale?.code ? null : productContext.locale;

  useEffect(() => {
    setSelectedRowKeys(selectedVariantId ? [selectedVariantId] : []);
  }, [selectedVariantId]);

  const paginationProps: TablePaginationConfig = {
    position: ["bottomLeft"],
    showQuickJumper: true,
    showSizeChanger: true,
    ...pagination
  };

  const columns = useMemo(() => ([
    {
      title: t["common.id"],
      dataIndex: "key",
      key: "key",
      onCell: () => ({ className: "id-cell" })
    },
    {
      title: t["product.variantsTable.variantProduct"],
      render: (value: TableDataType) => (
        <VariantListItem
          name={getLocalizedValue(value.name, effectiveLocale, productContext.channel, "")}
          thumbnail={getLocalizedValue(value.thumbnail, effectiveLocale, productContext.channel, null)}
          attributes={value.attributes}
        />
      )
    },
    {
      title: t["common.action"],
      width: 100,
      hidden: !isEdit,
      dataIndex: "key",
      onCell: () => ({ className: "action-cell" }),
      render: (value: string) => (
        <Button
          danger
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            onDeleteVariant(value);
          }}
        >
          {t["common.remove"]}
        </Button>
      )
    }
  ]), [t, isEdit, onDeleteVariant, productContext]);

  return (
    <>
      <Container
        bordered
        loading={loading}
        pagination={paginationProps}
        columns={columns}
        dataSource={dataSource}
        rowSelection={{ selectedRowKeys, columnWidth: 0 }}
        onRow={(record) => ({
          onClick: () => {
            setSelectedRowKeys([record.key]);
            onRowClick(record.key as string);
          }
        })}
      />
    </>
  );
};
