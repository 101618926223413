import { productsApiService } from "@pm/api/ProductsApiService";
import { atomWithQuery } from "jotai-tanstack-query";
import { ApiSearchParam } from "../../../common/types/commonTypes";
import { ProductListState, productListStateAtom } from "./productListState";

export const productManagementListState = atomWithQuery((get) => ({
  queryKey: ["products", get(productListStateAtom)],
  queryFn: async ({ queryKey: [_, uiState] }) => {
    const {
      searchProductNameValue,
      selectedCategoryIds,
      selectedLocale,
      selectedChannel,
      tablePage,
      tablePageSize,
      tableSorts,
      tableFilters
    } = uiState as ProductListState;
    const asSearchParam: ApiSearchParam[] = [];
    Object.entries(tableFilters).forEach(([key, filters]) => {
      filters?.forEach((filter) => {
        const entry: ApiSearchParam = {
          field: key,
          operation: "eq",
          condition: filter
        };
        asSearchParam.push(entry);
      });
    });

    return productsApiService.getProducts({
      page: tablePage,
      pageSize: tablePageSize,
      sorts: tableSorts,
      search: searchProductNameValue.concat(asSearchParam),
      categoryId: selectedCategoryIds,
      locale: selectedLocale,
      channel: selectedChannel ?? undefined,
    });
  }
}));
