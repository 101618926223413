import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { styled } from "styled-components";
import { useLocaleOptions } from "../../../hooks/useLocaleOptions";
import { useCommonUiStore } from "../../../domain/state/stores/CommonUiStore";
import { useCallback, useMemo } from "react";
import { AinIcon } from "../../atoms/AinIcon";

const StyledSelect = styled(Select)`
  &.ant-select.ant-select-in-form-item {
    min-width: 185px;
  }
`

const StyledGroup = styled.span`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
`

export const ChannelLocaleSelector: React.FC<{
  onChange?: (option: DefaultOptionType) => void;
  value?: string;
}> = ({ onChange, value, ...props }): JSX.Element => {
  const { defaultLocale } = useLocaleOptions();
  const { channels, locales, t } = useCommonUiStore();

  const options = useMemo<DefaultOptionType[]>(() => {
    const optionsRet: DefaultOptionType[] = [];
    // loop through all channels, first one is null ~ all channels
    [null, ...channels].forEach(c => {
      optionsRet.push({
        label: <StyledGroup>{c ? <AinIcon icon="shop"/> : <AinIcon icon="global"/>} {c?.name || t['common.allChannels']}</StyledGroup>,
        options: locales.map<DefaultOptionType>(l => ({
          label: <span>{l.name}</span>,
          value: `${c?.code || ''}:${l.code}`
        })),
      })
    })
    return optionsRet;
  }, [locales, channels, t]);

  const labelRender = useCallback((selected: any) => {
    const value: string = selected.value;
    const toks = value?.split(':');

    const localeLabel = locales.find(x => x.code === toks[1])?.name || t["common.allLocales"];
    const channelLabel = channels.find(x => x.code === toks[0])?.name || t["common.allChannels"];

    return <span>{channelLabel} / {localeLabel}</span>;
  }, [locales, channels, t])

  return (
    <StyledSelect
      className="locale-channel-selector"
      options={options}
      defaultValue={":" + defaultLocale?.value}
      onSelect={(_, newValue) => onChange && onChange(newValue)}
      labelRender={labelRender}
      value={value}
      {...props}
    />
  );
};
