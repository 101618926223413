import { Switch } from "antd";
import { BaseInputProps } from "../../types";
import Label from "../Label";
import { useContext } from "react";
import FormSettingContext from "../../context/FormSettingContext";
import { ItemContainer } from "../ItemContainer";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

export default function InputBoolean(props: BaseInputProps) {
  const { value, onUpdate, isRequired, isArrayItem } = props;
  const formSetting = useContext(FormSettingContext);

  const { t } = useCommonUiStore();

  if (formSetting.editMode) {
    return (
      <ItemContainer>
        { isArrayItem ? null : <Label template={props.template} isRequired={isRequired} /> }
        <Switch value={!!value} onChange={onUpdate} />
      </ItemContainer>
    );
  } else {
    return (
      <ItemContainer>
        { isArrayItem ? null : <Label template={props.template} isRequired={isRequired} /> }
        <div>{value ? t["common.yes"] : t["common.no"]}</div>
      </ItemContainer>
    );
  }
}
