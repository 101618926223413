import { useCallback, useContext, useMemo } from "react";
import { useCommonStore } from "../../../../../common/domain/state/stores/useCommonStore";
import FormSettingContext from "../context/FormSettingContext";
import { BaseInputProps } from "../types";
import DynamicInput from "./DynamicInput";

interface DynamicValueObject {
  value: any;
  locale: string | null;
  channel: string | null;
}

function isDynamicValueArray(value: any): boolean {
  if (!value) return false;
  if (!Array.isArray(value)) return false;
  const valueItem = value[0];
  if (!valueItem) return false;

  const keys = Object.keys(valueItem);
  if (keys.includes('value') && 
      keys.includes('locale') && 
      keys.includes('channel')) return true;
  return false;
}

function safeExtractValue(value: any, locale: string | null) {
  if (isDynamicValueArray(value) && locale) {
    return (value as DynamicValueObject[]).find(x => x.channel === null && x.locale === locale)?.value;
  }
  return value;
}

function isDynamicValueMatch(a: DynamicValueObject, b: DynamicValueObject) {
  return (a.channel ?? null) === (b.channel ?? null) && (a.locale ?? null) === (b.locale ?? null)
}

// Brief: process localized value for an input. Unwrap / Wrap values to it's own format before patching back real content
export default function DynamicValueControl(props: BaseInputProps) {
  const { ui } = useCommonStore();
  const { value, onUpdate } = props;
  const { defaultTransFormLocale } = ui;

  // Wrapper & unwrapper to control value
  // data filtering will be specified by "locale" and "channel"
  // only pass to input the filtered value, only 1

  const dataSetting = useContext(FormSettingContext);

  // wrapper for value, depends on data setting then target value will be selected
  const valueInternal = useMemo(() => {
    if (!dataSetting.channel && !dataSetting.locale) return safeExtractValue(value, defaultTransFormLocale.code);
    const targetLocale = dataSetting.useDefault ? defaultTransFormLocale.code : dataSetting.locale;

    
    // on initial load, value might not be formatted as expected, so whenever user starts editing, we will change the format
    if (!Array.isArray(value)) return value;
    
    // just force it to strong-typed one
    const dValues = value as DynamicValueObject[];

    // find matching by dataSetting
    let dTarget = dValues.find((x) => (x.channel ?? null) === (dataSetting.channel ?? null) && 
                                      (x.locale ?? null) === (targetLocale ?? null));

    if (dTarget === undefined) {
      dTarget = dValues.find(x => (x.channel ?? null) === null && (x.locale ?? null) === null);
    }

    return dTarget?.value;

    // should not reached here
  }, [dataSetting, value]);

  // wrapper for onUpdate, modify the response, upsert as needed to
  const onUpdateInternal = useCallback(
    (updatedValue: any) => {
      function callUpdate(v: any) {
        if (onUpdate) onUpdate(v);
      }

      // no longer send object back, always array
      // if (!dataSetting.channel && !dataSetting.locale) {
      //   return callUpdate(updatedValue);
      // }

      // now transform the value to array as required
      const dValues: DynamicValueObject[] = Array.isArray(value) ? value : [];
      const dValue: DynamicValueObject = {
        value: updatedValue,
        channel: dataSetting.channel,
        locale: dataSetting.locale,
      }

      // if we useDefault locale, pls set locale=null
      if (dataSetting.useDefault) {
        dValue.locale = null;
      }

      const updatedValues = dValues.map((x) => isDynamicValueMatch(x, dValue) ? dValue : x);
      if (!updatedValues.some((x) => isDynamicValueMatch(x, dValue))) {
        updatedValues.push(dValue);
      }
        
      return callUpdate(updatedValues);
    },
    [onUpdate, dataSetting, value],
  );

  return (
    <DynamicInput
      {...props}
      value={valueInternal}
      onUpdate={onUpdateInternal}
    />
  );
}
