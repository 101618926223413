import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Popover,
  Row,
  Space
} from "antd";
import { dataTypesMapping } from "../../../../common/businessLogic/dataTypesMapping";
import { AttributeInputTypeEnum } from "../../../../models/enums/attributeInputTypeEnum";
import { CreateAttributeRequest } from "../../../../models/valueObjects/createAttributeRequest";
import { DataTypeSelector } from "../../../molecules/DataTypesSelector";
import "./addNewInputPopover.scss";
import { attributeConstants } from "../../../../common/constants/constants";
import { InputTypesSelector } from "../../../molecules/InputTypesSelector";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

type FormErrors = Promise<{
  hasDuplicatedName?: boolean;
}>;

export interface AddNewInputProps {
  children: React.ReactNode;
  isOpen: boolean;
  onConfirm: (
    data: CreateAttributeRequest,
    form: FormInstance<CreateAttributeRequest>
  ) => FormErrors | undefined;
  onCancelClicked: () => void;
}

export const AddNewInputPopover: React.FC<AddNewInputProps> = ({
  children,
  isOpen,
  onConfirm,
  onCancelClicked
}) => {
  const { t } = useCommonUiStore();
  const [form] = Form.useForm<CreateAttributeRequest>();
  const [selectedInputType, setSelectedInputType] = useState(
    AttributeInputTypeEnum.simple
  );
  const [hasDuplicatedName, setHasDuplicatedName] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      setHasDuplicatedName(false);
    }
  }, [isOpen, form]);

  useEffect(() => {
    form.setFieldsValue({
      inputType: selectedInputType,
      dataType:
        dataTypesMapping[selectedInputType] &&
        dataTypesMapping[selectedInputType].length === 1
          ? dataTypesMapping[selectedInputType][0]
          : undefined
    });
  }, [form, selectedInputType]);

  const handleConfirm = async () => {
    const values = await form.validateFields();

    const { hasDuplicatedName } = (await onConfirm(values, form)) ?? {};
    if (hasDuplicatedName) {
      setHasDuplicatedName(hasDuplicatedName);
      return;
    }

    setSelectedInputType(AttributeInputTypeEnum.simple);
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Popover
        title={t["attributeDetails.addNewInput.modelTitle"]}
        open={isOpen}
        content={
          <div
            data-test={dMap["add-new-input-popover"]}
            className="add-new-input-popover"
          >
            <Form
              form={form}
              name={attributeConstants.forms["add-new-input-popover"]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete="off"
              layout="horizontal"
            >
              <Form.Item<CreateAttributeRequest>
                label={t["createAttribute.nameLabel"]}
                data-test={dMap["name"]}
                name="name"
                {...(hasDuplicatedName
                  ? {
                      validateStatus: "error",
                      help: t["common.validation.nameAlreadyExists"]
                    }
                  : {})}
                rules={[
                  {
                    required: true,
                    message: t["createAttribute.nameRequireMessage"]
                  }
                ]}
              >
                <Input
                  placeholder={t["createAttribute.namePlaceholder"]}
                />
              </Form.Item>

              <InputTypesSelector
                form={form}
                onChange={(inputType) => setSelectedInputType(inputType)}
              />

              <DataTypeSelector form={form} inputType={selectedInputType} />
              <Row justify="end">
                <Space>
                  <Col>
                    <Button onClick={onCancelClicked}>
                      {t["common.cancel"]}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      data-test={dMap["button-add"]}
                      type="primary"
                      onClick={handleConfirm}
                    >
                      {t["common.add"]}
                    </Button>
                  </Col>
                </Space>
              </Row>
            </Form>
          </div>
        }
      >
        {children}
      </Popover>
    </span>
  );
};
