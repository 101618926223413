import { booleanToText } from "../../../../../../common/ui/molecules/selection/booleanToText";
import { settingsFields } from "../../../../common/businessLogic/settingsFields";
import {
  formSelectionTypeUiMapping,
} from "../../../../common/helpers/attributeUiMappings";
import { AttributeEntity } from "../../../../models/entities/attributeEntity";
import { AttributeInputTypeEnum } from "../../../../models/enums/attributeInputTypeEnum";
import { AttributeTemplate } from "../../../../models/valueObjects/attributeTemplate";
import { TranslationMap } from "../../../../../../features/i18n/translation";
import { FormColumn } from "./formColumnType";
import {
  attributeTypeCol,
  dataTypeRow,
  defaultRow,
  descriptionRow,
  inputTypeRow,
  isUniqueCol,
  maxLengthRow,
  minLengthRow,
  multipleValuesRow,
  requiredCol,
  toggleDefaultRow,
} from "./formColums";
import { booleanSelectRender } from "./inputTypeRenders/booleanSelectRender";
import { formSelectionTypeRender } from "./inputTypeRenders/formSelectionTypeRender";
import { resolveSimpleTypeLastRows } from "./rowsResolvers/resolveSimpleTypeLastRows";

const builderDataMap = (t: TranslationMap) => new Map<
  AttributeInputTypeEnum,
  (
    attribute: AttributeTemplate | AttributeEntity,
    isTopLevel: boolean
  ) => FormColumn[][]
>([
  [
    AttributeInputTypeEnum.simple,
    (
      attributeEntity: AttributeTemplate | AttributeEntity,
      isTopLevel: boolean
    ) => [
      // common rows
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity, t)]
          : [requiredCol(attributeEntity, t)])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity, t)] : [])],
      [
        inputTypeRow(attributeEntity, t),
        { ...multipleValuesRow(attributeEntity, t), span: 12 }
      ],
      // type specific rows
      ...resolveSimpleTypeLastRows(attributeEntity, t)
    ]
  ],
  [
    AttributeInputTypeEnum.selection,
    (
      attributeEntity: AttributeTemplate | AttributeEntity,
      isTopLevel: boolean
    ) => [
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity, t)]
          : [requiredCol(attributeEntity, t)])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity, t)] : [])],
      [
        {
          ...inputTypeRow(attributeEntity, t),
          span: 6
        },
        {
          key: ["inputSettings", "searchable"],
          label: settingsFields(t).searchable,
          value:
            attributeEntity.inputSettings?.searchable == null
              ? t["common.na"]
              : booleanToText(
                  attributeEntity.inputSettings?.searchable ?? false,
                  t
                ),
          span: 6,
          ...booleanSelectRender
        },
        { ...multipleValuesRow(attributeEntity, t), span: 6 },
        {
          key: ["inputSettings", "selectionType"],
          label: settingsFields(t).selectionType,
          value:
            formSelectionTypeUiMapping(t)[
              attributeEntity.inputSettings?.selectionType ?? "single"
            ]?.label,
          span: 6,
          ...formSelectionTypeRender(t),
        }
      ],
      [dataTypeRow(attributeEntity, t), isUniqueCol(attributeEntity, t), defaultRow(attributeEntity, t)],
      // type specific rows
      ...resolveSimpleTypeLastRows(attributeEntity, t),
    ]
  ],
  [
    AttributeInputTypeEnum.form,
    (
      attributeEntity: AttributeTemplate | AttributeEntity,
      isTopLevel: boolean
    ) => [
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity, t)]
          : attributeEntity.inputType === AttributeInputTypeEnum.form
          ? []
          : [requiredCol(attributeEntity, t)])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity, t)] : [])],
      [
        inputTypeRow(attributeEntity, t),
        { ...multipleValuesRow(attributeEntity, t), span: 12 }
        // {
        //   key: ["inputSettings", "layoutOrientation"],
        //   label: settingsFields().childInputLayout,
        //   value:
        //     formLayoutOrientatoinUiMapping()[
        //       attributeEntity.inputSettings?.layoutOrientation ?? "vertical"
        //     ]?.label,
        //   span: 6,
        //   ...formLayoutSelectRender
        // }
      ],
      [dataTypeRow(attributeEntity, t)]
    ]
  ],
  [
    AttributeInputTypeEnum.textArea,
    (
      attributeEntity: AttributeTemplate | AttributeEntity,
      isTopLevel: boolean
    ) => [
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity, t)]
          : [requiredCol(attributeEntity, t)])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity, t)] : [])],
      [
        inputTypeRow(attributeEntity, t),
        { ...multipleValuesRow(attributeEntity, t), span: 12 }
      ],
      [dataTypeRow(attributeEntity, t)],
      // [minLengthRow(attributeEntity, t), maxLengthRow(attributeEntity, t)],
    ]
  ],
  [
    AttributeInputTypeEnum.toggle,
    (attributeEntity: AttributeTemplate | AttributeEntity, isTopLevel) => [
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity, t)]
          : [requiredCol(attributeEntity, t)])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity, t)] : [])],
      [
        inputTypeRow(attributeEntity, t),
        { ...multipleValuesRow(attributeEntity, t), span: 12 },
      ],
      [dataTypeRow(attributeEntity, t), toggleDefaultRow(attributeEntity, t)],
    ]
  ],
  [
    AttributeInputTypeEnum.uploader,
    (
      attributeEntity: AttributeTemplate | AttributeEntity,
      isTopLevel: boolean
    ) => [
      [
        ...(isTopLevel
          ? [attributeTypeCol(attributeEntity, t)]
          : [requiredCol(attributeEntity, t)])
      ],
      [...(isTopLevel ? [descriptionRow(attributeEntity, t)] : [])],
      [ 
        inputTypeRow(attributeEntity, t),
        { ...multipleValuesRow(attributeEntity, t), span: 12 }
      ],
      ...resolveSimpleTypeLastRows(attributeEntity, t)
    ]
  ]
]);

export const getBuilderData = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  isTopLevel: boolean,
  t: TranslationMap,
) => {
  try {
    const mapgetter = builderDataMap(t).get(attributeEntity.inputType);
    if (!mapgetter) {
      throw new Error(`Unknown input type, got: ${attributeEntity?.inputType}`);
    }
    return mapgetter(attributeEntity, isTopLevel);
  } catch (error) {
    throw new Error(`Unknown input type, got: ${attributeEntity?.inputType}`);
  }
};
