import { GetLocaleResourceResponse } from "../../domain/valueObjects/GetLocaleResourceResponse";
import { GetLocaleResponse } from "../../domain/valueObjects/GetLocaleResponse";
import { ApiListResponse, Id } from "../../types/commonTypes";
import BaseApiService from "../ApiService";

const baseUrl = `/settings/system/locales`;
const ApiService = new BaseApiService(baseUrl);

export class SystemLocalesApiService {
  public async getLocales(): Promise<ApiListResponse<GetLocaleResponse>> {
    const response = (await ApiService.getFetcher(
      ""
    )) as ApiListResponse<GetLocaleResponse>;
    const sortedItems = response?.items.sort((itemA, itemB) =>
      itemA.name.localeCompare(itemB.name)
    );
    return {
      ...response,
      items: sortedItems
    };
  }

  public async getResources(localeId: string): Promise<GetLocaleResourceResponse> {
    const response = (await ApiService.getFetcher(
      `/${localeId}/resources`
    )) as GetLocaleResourceResponse;
    return response;
  }
}

export const systemLocaleApiService = new SystemLocalesApiService();
