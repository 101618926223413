import { AttributeDataTypeEnum } from "@am/models/enums/attributeDataTypeEnum";
import { AttributeInputTypeEnum } from "@am/models/enums/attributeInputTypeEnum";
import DynamicValueControl from "@shared/ui/attributes-editor/components/DynamicValueControl";
import { BaseInputProps } from "@shared/ui/attributes-editor/types";

const SYSTEM_NAME_ATTRIBUTE = {
  name: "name",
  isLocaleDependent: true,
  dataType: AttributeDataTypeEnum.text,
  isNullable: false,
  isMultipleValues: false,
  labels: [
    {
      locale: "de-DE",
      value: "Produktname",
    },
    {
      locale: "en-US",
      value: "Product Name",
    },
    {
      locale: "vi-VN",
      value: "Tên sản phẩm",
    },
  ],
  template: [],
  description: "",
  isUnique: false,
  inputType: AttributeInputTypeEnum.simple,
  default: "",
  options: [],
  isChannelDependent: false,
};

export default function ProductNameEdit(props: BaseInputProps) {
  return (
    <DynamicValueControl
      value={props.value}
      onUpdate={props.onUpdate}
      isRequired
      template={SYSTEM_NAME_ATTRIBUTE}
    />
  );
}
