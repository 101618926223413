import { DependentValue } from "@pm/models/ProductEntity";

export function isMatch<T>(a: DependentValue<T>, b: DependentValue<T>): boolean {
  return (a.channel ?? null) === (b.channel ?? null) && (a.locale ?? null) === (b.locale ?? null);
}

export function addValue<T>(list: DependentValue<T>[], value: DependentValue<T>): DependentValue<T>[] {
  const updatedValue = list.map(x => isMatch(x, value) ? value : x);
  if (!updatedValue.find(x => isMatch(x, value))) {
    updatedValue.push(value);
  }

  return updatedValue;
}