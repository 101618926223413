import { Dropdown, Flex, MenuProps } from "antd";
import { useCallback, useMemo } from "react";
import { useCommonUiStore } from "../../../../common/domain/state/stores/CommonUiStore";
import { AinIcon } from "../../../../common/ui/atoms/AinIcon";

type Props = {
  onChange: (value: string | null) => void;
}

export default function ChannelSelector(props: Props) {
  const { onChange } = props;
  const ui = useCommonUiStore();
  const { channels, t } = ui;

  const items = useMemo(() => {
    const menuItems: MenuProps['items'] = channels.map(x => ({
      key: x.code,
      label: <Flex align="center" gap={10}><AinIcon icon="shop"/> {x.name}</Flex>
    }));
    menuItems.unshift({
      key: "",
      label: <Flex align="center" gap={10}><AinIcon icon="global"/> {t["common.allChannels"]}</Flex>
    })

    return menuItems;
  }, [channels, t])

  const handleOnClick = useCallback((v: any) => {
    onChange(v.key || null);
  }, [onChange])

  return (
    <Dropdown menu={{items, onClick: handleOnClick}}>
      <a onClick={e => e.preventDefault()}>Select</a>
    </Dropdown>
  )
}