import "./detailsContent.scss";
import AttributesTable from "../attributesTable/AttributesTable";
import { Tabs, TabsProps } from "antd";
import { useCategoryPageState } from "../../../../domain/hooks/useCategoryPageState";
import CategorySettings from "../categorySettings/CategorySettings";
import { useMemo } from "react";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

interface Props {}

export const DetailsContent: React.FC<Props> = (): JSX.Element => {
  const [state] = useCategoryPageState();
  const { t } = useCommonUiStore();

  const items: TabsProps["items"] = useMemo(() => [
    {
      key: "1",
      label: t["category.common.attributes"],
      children: <AttributesTable />
    },
    {
      key: "2",
      label: t["category.common.settings"],
      children: <CategorySettings />
    }
  ], [t]);

  if (state.currentCategory?.id !== state.baseCategoryId) {
    return (
      <div className="details-content">
        <AttributesTable />
      </div>
    );
  }

  return (
    <div className="details-content">
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default DetailsContent;
