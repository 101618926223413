import { Space, Row } from "antd";
import { FlagKey } from "../../mock/flags";

export interface FlagChipProps {
  width?: number;
  flag: FlagKey;
  label?: React.ReactNode;
  marginTop?: number;
}
export const FlagChip: React.FC<FlagChipProps> = ({
  width,
  flag,
  label,
  marginTop
}): JSX.Element => {
  return (
    <Space align="center" style={{ marginTop: marginTop ?? 6 }}>
      {!label ? null : <div>{label}</div>}
      <Row>
        <img width={width ?? 24} src={`/flags/${flag}.svg`} />
      </Row>
    </Space>
  );
};
