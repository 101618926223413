import { createContext } from "react";

export interface FormSetting {
  editMode: boolean;
  showValidationError: boolean;
  locale: string | null;
  channel: string | null;
  useDefault: boolean;
}

export default createContext<FormSetting>({
  editMode: false,
  showValidationError: false,
  locale: null,
  channel: null,
  useDefault: false,
});
