export type AinIcons =
  | "DAM"
  | "PIM"
  | "arrow-left"
  | "asset-uploader"
  | "attributes"
  | "boxes"
  | "brand"
  | "categories1"
  | "categories-menu"
  | "config"
  | "delete-outlined"
  | "delete-outlined-gray"
  | "dropdown"
  | "edit-filled"
  | "email"
  | "object"
  | "paper-clip-outlined"
  | "plus"
  | "question-mark-outlined"
  | "rate-star"
  | "shop"
  | "global"
  | "simple-field"
  | "texarea"
  | "toggle"
  | "translate"
  | "translation-outlined"
  | "delete-outlined-gray"
  | "table-outlined"
  | "logout"
  | "external-annotation"
  | "in-synced"
  | "drag-outlined";

type IconProps = {
  icon: AinIcons;
  size?: number;
  height?: number;
  width?: number;
  rotation?: number;
  onClick?: () => void;
  className?: string;
  title?: string;
};

export const AinIcon: React.FC<IconProps> = ({
  icon,
  size,
  height,
  width,
  rotation,
  onClick,
  className,
  title
}): JSX.Element => {
  const exStyle: Record<string, any> = {};
  if (rotation) {
    exStyle.transform = `rotate(${rotation}deg)`;
  }
  return (
    <img
      className={className}
      src={`/icons/${icon}.svg`}
      width={width ?? size ?? 16}
      height={height ?? size ?? 16}
      style={exStyle}
      onClick={onClick}
      title={title}
    ></img>
  );
};
