import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import FormSettingContext, { FormSetting } from "./context/FormSettingContext";
import DynamicValueControl from "./components/DynamicValueControl";
import { AttributeTemplate } from "@am/models/valueObjects/attributeTemplate";
import { CategoryAttributeWithOriginal } from "@pm/state/productManagementEntityState";
import FormValidationContext, { FormValidation, ValidationEntry, ValidationUpdater } from "./context/FormValidationContext";
import uniqueId from "lodash/uniqueId";
import { Labels } from "../../../../common/domain/valueObjects/Label";
import AttributeGroup from "./components/AttributeGroup";

export type FormValue = Record<string, any>;

export interface AttributeEditorProps extends PropsWithChildren {
  value: FormValue | null;
  onChange: (v: FormValue) => void;
  onValidationUpdated?: (isValid: boolean) => void;
  editable: boolean | null;
  showError: boolean | null;
  settings: Record<string, CategoryAttributeWithOriginal>;
  templates: Record<string, AttributeTemplate>;
  groups?: Record<string, Labels[]>;
  locale: string | null;
  channel: string | null;
  useDefault: boolean;
}

export default function AttributesEditor(props: AttributeEditorProps) {
  const { value, onChange, templates, settings, children, groups } = props;
  const formSetting = useMemo<FormSetting>(() => {
    return {
      editMode: props.editable || false,
      showValidationError: props.showError ?? true, // if not set, validation message will show
      channel: props.channel,
      locale: props.locale,
      useDefault: props.useDefault,
    };
  }, [props]);

  const handleUpdate = useCallback(
    (field: string, fieldValue: any) => {
      const newValue = { ...value, [field]: fieldValue };
      onChange(newValue);
    },
    [value, onChange],
  );

  // form-wide validation logic
  const fromValidation = useRef<FormValidation>({
    validationEntries: {},
    register: () => null, // first initialization to null
  })

  const validationRegisterHandler = (): ValidationUpdater => {
    const uid = uniqueId('validation-');
    fromValidation.current.validationEntries[uid] = {
      isValid: true,
      path: '',
      errorMessages: undefined,
    }
    return {
      unregister: () => {
        if (fromValidation.current.validationEntries[uid]) {
          delete fromValidation.current.validationEntries[uid];
        }
        updateValidation();
      },
      update: (isValid: boolean, path: string, errorMessages?: string[]) => {
        if (!fromValidation.current.validationEntries[uid]) return;
        Object.assign(fromValidation.current.validationEntries[uid], {
          isValid, path, errorMessages
        } as ValidationEntry)
        updateValidation();
      }
    }
  }

  // update every rerender, whenver this is updated, won't affect the current data
  fromValidation.current.register = validationRegisterHandler;

  const [isValid, setIsValid] = useState(true);
  const updateValidation = () => {
    const entries = fromValidation.current.validationEntries;
    const hasInvalid = Object.keys(entries).some(k => entries[k].isValid === false);
    if (hasInvalid) setIsValid(false) 
    else setIsValid(true);
  }

  useEffect(() => {
    if (props.onValidationUpdated) props.onValidationUpdated(isValid);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  const attributeKeys = Object.keys(templates);
  const attributeItems = attributeKeys.map((attribute) => {
    const fieldValue = value ? value[attribute] : null;
    const attributeSetting = settings[attribute];
    return (
      <DynamicValueControl
        key={attribute}
        value={fieldValue}
        onUpdate={(v) => handleUpdate(attribute, v)}
        template={templates[attribute]}
        isRequired={attributeSetting?.isRequired || false}
      />
    );
  });

  // group attributes
  const convertToGrouped = (currentList: JSX.Element[]) => {
    const groupedList: JSX.Element[] = [];
    
    let i = 0;
    while (i < attributeKeys.length) {
      const key = attributeKeys[i];
      const setting = settings[key];
      const groupName = setting?.group;

      if (groupName) {
        const groupItems = [currentList[i]];
        i++;
        while (i < attributeKeys.length) {
          const nextKey = attributeKeys[i];
          const nextSetting = settings[nextKey];
          const nextGroupName = nextSetting?.group;
          if (nextGroupName !== groupName) {
            break; // don't advance, let next one does
          } else {
            groupItems.push(currentList[i]);
            i++;
          }
        }
        const groupLabels = groups?.[groupName];
        groupedList.push(<AttributeGroup key={`grp-${i}`} labels={groupLabels} lang={formSetting.locale}>{groupItems}</AttributeGroup>);
      } else {
        groupedList.push(currentList[i]);
        i++;
      }
    }
    return groupedList;
  }
  const groupedAttributeItems = convertToGrouped(attributeItems);

  return (
    <FormSettingContext.Provider value={formSetting}>
    <FormValidationContext.Provider value={fromValidation.current}>
      {children}
      {groupedAttributeItems}
    </FormValidationContext.Provider>
    </FormSettingContext.Provider>
  );
}
