import { TranslationMap } from "../../../../features/i18n/translation";

export const settingsFields = (t: TranslationMap) => ({
  isSystemAttribute: t["common.system"],
  isMasterAttribute: t["common.attributeType"],
  isNullable: t["common.required"],

  // first
  inputType: t["attributeDetails.inputType"],
  dataType: t["common.dataType"],
  isMultipleValues: t["attributeDetails.inputTypes.simpleField.isMultipleValues"],

  // second
  description: t["common.description"],

  // third
  validation: t["common.validation"],
  default: t["attributeDetails.settings.editConfig.defaultValue"],
  isUnique: t["attributeDetails.inputTypes.simpleField.isUnique"],
  searchable: t["common.searchable"],
  selectionType: t["attributeDetails.inputTypes.selection.selectionType"],
  selectionLook: t["attributeDetails.inputTypes.selection.selectionLook"],
  childInputLayout: t["attributeDetails.inputTypes.form.childInputLayout"],

  // forth
  minCharacter: t["common.minCharacter"],
  maxCharacter: t["common.maxCharacter"],
  validFrom: t["common.validFrom"],
  validTo: t["common.validTo"],
  minValue: t["common.minValue"],
  maxValue: t["common.maxValue"],
  numberType: t["common.numberType"],
  minSize: t["common.minSize"],
  maxSize: t["common.maxSize"],
  allowedFileTypes: t["common.validFileType"],

  // text
  minLength: t["common.minCharacter"],
  maxLength: t["common.maxCharacter"],
  pattern: t["attributeDetails.settings.editConfig.textRegex"]
});

export type SettingFieldKeys = keyof ReturnType<typeof settingsFields>;

/**
 * Conventiont function to get autocompletions for settings fields.
 * Ensures field is always correctly spelled
 *
 * @example
 * getSettingField("inputType") // returns "inputType"
 */
export function getSettingField(fieldName: SettingFieldKeys): string {
  return fieldName;
}
