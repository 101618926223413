import { AttributeEntity } from "../../../../../models/entities/attributeEntity";
import { AttributeDataTypeEnum } from "../../../../../models/enums/attributeDataTypeEnum";
import { AttributeTemplate } from "../../../../../models/valueObjects/attributeTemplate";
import { TranslationMap } from "../../../../../../../features/i18n/translation";
import { FormColumn } from "../formColumnType";
import { AttributeInputTypeEnum } from "../../../../../models/enums/attributeInputTypeEnum";
import { 
  allowedTypesRow, 
  dataTypeRow, 
  defaultDateTimeRow, 
  defaultRow, 
  isUniqueCol, 
  maxFileSizeRow, 
  maxLengthRow, 
  maxNumberValueRow, 
  minFileSizeRow, 
  minLengthRow, 
  minNumberValueRow, 
  numberTypeRow, 
  patternRow, 
  validFromRow, 
  validToRow 
} from "../formColums";

export const resolveSimpleTypeLastRows = (
  attribute: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
) => {
  const rows: FormColumn[][] = [];

  switch (attribute.dataType) {
    case AttributeDataTypeEnum.text:
      if (attribute.inputType !== AttributeInputTypeEnum.selection) {
        rows.push([dataTypeRow(attribute, t), isUniqueCol(attribute, t), defaultRow(attribute, t)]);
        rows.push([patternRow(attribute, t), minLengthRow(attribute, t), maxLengthRow(attribute, t)]);
      }
      break;

    case AttributeDataTypeEnum.number:
      rows.push([dataTypeRow(attribute, t), numberTypeRow(attribute, t)]);
      rows.push([isUniqueCol(attribute, t), defaultRow(attribute, t), minNumberValueRow(attribute, t), maxNumberValueRow(attribute, t)]);
      break;

    case AttributeDataTypeEnum.dateTime:
    case AttributeDataTypeEnum.date:
    case AttributeDataTypeEnum.time:
      rows.push([dataTypeRow(attribute, t), defaultDateTimeRow(attribute, t)]);
      rows.push([validFromRow(attribute, t), validToRow(attribute, t)]);
      break;

    case AttributeDataTypeEnum.file: {
      rows.push([dataTypeRow(attribute, t), allowedTypesRow(attribute, t)]);
      rows.push([minFileSizeRow(attribute, t), maxFileSizeRow(attribute, t)]);
      break;
    }
    default:
      break;
  }

  return rows;
};
