import { Header } from "antd/es/layout/layout";
import "./categoryHeader.scss";
import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useRef } from "react";
import CreateCategoryModal from "../modals/createCategoryModal/CreateCategoryModal";
import { useCommonUiStore } from "../../../../../common/domain/state/stores/CommonUiStore";

interface Props {}

export const CategoryHeader: React.FC<Props> = (): JSX.Element => {
  const createCategoryModal = useRef<any>(null);
  const { t } = useCommonUiStore();

  return (
    <Header className="category-header">
      <Row>
        <Col span={16} className="header-name">
          {t["category.header.title"]}
        </Col>
        <Col span={8} className="create-new">
          <Button
            type="primary"
            onClick={() => createCategoryModal.current?.setIsOpen(true)}
            icon={<PlusOutlined key="" />}
          >
            {t["common.createNew"]}
          </Button>
        </Col>
      </Row>
      <CreateCategoryModal ref={createCategoryModal} />
    </Header>
  );
};

export default CategoryHeader;
