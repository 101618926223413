import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Button, Modal, Table } from "antd";
import "./optionValuesModal.scss";
import { ColumnsType } from "antd/es/table";
import { TemplateSetting } from "../../../../domain/valueObjects/templateSetting";
import TableEditableRow from "../../../molecules/tableEditableRow/TableEditableRow";
import TableEditableCell from "../../../molecules/tableEditableCell/TableEditableCell";
import cloneDeep from "../../../../../../common/modules/cloneDeep";
import { generateId } from "../../../../../../common/modules/debugging/generateId";
import { useCommonStore } from "../../../../../../common/domain/state/stores/useCommonStore";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

interface Props {
  onSaved: (setting: TemplateSetting) => void;
}

interface TableDataType {
  key: string;
  dataKey: string;
  value?: string;
}

const OptionValuesModal = forwardRef((props: Props, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<TableDataType[]>();
  const settingRef = useRef<TemplateSetting>();
  const { ui: { defaultLocale, t }} = useCommonStore();

  useImperativeHandle(ref, () => ({ openWithData }));

  const openWithData = (setting: TemplateSetting) => {
    settingRef.current = cloneDeep(setting);
    setDataSource(
      setting.options?.map((x) => ({
        key: generateId(),
        dataKey: x.key,
        value: x.displayValues.find(
          (x) => x.locale === defaultLocale?.code
        )?.value
      })) ?? []
    );

    setIsOpen(true);
  };

  const handleSaveCell = (row: TableDataType) => {
    const dataSourceTemp = [...dataSource!];
    const index = dataSourceTemp.findIndex((x) => x.key === row.key);

    dataSourceTemp.splice(index, 1, row);
    setDataSource(dataSourceTemp);
  };

  const handleAddValue = () => {
    const newData: TableDataType = {
      key: generateId(),
      dataKey: "",
      value: ""
    };

    setDataSource([...dataSource!, newData]);
  };

  const handleOk = () => {
    if (!settingRef.current) {
      return;
    }

    const validData = dataSource!
      .filter((x) => x.dataKey && x.value)
      .map((x) => ({
        key: x.dataKey,
        value: x.value!
      }));
    const valueOptions =
      settingRef.current.options?.filter((o) =>
        validData.some((x) => x.key === o.key)
      ) ?? [];

    validData.forEach(({ key, value }) => {
      const found = valueOptions.find((x) => x.key === key);
      if (found) {
        const label = found.displayValues.find(
          (x) => x.locale === defaultLocale?.code
        );
        if (label) {
          label.value = value;
        }
      } else if (defaultLocale) {
        valueOptions.push({
          key,
          displayValues: [{ locale: defaultLocale.code, value }]
        });
      }
    });

    settingRef.current.options = valueOptions;
    props.onSaved(settingRef.current);
    setIsOpen(false);
  };

  const columns: ColumnsType<TableDataType> = [
    {
      title: t["category.settings.key"],
      dataIndex: "dataKey",
      key: "dataKey",
      width: "40%",
      sorter: (a, b) => a.key.localeCompare(b.key),
      onCell: (record) => ({
        record,
        dataIndex: "dataKey",
        title: t["category.settings.key"],
        handleSaveCell
      })
    },
    {
      title: t["category.settings.value"],
      dataIndex: "value",
      key: "value",
      sorter: (a, b) =>
        !a.value || !b.value ? 0 : a.value.localeCompare(b.value),
      onCell: (record) => ({
        record,
        dataIndex: "value",
        title: t["category.settings.value"],
        handleSaveCell
      })
    },
    {
      title: t["category.common.action"],
      width: 100,
      render: (_, record) => (
        <Button
          type="link"
          danger
          onClick={() =>
            setDataSource(dataSource?.filter((x) => x.key !== record.key))
          }
        >
          {t["category.settings.remove"]}
        </Button>
      )
    }
  ];

  return (
    <Modal
      title={t["category.settings.optionsValue"]}
      okText={t["category.common.save"]}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={handleOk}
      width={1200}
      destroyOnClose
      className="option-values-modal"
    >
      <div className="top-bar">
        <span className="total">
          {`${dataSource?.length} ${t["category.settings.values"]}`}
          <Button type="primary" onClick={handleAddValue}>
            {t["common.addValue"]}
          </Button>
        </span>
      </div>
      <Table
        pagination={false}
        dataSource={dataSource}
        loading={!dataSource}
        columns={columns}
        components={{
          body: {
            row: TableEditableRow,
            cell: TableEditableCell
          }
        }}
      />
    </Modal>
  );
});

export default OptionValuesModal;
