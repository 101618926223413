import { AttributeInputTypeEnum } from "@am/models/enums/attributeInputTypeEnum";
import { AttributeDataTypeEnum } from "@am/models/enums/attributeDataTypeEnum";

import { BaseInputProps } from "../types";
import InputText from "./inputs/InputText";
import InputNumber from "./inputs/InputNumber";
import InputSelect from "./inputs/InputSelect";
import InputBoolean from "./inputs/InputBoolean";
import InputArray from "./Array";
import InputObject from "./Object";
import InputUploader from "./inputs/InputUploader";
import InputTextArea from "./inputs/InputTextArea";

export default function DynamicInput(props: BaseInputProps) {
  const { template } = props;
  if (!template) return null;

  switch (template.inputType) {
    case AttributeInputTypeEnum.simple:
      if (template.dataType === AttributeDataTypeEnum.number) {
        if (template.isMultipleValues) {
          return <InputArray {...props} />;
        } else {
          return <InputNumber {...props} />;
        }
      }

      // for all others, we use simple text input
      if (template.isMultipleValues) {
        return <InputArray {...props} />;
      } else {
        return <InputText {...props} />;
      }

    case AttributeInputTypeEnum.textArea:
      if (template.isMultipleValues) {
        return <InputArray {...props} />;
      } else {
        return <InputTextArea {...props} />;
      }

    case AttributeInputTypeEnum.selection:
      return <InputSelect {...props} />;

    case AttributeInputTypeEnum.toggle:
      if (template.isMultipleValues) {
        return <InputArray {...props} />;
      } else {
        return <InputBoolean {...props} />;
      }
      
    case AttributeInputTypeEnum.form:
      if (template.isMultipleValues) {
        return <InputArray {...props} />;
      } else {
        return <InputObject {...props} />;
      }

    case AttributeInputTypeEnum.uploader:
      return <InputUploader {...props} />;

    default:
      return <>Input type not supported</>;
  }
}
