import { useEffect, useMemo, useState } from "react";
import { ValidationFunction } from "../components/rules";

export default function useValidation(value: any, rules: ValidationFunction[], showErrorState: boolean) {
    const [isValid, setIsValid] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[] | undefined>();
    const status = useMemo<any>(() => {
        if (!showErrorState) return undefined; // even validation failed, we don't show error state
        return isValid ? undefined : 'error'
    }, [isValid, showErrorState]);

    useEffect(() => {
        let newIsValid = true;
        const messages: string[] = [];

        rules.some(r => {
            const validationResult = r(value);
            if (validationResult) {
                newIsValid = false;
                messages.push(validationResult);
                return true;
            }
        })
        setIsValid(newIsValid);
        setErrorMessages(messages.length ? messages : undefined);
    }, [value, rules])

    return {
        isValid,
        // input validation parameters....
        errorMessages,
        status,
    }
}