import { AttributeTemplate } from "@am/models/valueObjects/attributeTemplate";
import { useMemo } from "react";
import useLabel from "../hooks/useLabel";
import { styled } from "styled-components";

export interface LabelProps {
  template: AttributeTemplate | null | undefined;
  isRequired?: boolean;
}

const LabelContainer = styled.div`
  font-weight: 600;
  padding-bottom: 8px;
`;

const RedText = styled.span`
  color: #ff4d4f;
`;

export default function Label(props: LabelProps) {
  const { template, isRequired } = props;
  const showRequired = useMemo(() => {
    return isRequired === true;
  }, [isRequired]);

  const labelText = useLabel(template);

  return (
    <LabelContainer title={template?.name}>
      {showRequired ? <RedText>*</RedText> : null} {labelText}
    </LabelContainer>
  );
}
