import { getAttributeTypeChecker } from "@am/common/businessLogic/getAttributeType";
import { AttributeEntity } from "@am/models/entities/attributeEntity";
import { LockFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { styled } from "styled-components";
import { AinIcon } from "../../../../common/ui/atoms/AinIcon";
import { useCommonUiStore } from "../../../../common/domain/state/stores/CommonUiStore";

const Styled = styled.div`
  display: flex;

  .attribute-type-icon-container {
    margin-right: 8px;
    display: flex;
    align-items: center;

    .lock-icon {
      font-size: 16px;
      color: #faad14;
    }
  }
`;

export const AttributeTypeIcon: React.FC<{
  attributeEntity: AttributeEntity;
}> = ({ attributeEntity }): JSX.Element => {
  const { t } = useCommonUiStore();
  const attributeTypeChecker = getAttributeTypeChecker(attributeEntity);

  if (attributeTypeChecker.isCommon) return <></>;

  return (
    <Styled>
      <div className="attribute-type-icon-container">
        {attributeTypeChecker.isSystem ? (
          <Tooltip title={t["common.attributeType.system.tooltip"]}>
            <LockFilled className="lock-icon" />
          </Tooltip>
        ) : attributeTypeChecker.isMaster ? (
          <AinIcon icon="rate-star" />
        ) : null}
      </div>
    </Styled>
  );
};
