import { AssetMinimumInfo } from "@pm/models/ProductEntity";
import { BaseInputProps } from "../../types";
import {
  downloadFile,
  isImageFileName,
} from "../../../../../../common/modules/fileHelper";
import { PreviewImage } from "@shared/ui/atoms/PreviewImage";
import { FileOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useContext, useMemo } from "react";
import FormSettingContext from "../../context/FormSettingContext";
import Label from "../Label";
import { styled } from "styled-components";
import { DnDUploader } from "@shared/ui/atoms/DnDUploader";
import { AssetType } from "@shared/entities/AssetType";
import { ItemContainer } from "../ItemContainer";

const FilesDisplay = styled.div`
  margin-bottom: -10px;

  .ant-upload-drag {
    height: 130px !important;
  }

  .file {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;

    .filename {
      margin-top: 0px;
      color: #13c2c2;
      padding-left: 0;
      padding-right: 0;
      outline: initial;
      font-weight: 500;

      > span {
        width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .image {
      padding: 6px;
      width: 120px;
      height: 120px;
      position: relative;
      border: 1px solid #00000026;
      border-radius: 6px;

      .ant-image-img,
      .ant-image {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        position: absolute;
        object-fit: contain;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .anticon-eye {
        display: none;
      }
    }

    .anticon {
      display: block;
      padding-top: 35px;
      font-size: 50px;
      text-align: center;
      width: 120px;
      height: 120px;
      color: #878787;
      border: 1px solid #00000026;
      border-radius: 6px;
    }
  }
`;

export default function InputUploader(props: BaseInputProps) {
  const { value, onUpdate, template, isRequired } = props;
  const formSetting = useContext(FormSettingContext);
  const isMultipleValues = template?.isMultipleValues || false;

  const renderSingleFile = (file: AssetMinimumInfo) => {
    return (
      <span className="file" key={file.id}>
        {isImageFileName(file.fileName) ? (
          <div className="image">
            <PreviewImage src={file.fileLink} alt={file.fileName} />
          </div>
        ) : (
          <FileOutlined />
        )}
        <Button
          className="filename"
          type="link"
          title={file.fileName}
          onClick={() => downloadFile(file.fileLink, file.fileName)}
        >
          {file.fileName}
        </Button>
      </span>
    );
  };

  const renderViewValue = () => {
    if (!value) {
      return "--";
    }

    if (!Array.isArray(value)) {
      return renderSingleFile(value);
    }

    if (!value.length) {
      return "--";
    }

    return value.map(renderSingleFile);
  };

  const files = useMemo(() => {
    if (value) {
      return Array.isArray(value) ? value : [value];
    }
    return [];
  }, [value]);

  if (!formSetting.editMode) {
    return (
      <ItemContainer>
        <Label template={props.template} isRequired={isRequired} />
        <FilesDisplay>{renderViewValue()}</FilesDisplay>
      </ItemContainer>
    );
  }

  return (
    <ItemContainer>
      <Label template={props.template} isRequired={isRequired} />
      <DnDUploader
        assetType={AssetType.productImage}
        multiple={isMultipleValues}
        files={files}
        listType="picture"
        iconRender={() => <FileOutlined />}
        onChange={onUpdate}
      />
    </ItemContainer>
  );
}
