export type ValidationFunction = (value: any) => string | null | undefined;

export function validationIsRequired(message: string): ValidationFunction {
  const fnc: ValidationFunction = (value: any) => {
    return value == null || value === undefined || value === '' ? message : null;
  }
  return fnc;
}

export function validationIsRequiredArray(message: string): ValidationFunction {
  const fnc: ValidationFunction = (value: any) => {
    return !value || value.length === 0 ? message : null;
  }
  return fnc;
}

export function validationIsMatchPattern(message: string, pattern: string): ValidationFunction {
  const fnc: ValidationFunction = (value: any) => {
    return null;
  }
  return fnc;
}

export function validationRoundedValue(message: string): ValidationFunction {
  const fnc: ValidationFunction = (value: any) => {
    if (value === null || value === undefined) return null; // we don't validate empty
    if (!Number.isInteger(value) && value !== 0) {
      return message;
    }
    return null;
  }
  return fnc;
}