import { useMemo } from "react";
import { inputTypesUiMetadata } from "../../common/helpers/attributeUiMappings";
import { Form, FormInstance, Select, SelectProps } from "antd";
import { CreateAttributeRequest } from "../../models/valueObjects/createAttributeRequest";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { useCommonUiStore } from "../../../../common/domain/state/stores/CommonUiStore";

export const InputTypesSelector: React.FC<
  SelectProps & {
    form: FormInstance<CreateAttributeRequest>;
    placeholder?: string;
  }
> = ({
  form,
  placeholder,
  defaultValue,
  onChange,
  ...formItemProps
}): JSX.Element => {
  const { t } = useCommonUiStore();
  const options = useMemo(() => {
    const finalOptions = Object.entries(inputTypesUiMetadata(t)).map(
      ([attributeType, data]) => ({
        value: attributeType,
        label: data?.label
      })
    );

    return finalOptions;
  }, [t]);

  const handleChange: SelectProps["onChange"] = (...args) => {
    if (onChange) {
      onChange(...args);
    }
    form.validateFields(["inputType"]);
  };

  return (
    <Form.Item
      data-test={dMap["input-type"]}
      label={t["common.inputType"]}
      name="inputType"
      rules={[
        {
          required: true,
          message: t["createAttribute.inputTypeRequireMessage"]
        }
      ]}
      {...formItemProps}
    >
      <Select
        data-test={dMap["select"]}
        placeholder={placeholder ?? t["common.select"]}
        options={options}
        onChange={handleChange}
        allowClear
        style={{ width: "100%", ...formItemProps.style }}
      />
    </Form.Item>
  );
};
