import { useContext, useEffect, useRef } from "react";
import FormValidationContext, { FnUpdateValidation } from "../context/FormValidationContext";

export default function useFormWideValidator(editMode: boolean, isValid: boolean, messages?: string[]) {
  // form-wide validation
  const formValidation = useContext(FormValidationContext);
  const formValidationUpdator = useRef<FnUpdateValidation | undefined>(undefined);

  useEffect(() => {
    if (!editMode) return;
    const registration = formValidation.register();
    formValidationUpdator.current = registration?.update;
    return registration?.unregister;
  }, [formValidation, editMode])

  useEffect(() => {
    if (!editMode) return;
    if (!formValidationUpdator.current) return;
    formValidationUpdator.current(isValid, "", messages);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, editMode])
}