import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import { Upload } from "antd";
import { LabelText } from "./LabelText";
import { AssetMinimumInfo } from "@pm/models/ProductEntity";
import { assetApiService } from "@shared/services/AssetApiService";
import { AssetType } from "@shared/entities/AssetType";
import { useCommonUiStore } from "../../../../common/domain/state/stores/CommonUiStore";

const Dropper = styled(Upload.Dragger)`
  .ant-upload-drag {
    padding: 8px;
    min-height: 120px;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 1;
    position: relative;

    .ant-upload {
      padding: 0;
    }

    .anticon-file {
      color: #878787 !important;
    }
  }

  .ant-upload-list-item-name {
    color: #13c2c2;
  }

  .preview-image {
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    position: absolute;
    object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export interface DnDUploaderProps extends UploadProps {
  id?: string;
  assetType: AssetType;
  files?: AssetMinimumInfo[];
  showPreview?: boolean;
  onChange?: (value: any) => void;
}

export const DnDUploader: React.FC<DnDUploaderProps> = ({
  id,
  files,
  multiple,
  showPreview,
  onChange,
  assetType,
  ...rest
}) => {
  const { t } = useCommonUiStore();
  const [previewUrl, setPreviewUrl] = useState<string>();

  useEffect(() => {
    if (files?.length) {
      setPreviewUrl(files[0].fileLink);
    }
  }, [files]);

  const uploadedFiles = useMemo(
    () =>
      files
        ?.filter((x) => !!x)
        .map<UploadFile>((x) => ({
          name: x.fileName,
          uid: x.id,
          status: "done",
          thumbUrl: x.fileLink,
        })),
    [files],
  );

  const handleRemove = (file: UploadFile) => {
    setPreviewUrl(undefined);

    const updatedAssets = (files ?? []).filter((x) => x.id !== file.uid);
    onChange && onChange(multiple ? updatedAssets : undefined);
  };

  const [pendingFiles, setPendingFiles] = useState<AssetMinimumInfo[]>([]);
  const handleCustomRequest = async ({ file, onError, onSuccess }: any) => {
    const uploadFile = file as File;

    if (!uploadFile) {
      return onError && onError(new Error("No file provided"));
    }

    try {
      const response = await assetApiService.createAsset([
        {
          file: uploadFile,
          fileName: uploadFile.name,
          type: assetType,
        },
      ]);

      if (response && response[0]) {
        const asset: AssetMinimumInfo = {
          id: response[0].id,
          fileName: response[0].fileName,
          fileLink: response[0].fileLink,
        };

        setPreviewUrl(asset.fileLink);
        if (!multiple) {
          onChange && onChange(asset);
        } else {
          // files got hoisted due to multiple calls, that's why we have this trick!
          setPendingFiles(fs => [...fs, asset]);
        }
      }
    } catch (error) {
      console.error(error);
      return onError && onError(error);
    }
  };
  useEffect(() => {
    if (pendingFiles.length) {
      const updatedAssets = (files ?? []).concat(pendingFiles);
      onChange && onChange(updatedAssets);
      setPendingFiles([]);
    }
  }, [pendingFiles]); // purposely


  return (
    <Dropper
      id={id}
      name={id ?? "file-upload"}
      multiple={multiple}
      fileList={uploadedFiles}
      // beforeUpload={handleBeforeUpload}
      onRemove={handleRemove}
      customRequest={handleCustomRequest}
      accept=".png,.gif,.jpg"
      {...rest}
    >
      {showPreview && previewUrl ? (
        <img className="preview-image" src={previewUrl} />
      ) : (
        <span className="empty-content">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <LabelText>{t["common.uploadDraggerText"]}</LabelText>
        </span>
      )}
    </Dropper>
  );
};
