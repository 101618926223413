import "react-quill/dist/quill.snow.css";
import styled, { css } from "styled-components";
import { Dropdown, MenuProps } from "antd";
import { DropdownButtonType } from "antd/es/dropdown";

const DropdownButton = styled(Dropdown.Button)<{
  $disableButton?: boolean;
  $disableDropdown?: boolean;
}>`
  &.ant-dropdown-button {
    ${({ $disableButton }) =>
      $disableButton &&
      css`
        > .ant-btn-compact-first-item {
          cursor: not-allowed;
          border-color: #00000026;
          color: rgba(0, 0, 0, 0.25);
          background: #0000000a;
          box-shadow: none;
          pointer-events: none;
        }
      `}

    ${({ $disableDropdown }) =>
      $disableDropdown &&
      css`
        > .ant-dropdown-trigger {
          cursor: not-allowed;
          border-color: #00000026;
          color: rgba(0, 0, 0, 0.25);
          background: #0000000a;
          box-shadow: none;
          pointer-events: none;

          &:before {
            display: none !important;
          }
        }
      `}
  }
`;

type Props = {
  buttonText: string;
  disableButton?: boolean;
  disableDropdown?: boolean;
  onButtonClick?: () => void;
  items: MenuProps["items"];
  type?: DropdownButtonType;
};

const DisableDropdownButton: React.FC<Props> = ({
  buttonText,
  items,
  disableButton,
  disableDropdown,
  onButtonClick,
  type,
}) => {
  return (
    <DropdownButton
      type={type}
      $disableButton={disableButton}
      $disableDropdown={disableDropdown}
      onClick={onButtonClick}
      menu={{ items }}
    >
      {buttonText}
    </DropdownButton>
  );
};

export default DisableDropdownButton;
