import { Col, FormInstance, Row, Tag } from "antd";
import "./selectionInputSetting.scss";
import { TemplateSetting } from "../../../../domain/valueObjects/templateSetting";
import { SelectionType } from "@cm/domain/valueObjects/selectionType";
import SelectionFormItem from "@cm/ui/molecules/formItems/selectionFormItem/SelectionFormItem";
import { SelectionLook } from "../../../../../../common/domain/valueObjects/SelectionLook";
import { useCommonStore } from "../../../../../../common/domain/state/stores/useCommonStore";
import { useMemo } from "react";

interface Props {
  setting: TemplateSetting;
  form: FormInstance;
}

const selectionTypeOptions = Object.entries(SelectionType).map(
  ([label, value]) => ({ label, value })
);
const selectionLookOptions = Object.entries(SelectionLook).map(
  ([label, value]) => ({ label, value })
);

export const SelectionInputSetting: React.FC<Props> = ({
  setting,
  form
}): JSX.Element => {
  const { ui: { defaultLocale, t }} = useCommonStore();
  const defaultValueOptions = useMemo(() =>
    setting.options?.map((o) => ({
      label:
        o.displayValues.find((x) => x.locale === defaultLocale?.code)
          ?.value ?? o.key,
      value: o.key
    })) ?? []
  , [setting, defaultLocale]);

  return (
    <Row className="selection-input-setting">
      <Col xl={8} lg={12}>
        <span className="label">{t["category.settings.inputType"]}</span>
        <Tag>{setting.inputType}</Tag>
      </Col>
      <Col xl={8} lg={12}>
        <span className="label">
          {t["category.settings.selectionType"]}
        </span>
        <SelectionFormItem
          form={form}
          formItemName={[setting.name, "inputSettings", "selectionType"]}
          value={setting.inputSettings?.selectionType}
          options={selectionTypeOptions}
        />
      </Col>
      <Col lg={24} xl={8}>
        <span className="label">{t["category.settings.dataType"]}</span>
        <Tag>{setting.dataType}</Tag>
      </Col>
      <Col lg={24}>
        <span className="label">
          {t["category.settings.defaultValue"]}
        </span>
        <SelectionFormItem
          form={form}
          formItemName={[setting.name, "default"]}
          value={setting.default}
          options={defaultValueOptions}
          isMultiple={setting.isMultipleValues}
        />
      </Col>
    </Row>
  );
};

export default SelectionInputSetting;
