import { ApiListResponse, LocaleValues } from "../../../../common/types/commonTypes";
import { CategoryEntity } from "../../domain/entities/categoryEntity";
import { CreateCategoryRequest } from "../../domain/valueObjects/createCategoryRequest";
import { ApiService } from "../../../../common/services/ApiService";
import { CategoryListResponse } from "../../domain/valueObjects/categoryListResponse";

const baseUrl = `/attribute/categories`;

export class CategoryApiService {
  public getCategories(locale: LocaleValues): Promise<ApiListResponse<CategoryListResponse>> {
    // TODO locale should be get from i18nService
    return ApiService.getFetcher(
      `${baseUrl}/all?locale=${locale}`
    );
  }

  public async getCategory(categoryId: string): Promise<CategoryEntity> {
    return ApiService.getFetcher(`${baseUrl}/${categoryId}`);
  }

  public createCategory(data: CreateCategoryRequest): Promise<CategoryEntity> {
    return ApiService.postFetcher(`${baseUrl}`, data);
  }

  public updateCategory(
    categoryId: string,
    data: CategoryEntity
  ): Promise<CategoryEntity> {
    return ApiService.putFetcher(`${baseUrl}/${categoryId}`, data);
  }

  public deleteCategory(categoryId: string): Promise<void> {
    return ApiService.deleteFetcher(`${baseUrl}/${categoryId}`);
  }
}

export const categoryApiService = new CategoryApiService();
