import { langToFlag } from "@pm/mock/flags";
import { LanguageKey } from "@pm/mock/languages";
import { FlagChip } from "@pm/ui/molecules/FlagChip";
import { Radio, Space } from "antd";
import { useLocaleOptions } from "../../../../../common/hooks/useLocaleOptions";

export interface LanguageSelectorHeaderProps {
  label?: string;
  locale: string | null;
  onChange?: (targetLocale: string) => void;
}

export const LanguageSelectorHeader: React.FC<LanguageSelectorHeaderProps> = ({
  label,
  locale,
  onChange
}) => {
  const { defaultLocale, localeOptions } = useLocaleOptions();

  function changeLocale(selectedLocale: string) {
    const targetLocale = localeOptions.find(
      (locale) => locale.value === selectedLocale
    );
    if (targetLocale) onChange?.(targetLocale?.value as string);
  }

  return (
    <Space align="center">
      {label && <span style={{ marginRight: 8 }}>{label}</span>}
      <Radio.Group
        value={locale || defaultLocale?.value}
        defaultValue={defaultLocale?.value}
        onChange={(e) => changeLocale(e.target.value)}
      >
        {localeOptions.map(({ label, value }, index) => (
          <Space direction="horizontal" key={index} align="center">
            <Radio key={value as LanguageKey} value={value}>
              <FlagChip marginTop={0} label={label} flag={langToFlag(value as any)} />
            </Radio>
          </Space>
        ))}
      </Radio.Group>
    </Space>
  );
};
