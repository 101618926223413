import { Input, InputNumber, Select, Tag } from "antd";
import { FormColumn } from "./formColumnType";
import { booleanToText } from "../../../../../../common/ui/molecules/selection/booleanToText";
import { settingsFields } from "../../../../common/businessLogic/settingsFields";
import {
  masterAttributeUiMapping,
  inputTypesUiMetadata,
  dataTypesUiMetadata,
  numberTypeUiMapping
} from "../../../../common/helpers/attributeUiMappings";
import {
  AttributeEntity,
  isAttributeEntity
} from "../../../../models/entities/attributeEntity";
import {
  booleanSelectRender,
  booleanSelectWithCustomLabels
} from "./inputTypeRenders/booleanSelectRender";
import { AttributeTemplate } from "../../../../models/valueObjects/attributeTemplate";
import { getAttributeType } from "@am/common/businessLogic/getAttributeType";
import { TranslationMap } from "../../../../../../features/i18n/translation";
import { numberTypeSelectRender } from "./inputTypeRenders/numberTypeSelectRender";
import { resolveDateTimeInput } from "./rowsResolvers/resolveDateTimeInput";

export const attributeTypeCol = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "isMasterAttribute",
  label: settingsFields(t).isMasterAttribute,
  span: 12,
  value: isAttributeEntity(attributeEntity) ? (
    <Tag>
      {masterAttributeUiMapping(t)[getAttributeType(attributeEntity)]?.label}
    </Tag>
  ) : (
    t["common.na"]
  ),
  render: (value) => <>{value}</>
});

export const requiredCol = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "isNullable",
  label: settingsFields(t).isNullable,
  span: 24,
  value: booleanToText(!attributeEntity.isNullable, t),
  ...booleanSelectWithCustomLabels(t["common.no"], t["common.yes"])
});

export const inputTypeRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "inputType",
  label: settingsFields(t).inputType,
  value: inputTypesUiMetadata(t)[attributeEntity.inputType]?.label,
  span: 12,
  isReadOnly: true,
  render: (value) => <Tag>{value}</Tag>
});

export const dataTypeRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "dataType",
  label: settingsFields(t).dataType,
  value: dataTypesUiMetadata(t)[attributeEntity.dataType]?.label,
  span: 12,
  hide: !!attributeEntity.dataType,
  isReadOnly: true,
  render: (value) => <Tag>{value}</Tag>
});

export const descriptionRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "description",
  label: settingsFields(t).description,
  value: attributeEntity.description,
  span: 24,
  render: (value) => (
    <Input.TextArea
      className="description"
      value={value?.toString()}
      placeholder={t["common.input"]}
      rows={1}
      cols={70}
    />
  )
});

export const isUniqueCol = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "isUnique",
  label: settingsFields(t).isUnique,
  value: booleanToText(attributeEntity.isUnique, t),
  span: 6,
  ...booleanSelectRender
});

export const multipleValuesRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "isMultipleValues",
  label: settingsFields(t).isMultipleValues,
  value: booleanToText(attributeEntity.isMultipleValues, t),
  span: 6,
  ...booleanSelectRender
});

export const toggleDefaultRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "default",
  label: settingsFields(t).default,
  span: 12,
  value: attributeEntity.default ? t["common.on"] : t["common.off"],
  ...booleanSelectWithCustomLabels(t["common.on"], t["common.off"])
});

export const defaultRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "default",
  label: settingsFields(t).default,
  value: attributeEntity.default,
  span: 6,
  hide: !!attributeEntity.dataType
})

export const patternRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: ["dataSettings", "pattern"],
  label: settingsFields(t).pattern,
  value: attributeEntity.dataSettings?.pattern,
  span: 12,
  hide: !!attributeEntity.dataType
})

export const minLengthRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: ["dataSettings", "minLength"],
  label: settingsFields(t).minLength,
  value: attributeEntity.dataSettings?.minLength ?? t["common.na"],
  span: 6,
  hide: !!attributeEntity.dataType,
  render: () => {
    return <InputNumber />;
  },
})

export const maxLengthRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: ["dataSettings", "maxLength"],
  label: settingsFields(t).maxLength,
  value: attributeEntity.dataSettings?.maxLength ?? t["common.na"],
  span: 6,
  hide: !!attributeEntity.dataType,
  render: () => {
    return <InputNumber />;
  },
})

export const numberTypeRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: ["dataSettings", "numberType"],
  label: settingsFields(t).numberType,
  hide: !!attributeEntity,
  span: 12,
  value:
    numberTypeUiMapping(t)[
      attributeEntity.dataSettings?.numberType ?? "rounded"
    ]?.label,
  ...numberTypeSelectRender(t)
})

export const minNumberValueRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "minValue",
  label: settingsFields(t).minValue,
  value: null,
  hide: !!attributeEntity.dataType,
  span: 6,
})

export const maxNumberValueRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "maxValue",
  label: settingsFields(t).maxValue,
  value: null,
  hide: !!attributeEntity.dataType,
  span: 6,
})

export const defaultDateTimeRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "default",
  label: settingsFields(t).default,
  value: null,
  hide: !!attributeEntity.dataType,
  span: 12,
  render: (value) => {
    return resolveDateTimeInput(attributeEntity.dataType, value);
  }
});

export const validFromRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "validFrom",
  label: settingsFields(t).validFrom,
  value: null,
  hide: !!attributeEntity.dataType,
  span: 12,
  render: (value) => {
    return resolveDateTimeInput(attributeEntity.dataType, value);
  }
});

export const validToRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "validTo",
  label: settingsFields(t).validTo,
  value: null,
  hide: !!attributeEntity.dataType,
  span: 12,
  render: (value) => {
    return resolveDateTimeInput(attributeEntity.dataType, value);
  }
});

export const allowedTypesRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => {
  const createOptions = (items: string[] | undefined | null) => {
    const fileTypeOptions =
      items?.map((type) => ({
        value: type,
        label: type
      })) ?? [];
    return fileTypeOptions;
  };

  return {
    key: ["inputSettings", "allowedFileTypes"],
    label: settingsFields(t).allowedFileTypes,
    value:
      attributeEntity.inputSettings?.allowedFileTypes &&
      attributeEntity.inputSettings.allowedFileTypes.length
        ? attributeEntity.inputSettings.allowedFileTypes.join(", ")
        : t["common.na"],
    hide: !!attributeEntity.dataType,
    span: 12,
    render: (value) => (
      <Select
        mode="tags"
        placeholder={t["attributeDetails.inputSettings.fileTypes.placeholder"]}
        options={createOptions(attributeEntity?.inputSettings?.allowedFileTypes)}
        tokenSeparators={[","]}
        defaultValue={
          attributeEntity?.inputSettings?.allowedFileTypes ?? undefined
        }
      ></Select>
    )
  }
}

export const minFileSizeRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "minSize",
  label: settingsFields(t).minSize,
  value: null,
  hide: !!attributeEntity.dataType,
  span: 12,
  render: () => {
    return <InputNumber />;
  },
});

export const maxFileSizeRow = (
  attributeEntity: AttributeTemplate | AttributeEntity,
  t: TranslationMap,
): FormColumn => ({
  key: "maxSize",
  label: settingsFields(t).maxSize,
  value: null,
  hide: !!attributeEntity.dataType,
  span: 12,
  render: () => {
    return <InputNumber />;
  },
});
