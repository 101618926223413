import { Button, Form, Input, Popover, Select } from "antd";
import { useState } from "react";
import { SettingInputType } from "../../../../domain/valueObjects/settingInputType";
import "./addSettingButton.scss";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

interface Props {
  onAddNewSetting: (setting: any) => void;
}

export const AddSettingButton: React.FC<Props> = ({
  onAddNewSetting
}): JSX.Element => {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useCommonUiStore();

  const inputTypeOptions = Object.entries(SettingInputType).map(
    ([key, value]) => ({
      label: key,
      value
    })
  );

  const handleFormFinished = (values: any) => {
    onAddNewSetting(values);
    setIsOpen(false);
    form.resetFields();
  };

  const addSettingForm = (
    <Form
      name="add-category-setting"
      form={form}
      layout="vertical"
      onFinish={handleFormFinished}
      autoComplete="off"
    >
      <Form.Item
        label={t["category.settings.settingName"]}
        name="name"
        rules={[
          {
            required: true,
            message: t["category.settings.nameRequireMessage"]
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t["category.settings.inputType"]}
        name="inputType"
        rules={[
          {
            required: true,
            message: t["category.settings.inputTypeRequireMessage"]
          }
        ]}
      >
        <Select options={inputTypeOptions} />
      </Form.Item>

      <div className="buttons">
        <Button onClick={() => setIsOpen(false)}>
          {t["common.cancel"]}
        </Button>
        <Button type="primary" htmlType="submit" className="submit">
          {t["common.createNew"]}
        </Button>
      </div>
    </Form>
  );

  return (
    <Popover
      open={isOpen}
      rootClassName="add-setting-button"
      placement="bottomRight"
      content={addSettingForm}
    >
      <Button size="small" onClick={() => setIsOpen(!isOpen)}>
        {t["category.settings.addSetting"]}
      </Button>
    </Popover>
  );
};

export default AddSettingButton;
