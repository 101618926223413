import { styled } from "styled-components";
import { Labels } from "../../../../../common/domain/valueObjects/Label";
import useLabel from "../hooks/useLabel";
import { AppstoreFilled } from "@ant-design/icons";
import { useCommonUiStore } from "../../../../../common/domain/state/stores/CommonUiStore";

type Props = {
  labels?: Labels[];
  lang: string | null;
  children: JSX.Element[];
}

const GroupContainer = styled.div`
  border: 2px solid #ddd;
  padding: 24px 16px;
  margin: 24px 0;
`

const GroupHeader = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 24px;
`

export default function AttributeGroup(props: Props) {
  const labelText = useLabel(props.labels);
  const { t } = useCommonUiStore();
  return (
    <GroupContainer>
      <GroupHeader><AppstoreFilled /> {t['common.group']}: {labelText}</GroupHeader>
      {props.children}
    </GroupContainer>
  )
}