type CommonConstants = {
  api: {
    version: string;
    baseUrl: string;
  };
  tablePageSize: number;
  isTesting: boolean;
  fileApplication: string;
};

export const commonConstants: CommonConstants = {
  api: {
    version: "v1",
    baseUrl: import.meta.env.VITE_API_BASE_URL ?? "http://localhost:22330"
  },
  tablePageSize: 10,
  fileApplication: "PIM",
  isTesting: !!(window as any).Cypress
};
