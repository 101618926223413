import { useMemo } from "react";
import { dataTypesUiMetadata } from "../../common/helpers/attributeUiMappings";
import { Form, FormInstance, Select, SelectProps } from "antd";
import { AttributeInputTypeEnum } from "../../models/enums/attributeInputTypeEnum";
import { dataTypesMapping } from "../../common/businessLogic/dataTypesMapping";
import { CreateAttributeRequest } from "../../models/valueObjects/createAttributeRequest";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { useCommonUiStore } from "../../../../common/domain/state/stores/CommonUiStore";

export const DataTypeSelector: React.FC<
  SelectProps & {
    form: FormInstance<CreateAttributeRequest>;
    inputType?: AttributeInputTypeEnum;
    placeholder?: string;
  }
> = ({
  form,
  inputType,
  placeholder,
  onChange,
  ...formItemProps
}): JSX.Element => {
  const { t } = useCommonUiStore();
  const options = useMemo(() => {
    let finalOptions = Object.entries(dataTypesUiMetadata(t)).map(
      ([attributeType, data]) => ({
        value: attributeType,
        label: data?.label
      })
    );

    if (inputType && dataTypesMapping[inputType]) {
      const finalDataTypes = dataTypesMapping[inputType];
      finalOptions = finalDataTypes.map((dataType) => ({
        label: dataTypesUiMetadata(t)[dataType]?.label,
        value: dataType
      }));
    }

    form.setFieldValue(
      "dataType",
      finalOptions.length === 1 ? finalOptions[0].value : undefined
    );

    return finalOptions;
  }, [inputType, form, t]);

  const handleChange: SelectProps["onChange"] = (...args) => {
    if (onChange) {
      onChange(...args);
    }
    form.validateFields(["dataType"]);
  };

  return (
    <Form.Item
      data-test={dMap["data-type"]}
      label={t["common.dataType"]}
      name="dataType"
      rules={[
        {
          required: true,
          message: t["createAttribute.dataTypeRequireMessage"]
        }
      ]}
      {...formItemProps}
    >
      <Select
        data-test={dMap["select"]}
        placeholder={placeholder ?? t["common.select"]}
        options={options}
        onChange={handleChange}
        allowClear
        style={{ width: "100%", ...formItemProps.style }}
      />
    </Form.Item>
  );
};
