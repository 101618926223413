import { IUserEntitiy } from "../../../../common/domain/entities/UserEntitiy";
import { guardFunction } from "../../../../common/modules/guardFunction";
import { AinDate } from "../../../../common/types/commonTypes";
import { AttributeDataTypeEnum } from "../enums/attributeDataTypeEnum";
import { AttributeInputTypeEnum } from "../enums/attributeInputTypeEnum";
import { AttributeStatusEnum } from "../enums/attributeStatus";
import {
  AttributeTemplate,
  emptyAttributeTemplate
} from "../valueObjects/attributeTemplate";

export type AttributeEntity = {
  id: string;
  updatedAt: AinDate;
  updatedBy: IUserEntitiy;
  createdAt: AinDate;
  isMasterAttribute: boolean;
  isSystemAttribute: boolean;
  isHavingDraft: boolean;
  status: AttributeStatusEnum;
} & AttributeTemplate;

export const isAttributeEntity = guardFunction<AttributeEntity>((value) => {
  return !!value.id;
});

export const emptyAttributeEntity: AttributeEntity = {
  id: "",
  name: "",
  description: "",
  createdAt: "",
  updatedAt: "",
  updatedBy: {
    id: "",
    name: ""
  },
  isMasterAttribute: false,
  isSystemAttribute: false,
  isHavingDraft: false,
  labels: [{ locale: "", value: "" }],
  dataType: AttributeDataTypeEnum.text,
  isMultipleValues: false,
  inputType: AttributeInputTypeEnum.simple,
  default: "",
  isUnique: false,
  isNullable: false,
  settings: "",
  status: AttributeStatusEnum.unpublished,
  template: [
    {
      ...emptyAttributeTemplate
    }
  ],
  options: []
};
