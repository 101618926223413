import { attributesRouteConstants } from "@am/attributeRoutes";
import { inputTypesUiMetadata } from "@am/common/helpers/attributeUiMappings";
import { Row, Space, Button, Col, Tag, Typography, Flex, Dropdown, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { getDate } from "../../../../../../common/modules/dateFormat";
import { AinIcon } from "../../../../../../common/ui/atoms/AinIcon";
import AinGrid from "../../../../../../common/ui/molecules/AinGrid";
import TextWithLabel, {
  ITextWithLabelProps
} from "../../../../../../common/ui/molecules/TextWithLabel";
import { AttributeEntity } from "@am/models/entities/attributeEntity";
import { themeColors } from "../../../../../../common/ui/styles/themeColors";
import { useAttributesStore } from "@am/state/stores/attributesStore";
import { useGetDetailsFormValuesPublisher } from "@am/hooks/useGetDetailsFormValuesPublisher";
import { DetailsHeaderStyled } from "./DetailsHeaderStyled";
import { AttributeTypeIcon } from "@am/ui/molecules/AttributeTypeIcon";
import { getAttributeTypeChecker } from "@am/common/businessLogic/getAttributeType";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";
import { useMemo } from "react";

const labelStyles = (
  width = 202
): Omit<ITextWithLabelProps, "label" | "size"> => ({
  direction: "horizontal",
  labelStyle: {
    width,
    display: "block"
  },
  color: themeColors.colorTextSecondary
});


type Props = {
  attributeEntity: AttributeEntity;
  onDelete: () => void;
};

enum DraftButtonValues {
  save = "save",
  delete = "delete"
}

export const DetailsHeader: React.FC<Props> = ({
  attributeEntity,
  onDelete
}): JSX.Element => {
  const navigate = useNavigate();
  const getDetailsFormValuesPublisher = useGetDetailsFormValuesPublisher();
  const { ui } = useAttributesStore();
  const { isEditing, setIsEditing, setLastButtonClick } = ui;

  const { t } = useCommonUiStore();

  function onPublishClicked() {
    setLastButtonClick("publish");
  }

  const handleDraftButtonClick: MenuProps["onClick"] = (args) => {
    const key = args.key as keyof typeof DraftButtonValues;
    if (key === "save") {
      setLastButtonClick("saveDraft");
    } else if (key === 'delete') {
      setLastButtonClick("deleteDraft");
    }
  };
  const handleSave = () => {
    handleDraftButtonClick({
      key: DraftButtonValues.save,
      keyPath: [],          
      // @ts-ignore      
      domEvent: null,
    })
  }

  const items = useMemo<MenuProps["items"]>(() => ([
    {
      key: DraftButtonValues.delete,
      label: t["common.deleteDraft"],
      danger: true,
      disabled: !attributeEntity.isHavingDraft,
    }
  ]), [t, attributeEntity]);

  return (
    <DetailsHeaderStyled className="details-header-container">
      <Row className="header-breadcrumb">
        <Space>
          <Button
            data-test={dMap["button-back"]}
            type="link"
            onClick={() => {
              setIsEditing(false);
              navigate(`/${attributesRouteConstants.attributes}`);
            }}
            size="small"
          >
            <AinIcon icon="arrow-left" size={12}></AinIcon>
          </Button>
          <span className="header-breadcrumb-app-title">
            {t["common.attribute"]}
          </span>
          /<strong>{attributeEntity.name}</strong>
        </Space>
      </Row>

      {/* Input Type, Name --- Actions */}
      <Col className="header-details-name-container">
        <Space>
          <AttributeTypeIcon attributeEntity={attributeEntity} />

          <Tag>{inputTypesUiMetadata(t)[attributeEntity.inputType]?.label}</Tag>

          <Typography.Title className="header-details-name" level={5}>
            {attributeEntity.name}
          </Typography.Title>
        </Space>
      </Col>

      <Row className="header-details-info-and-actions" justify="space-between">
        {/* Actions */}
        <Col span={17}>
          <AinGrid colSpans={[12, 12]} colGap={8}>
            <Row className="details-info-row">
              <Col>
                <TextWithLabel
                  label={t["common.created"]}
                  {...labelStyles()}
                >
                  {getDate(attributeEntity.createdAt)}
                </TextWithLabel>
              </Col>
              <Col>
                <TextWithLabel
                  label={t["attribute.id"]}
                  direction="horizontal"
                  {...labelStyles()}
                >
                  {attributeEntity.id}
                </TextWithLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextWithLabel
                  label={t["common.lastUpdated"]}
                  direction="horizontal"
                  {...labelStyles()}
                >
                  {getDate(attributeEntity.updatedAt)}
                </TextWithLabel>
              </Col>
              <Col>
                {/* <TextWithLabel
                  label={t["common.status"]}
                  direction="horizontal"
                  {...labelStyles()}
                >
                  <Tag
                    color={
                      attributeStatusUiMapping()[attributeEntity.status]?.color
                    }
                  >
                    {attributeStatusUiMapping()[attributeEntity.status]?.label}
                  </Tag>
                </TextWithLabel> */}
              </Col>
            </Row>
          </AinGrid>
        </Col>

        {getAttributeTypeChecker(attributeEntity).isSystem ? null : (
          <Flex align="end" vertical>
            { attributeEntity.isHavingDraft ? 
              <Space style={{marginBottom: 5}}>{ isEditing ? t["attributeDetails.draftEditText"] : t["attributeDetails.draftViewText"] }</Space> 
              : null }
            <Space>
              <Button type="default" danger onClick={onDelete}>
                <AinIcon icon="delete-outlined" height={22}></AinIcon>
              </Button>
              {isEditing ? (
                <Space>
                  <Dropdown.Button
                    onClick={handleSave}
                    menu={{
                      items,
                      onClick: handleDraftButtonClick
                    }}
                    trigger={["click"]}
                  >
                    {t["common.saveDraft"]}
                  </Dropdown.Button>

                  <Button onClick={() => setIsEditing(false)}>
                    {t["common.cancel"]}
                  </Button>
                  <Button
                    data-test={dMap["publish-button"]}
                    type="primary"
                    onClick={onPublishClicked}
                    onMouseOver={() => {
                      getDetailsFormValuesPublisher();
                    }}
                    onFocus={() => {
                      getDetailsFormValuesPublisher();
                    }}
                  >
                    {t["common.publish"]}
                  </Button>
                </Space>
              ) : (
                <Button
                  data-test={dMap["edit-button"]}
                  onClick={() => setIsEditing(true)}
                >
                  {t["common.edit"]}
                </Button>
              )}
            </Space>
          </Flex>
        )}
      </Row>
    </DetailsHeaderStyled>
  );
};
