export interface GetLocaleResponse {
  id: string;
  name: string;
  code: string;
  icon: string;
  isActive: boolean;
  isDefault: boolean;
}

export const emptyLocale: GetLocaleResponse = {
  id: "",
  name: "",
  code: "",
  icon: "",
  isActive: false,
  isDefault: false,
};

export interface GetLocaleAPIResponse extends Omit<GetLocaleResponse, "code"> {
  code: string | null;
}

export const emptyLocaleWithNullableCode: GetLocaleAPIResponse = {
  ...emptyLocale,
  code: null,
};
