import { DependentValue } from "@pm/models/ProductEntity";

export default function getLocalizedValue<T>(
  dependValues: DependentValue<T>[] | null | undefined,
  locale: string | null,
  channel: string | null | undefined,
  fallback: T,
) {
  if (!dependValues || dependValues.length === 0) return fallback;
  let target = dependValues.find(x => x.locale === locale && x.channel === channel);

  // fallback to null value
  if (!target) target = dependValues.find(x => (x.locale ?? null) === null && (x.channel ?? null) === null);
  return target?.value ?? fallback;
}