import { Form, FormInstance, Select } from "antd";
import "./booleanFormItem.scss";
import { useEffect, useRef, useState } from "react";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

interface Props {
  formItemName: string[];
  onText: string;
  offText: string;
  value: boolean | undefined | null;
  form: FormInstance;
}

export const BooleanFormItem: React.FC<Props> = ({
  form,
  formItemName,
  value,
  onText,
  offText
}): JSX.Element => {
  const [editing, setEditing] = useState<boolean>(false);
  const [updatedValue, setUpdatedValue] = useState<any>(value);
  const inputRef = useRef<any>(null);
  const { t } = useCommonUiStore();

  useEffect(() => {
    form.setFieldValue(formItemName, value);
  }, [value]);

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const resolveValueText = () => {
    if (updatedValue === undefined || updatedValue === null) {
      return t["category.common.na"];
    }

    return updatedValue ? onText : offText;
  };

  const options = [
    { label: onText, value: true },
    { label: offText, value: false }
  ];

  if (!editing) {
    return (
      <span className="boolean-form-item" onClick={() => setEditing(true)}>
        {resolveValueText()}
      </span>
    );
  }

  const handleOnBlur = async () => {
    setEditing(false);

    let newValue = await form.validateFields();
    formItemName.forEach((x) => {
      newValue = newValue[x];
    });
    setUpdatedValue(newValue);
  };

  return (
    <Form.Item className="boolean-form-item" name={formItemName}>
      <Select ref={inputRef} options={options} onBlur={handleOnBlur} />
    </Form.Item>
  );
};

export default BooleanFormItem;
