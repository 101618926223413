import { Button, Form, Input, Popover } from "antd";
import { useState } from "react";
import "./addGroupButton.scss";
import { useCommonStore } from "../../../../../../common/domain/state/stores/useCommonStore";
import { Labels } from "../../../../../../common/domain/valueObjects/Label";

interface Props {
  onAddNewGroup: (name: string, labels: Labels[]) => void;
}

export const AddGroupButton: React.FC<Props> = ({
  onAddNewGroup
}): JSX.Element => {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    ui: { locales, t }
  } = useCommonStore();

  const handleFormFinished = (values: any) => {
    onAddNewGroup(
      values.name,
      locales.map<Labels>((x) => ({ locale: x.code, value: values[x.code] }))
    );
    setIsOpen(false);
    form.resetFields();
  };

  const addGroupForm = (
    <Form
      name="add-category-group"
      form={form}
      layout="vertical"
      onFinish={handleFormFinished}
      autoComplete="off"
    >
      <Form.Item
        label={t["category.addGroup.name"]}
        name="name"
        rules={[
          {
            required: true,
            message: t["category.addGroup.nameRequireMessage"]
          }
        ]}
      >
        <Input />
      </Form.Item>

      {locales.map((x) => (
        <Form.Item
          key={x.code}
          label={x.name}
          name={x.code}
          rules={[
            {
              required: true,
              message: `${t["category.addGroup.labelRequireMessage"]} ${
                x.name
              }`
            }
          ]}
        >
          <Input />
        </Form.Item>
      ))}

      <div className="buttons">
        <Button onClick={() => setIsOpen(false)}>
          {t["common.cancel"]}
        </Button>
        <Button type="primary" htmlType="submit" className="submit">
          {t["common.createNew"]}
        </Button>
      </div>
    </Form>
  );

  return (
    <Popover
      open={isOpen}
      rootClassName="add-group-button"
      placement="bottomRight"
      content={addGroupForm}
    >
      <Button size="small" onClick={() => setIsOpen(!isOpen)}>
        {t["category.details.addGroup"]}
      </Button>
    </Popover>
  );
};

export default AddGroupButton;
