import { AttributeDataTypeEnum } from "../../models/enums/attributeDataTypeEnum";
import { AttributeInputTypeEnum } from "../../models/enums/attributeInputTypeEnum";
import { AttributesInputSettings } from "../../models/valueObjects/inputSettings";
import { TagProps } from "antd";
import { AttributeStatusEnum } from "../../models/enums/attributeStatus";
import { AttributesDataSettings } from "../../models/valueObjects/dataSettings";
import { AttributeTypesEnum } from "@am/types/attributeCommonTypes";
import { DefaultOptionType } from "antd/es/select";
import { TranslationMap } from "../../../../features/i18n/translation";

type StatusMetaData = {
  label: string;
  abbrev?: string;
  color?: TagProps["color"];
  beKey?: string;
};

export const masterAttributeUiMapping: (t: TranslationMap) => Record<
  AttributeTypesEnum,
  StatusMetaData | undefined
> = (t: TranslationMap) => ({
  [AttributeTypesEnum.system]: {
    label: t["common.system"],
    beKey: "isSystemAttribute"
  },
  [AttributeTypesEnum.master]: {
    label: t["common.master"],
    beKey: "isMasterAttribute"
  },
  [AttributeTypesEnum.common]: {
    label: t["common.common"]
  }
});

export function getAttributeTypeAsSelectOptions(t: TranslationMap): DefaultOptionType[] {
  const options = Object.entries(
    masterAttributeUiMapping(t)
  ).map<DefaultOptionType>(([key, label]) => ({
    label: label?.label,
    value: key
  }));
  return options;
}

type OptionalExceptFor<T, TRequired extends keyof T = keyof T> = Partial<
  Pick<T, Exclude<keyof T, TRequired>>
> &
  Required<Pick<T, TRequired>>;

export const formLayoutOrientatoinUiMapping: (t: TranslationMap) => Record<
  // @ts-expect-error should scope settings
  AttributesInputSettings["layoutOrientation"],
  StatusMetaData | undefined
> = (t: TranslationMap) => ({
  horizontal: {
    label: t["attributeDetails.inputSettings.layoutOrientation.horizontal"]
  },
  vertical: {
    label: t["attributeDetails.inputSettings.layoutOrientation.vertical"]
  }
});

export const formSelectionTypeUiMapping: (t: TranslationMap) => Record<
  // @ts-expect-error should scope settings
  AttributesInputSettings["selectionType"],
  StatusMetaData | undefined
> = (t: TranslationMap) => ({
  single: {
    label: t["common.single"]
  },
  multiple: {
    label: t["common.multiple"]
  }
});

export const dataTypesUiMetadata: (t: TranslationMap) => Record<
  AttributeDataTypeEnum,
  StatusMetaData | undefined
> = (t: TranslationMap) => ({
  [AttributeDataTypeEnum.text]: {
    label: t["common.dataTypes.text"]
  },
  [AttributeDataTypeEnum.email]: {
    label: t["common.dataTypes.email"]
  },
  [AttributeDataTypeEnum.phone]: {
    label: t["common.dataTypes.phone"]
  },
  [AttributeDataTypeEnum.number]: {
    label: t["common.dataTypes.number"]
  },
  [AttributeDataTypeEnum.date]: {
    label: t["common.dataTypes.date"]
  },
  [AttributeDataTypeEnum.time]: {
    label: t["common.dataTypes.time"]
  },
  [AttributeDataTypeEnum.file]: {
    label: t["common.dataTypes.file"]
  },
  [AttributeDataTypeEnum.object]: {
    label: t["common.dataTypes.object"]
  },
  [AttributeDataTypeEnum.boolean]: {
    label: t["common.dataTypes.boolean"]
  },
  [AttributeDataTypeEnum.dateTime]: {
    label: t["common.dataTypes.dateTime"]
  }
});

export const inputTypesUiMetadata: (t: TranslationMap) => Record<
  AttributeInputTypeEnum,
  StatusMetaData | undefined
> = (t: TranslationMap) => ({
  [AttributeInputTypeEnum.simple]: {
    label: t["common.inputTypes.simple"]
  },
  [AttributeInputTypeEnum.textArea]: {
    label: t["common.inputTypes.textarea"]
  },
  [AttributeInputTypeEnum.toggle]: {
    label: t["common.inputTypes.toggle"]
  },
  [AttributeInputTypeEnum.selection]: {
    label: t["common.inputTypes.selection"]
  },
  [AttributeInputTypeEnum.uploader]: {
    label: t["common.inputTypes.uploader"]
  },
  [AttributeInputTypeEnum.form]: {
    label: t["common.inputTypes.form"]
  }
});

export const numberTypeUiMapping: (t: TranslationMap) => Record<
  AttributesDataSettings["numberType"],
  StatusMetaData | undefined
> = (t: TranslationMap) => ({
  rounded: {
    label: t["common.rounded"]
  },
  decimal: {
    label: t["common.decimal"]
  }
});

export const attributeStatusUiMapping: (t: TranslationMap) => Record<
  AttributeStatusEnum,
  StatusMetaData | undefined
> = (t: TranslationMap) => ({
  published: {
    label: t["common.published"],
    color: "cyan"
  },
  unpublished: {
    label: t["common.unpublished"]
  }
});
