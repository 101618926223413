import { Labels } from "../../../common/domain/valueObjects/Label";
import { generateId } from "../../../common/modules/debugging/generateId";
import { dataTypesMapping } from "../common/businessLogic/dataTypesMapping";
import {
  AttributeEntity,
  emptyAttributeEntity
} from "../models/entities/attributeEntity";
import { AttributeDataTypeEnum } from "../models/enums/attributeDataTypeEnum";
import { AttributeInputTypeEnum } from "../models/enums/attributeInputTypeEnum";
import { AttributeStatusEnum } from "../models/enums/attributeStatus";
import { AttributeObjectOption } from "../models/valueObjects/attributeObjectOptions";
import {
  emptyAttributeTemplate,
  AttributeTemplate
} from "../models/valueObjects/attributeTemplate";

const attributeEntitySimple: AttributeEntity = {
  ...emptyAttributeEntity,
  id: "id-1",
  name: "clothingMaterial",
  description:
    "Shown as tooltip for the attribute name to provide further note or explanation.",
  createdAt: "01-01-2023",
  updatedAt: "01-01-2023",
  updatedBy: {
    id: "User123",
    name: "Reviewer123"
  },
  isMasterAttribute: true,
  labels: [
    { locale: "en-US", value: "Clothing material" },
    { locale: "vi-VN", value: "Chất liệu trang phục" }
  ],
  dataType: AttributeDataTypeEnum.text,
  isMultipleValues: true,
  inputType: AttributeInputTypeEnum.simple,
  default: "default",
  isUnique: true,
  settings: "settings",
  template: [
    {
      ...emptyAttributeTemplate,
      name: "location",
      description:
        "Shown as tooltip for the attribute name to provide further note or explanation.",
      labels: [
        { locale: "en-US", value: "Location" },
        { locale: "vi-VN", value: "Vị trí" }
      ],
      dataType: AttributeDataTypeEnum.object,
      isMultipleValues: true,
      inputType: AttributeInputTypeEnum.selection,
      default: "overall",
      isUnique: true,
      settings: "settings",
      template: [],
      options: [
        {
          key: "overall",
          displayValues: [
            { locale: "en-US", value: "Overall" },
            { locale: "vi-VN", value: "Tổng thể" }
          ]
        },
        {
          key: "inside",
          displayValues: [
            { locale: "en-US", value: "Inside" },
            { locale: "vi-VN", value: "Bên trong" }
          ]
        }
      ]
    },
    {
      ...emptyAttributeTemplate,
      name: "composition",
      description:
        "Shown as tooltip for the attribute name to provide further note or explanation.",
      labels: [
        { locale: "en-US", value: "Composition" },
        { locale: "vi-VN", value: "Cấu tạo" }
      ],
      dataType: AttributeDataTypeEnum.object,
      isMultipleValues: true,
      inputType: AttributeInputTypeEnum.form,
      default: "default",
      isUnique: true,
      settings: "settings",
      template: [
        {
          ...emptyAttributeTemplate,
          name: "material",
          description:
            "Shown as tooltip for the attribute name to provide further note or explanation.",
          labels: [
            { locale: "en-US", value: "Material" },
            { locale: "vi-VN", value: "Chất liệu" }
          ],
          dataType: AttributeDataTypeEnum.text,
          isMultipleValues: true,
          inputType: AttributeInputTypeEnum.selection,
          default: "default",
          isUnique: true,
          settings: "settings",
          template: [],
          options: [
            {
              key: "aluminium",
              displayValues: [
                { locale: "en-US", value: "Aluminium" },
                { locale: "vi-VN", value: "Nhôm" }
              ]
            },
            {
              key: "filz",
              displayValues: [
                { locale: "en-US", value: "Filz" },
                { locale: "vi-VN", value: "Nỉ" }
              ]
            }
          ]
        },
        {
          ...emptyAttributeTemplate,
          name: "percentage",
          description:
            "Shown as tooltip for the attribute name to provide further note or explanation.",
          labels: [
            { locale: "en-US", value: "Percentage" },
            { locale: "vi-VN", value: "Phần trăm" }
          ],
          dataType: AttributeDataTypeEnum.number,
          isMultipleValues: true,
          inputType: AttributeInputTypeEnum.simple,
          default: "default",
          isUnique: true,
          settings: "settings",
          template: [],
          options: []
        }
      ],
      options: []
    }
  ],
  options: []
};

const attributeEntityForm: AttributeEntity = {
  ...emptyAttributeEntity,
  id: "id-1",
  name: "clothingMaterial",
  description:
    "Shown as tooltip for the attribute name to provide further note or explanation.",
  createdAt: "01-01-2023",
  updatedAt: "01-01-2023",
  updatedBy: {
    id: "User123",
    name: "Reviewer123"
  },
  isMasterAttribute: true,
  labels: [
    { locale: "en-US", value: "Clothing material" },
    { locale: "vi-VN", value: "Chất liệu trang phục" }
  ],
  dataType: AttributeDataTypeEnum.object,
  isMultipleValues: true,
  inputType: AttributeInputTypeEnum.form,
  default: "default",
  isUnique: true,
  settings: "settings",
  template: [
    {
      ...emptyAttributeTemplate,
      name: "location",
      description:
        "Shown as tooltip for the attribute name to provide further note or explanation.",
      labels: [
        { locale: "en-US", value: "Location" },
        { locale: "vi-VN", value: "Vị trí" }
      ],
      dataType: AttributeDataTypeEnum.text,
      isMultipleValues: true,
      inputType: AttributeInputTypeEnum.simple,
      default: "overall",
      isUnique: true,
      settings: "settings",
      template: [],
      options: [
        {
          key: "overall",
          displayValues: [
            { locale: "en-US", value: "Overall" },
            { locale: "vi-VN", value: "Tổng thể" }
          ]
        },
        {
          key: "inside",
          displayValues: [
            { locale: "en-US", value: "Inside" },
            { locale: "vi-VN", value: "Bên trong" }
          ]
        }
      ]
    },
    {
      ...emptyAttributeTemplate,
      name: "composition",
      description:
        "Shown as tooltip for the attribute name to provide further note or explanation.",
      labels: [
        { locale: "en-US", value: "Composition" },
        { locale: "vi-VN", value: "Cấu tạo" }
      ],
      dataType: AttributeDataTypeEnum.object,
      isMultipleValues: true,
      inputType: AttributeInputTypeEnum.form,
      default: "default",
      isUnique: true,
      settings: "settings",
      template: [
        {
          ...emptyAttributeTemplate,
          name: "material",
          description:
            "Shown as tooltip for the attribute name to provide further note or explanation.",
          labels: [
            { locale: "en-US", value: "Material" },
            { locale: "vi-VN", value: "Chất liệu" }
          ],
          dataType: AttributeDataTypeEnum.text,
          isMultipleValues: true,
          inputType: AttributeInputTypeEnum.selection,
          default: "default",
          isUnique: true,
          settings: "settings",
          template: [],
          options: [
            {
              key: "aluminium",
              displayValues: [
                { locale: "en-US", value: "Aluminium" },
                { locale: "vi-VN", value: "Nhôm" }
              ]
            },
            {
              key: "filz",
              displayValues: [
                { locale: "en-US", value: "Filz" },
                { locale: "vi-VN", value: "Nỉ" }
              ]
            }
          ]
        },
        {
          ...emptyAttributeTemplate,
          name: "percentage",
          description:
            "Shown as tooltip for the attribute name to provide further note or explanation.",
          labels: [
            { locale: "en-US", value: "Percentage" },
            { locale: "vi-VN", value: "Phần trăm" }
          ],
          dataType: AttributeDataTypeEnum.number,
          isMultipleValues: true,
          inputType: AttributeInputTypeEnum.simple,
          default: "default",
          isUnique: true,
          settings: "settings",
          template: [],
          options: []
        }
      ],
      options: []
    },
    {
      ...emptyAttributeTemplate,
      name: "composition1",
      description:
        "Shown as tooltip for the attribute name to provide further note or explanation.",
      labels: [
        { locale: "en-US", value: "Composition" },
        { locale: "vi-VN", value: "Cấu tạo" }
      ],
      dataType: AttributeDataTypeEnum.text,
      isMultipleValues: true,
      inputType: AttributeInputTypeEnum.simple,
      default: "default",
      isUnique: true,
      settings: "settings",
      template: [
        {
          ...emptyAttributeTemplate,
          name: "material",
          description:
            "Shown as tooltip for the attribute name to provide further note or explanation.",
          labels: [
            { locale: "en-US", value: "Material" },
            { locale: "vi-VN", value: "Chất liệu" }
          ],
          dataType: AttributeDataTypeEnum.text,
          isMultipleValues: true,
          inputType: AttributeInputTypeEnum.selection,
          default: "default",
          isUnique: true,
          settings: "settings",
          template: [],
          options: [
            {
              key: "aluminium",
              displayValues: [
                { locale: "en-US", value: "Aluminium" },
                { locale: "vi-VN", value: "Nhôm" }
              ]
            },
            {
              key: "filz",
              displayValues: [
                { locale: "en-US", value: "Filz" },
                { locale: "vi-VN", value: "Nỉ" }
              ]
            }
          ]
        },
        {
          ...emptyAttributeTemplate,
          name: "percentage",
          description:
            "Shown as tooltip for the attribute name to provide further note or explanation.",
          labels: [
            { locale: "en-US", value: "Percentage" },
            { locale: "vi-VN", value: "Phần trăm" }
          ],
          dataType: AttributeDataTypeEnum.number,
          isMultipleValues: true,
          inputType: AttributeInputTypeEnum.simple,
          default: "default",
          isUnique: true,
          settings: "settings",
          template: [],
          options: []
        }
      ],
      options: []
    }
  ],
  options: []
};

const attributeEntityForm_oneTemplate: AttributeEntity = {
  ...emptyAttributeEntity,
  id: "fashionLaundryCareLabel",
  name: "fashionLaundryCareLabel",
  description:
    "Shown as tooltip for the attribute name to provide further note or explanation.",
  createdAt: "01-01-2023",
  updatedAt: "01-01-2023",
  updatedBy: {
    id: "User123",
    name: "Reviewer123"
  },
  isMasterAttribute: true,
  labels: [
    { locale: "en-US", value: "Fashion Laundry Care Label" },
    { locale: "vi-VN", value: "Hướng dẫn chăm sóc sản phẩm" }
  ],
  dataType: AttributeDataTypeEnum["text"],
  isMultipleValues: true,
  inputType: AttributeInputTypeEnum["simple"],
  default: "default",
  isUnique: false,
  settings: "settings",
  template: [
    {
      ...emptyAttributeTemplate,
      name: "washing",
      description:
        "Shown as tooltip for the attribute name to provide further note or explanation.",
      labels: [
        { locale: "en-US", value: "Washing" },
        { locale: "vi-VN", value: "Giặt" }
      ],
      dataType: AttributeDataTypeEnum["object"],
      isMultipleValues: false,
      inputType: AttributeInputTypeEnum["form"],
      default: "overall",
      isUnique: false,
      settings: "settings",
      template: null,
      options: null
    }
  ],
  options: null
};

const attributeEntityForm_careLabel: AttributeEntity = {
  ...emptyAttributeEntity,
  id: "fashionLaundryCareLabel",
  name: "fashionLaundryCareLabel",
  description:
    "Shown as tooltip for the attribute name to provide further note or explanation.",
  createdAt: "01-01-2023",
  updatedAt: "01-01-2023",
  updatedBy: {
    id: "User123",
    name: "Reviewer123"
  },
  isMasterAttribute: true,
  labels: [
    { locale: "en-US", value: "Fashion Laundry Care Label" },
    { locale: "vi-VN", value: "Hướng dẫn chăm sóc sản phẩm" }
  ],
  dataType: AttributeDataTypeEnum.object,
  isMultipleValues: true,
  inputType: AttributeInputTypeEnum.selection,
  default: "default",
  isUnique: false,
  settings: "settings",
  template: [
    {
      ...emptyAttributeTemplate,
      name: "washing",
      description:
        "Shown as tooltip for the attribute name to provide further note or explanation.",
      labels: [
        { locale: "en-US", value: "Washing" },
        { locale: "vi-VN", value: "Giặt" }
      ],
      dataType: AttributeDataTypeEnum["object"],
      isMultipleValues: false,
      inputType: AttributeInputTypeEnum["form"],
      default: "overall",
      isUnique: false,
      settings: "settings",
      template: [
        {
          ...emptyAttributeTemplate,
          name: "nested_washing",
          description:
            "Shown as tooltip for the attribute name to provide further note or explanation.",
          labels: [
            { locale: "en-US", value: "Washing" },
            { locale: "vi-VN", value: "Giặt" }
          ],
          dataType: AttributeDataTypeEnum["object"],
          isMultipleValues: false,
          inputType: AttributeInputTypeEnum["form"],
          default: "overall",
          isUnique: false,
          settings: "settings",
          template: null,
          options: [
            {
              key: "nested_washing_50_veryGentle_level_2",
              displayValues: [
                { locale: "en-US", value: "Very Gentle at 50 degree" },
                { locale: "vi-VN", value: "Giặt nhẹ nhàng ở 50 độ C" }
              ],
              files: [
                {
                  locale: "en-US",
                  chanel: null,
                  type: "banner",
                  fileId: "image0001",
                  fileUrl: "optional image url"
                },
                {
                  locale: "vi-VN",
                  chanel: null,
                  type: "banner",
                  fileId: "image0002"
                }
              ]
            },
            {
              key: "nested_washing_40_normal_level_2",
              displayValues: [
                { locale: "en-US", value: "Normal at 40 degree" },
                { locale: "vi-VN", value: "Giặt bình thường ở 40 độ" }
              ],
              files: [
                {
                  locale: "en-US",
                  chanel: null,
                  type: "banner",
                  fileId: "image0001",
                  fileUrl: "optional image url"
                },
                {
                  locale: "vi-VN",
                  chanel: null,
                  type: "banner",
                  fileId: "image0002"
                }
              ]
            }
          ]
        }
      ],
      options: [
        {
          key: "washing_50_veryGentle_level_1",
          displayValues: [
            { locale: "en-US", value: "Very Gentle at 50 degree" },
            { locale: "vi-VN", value: "Giặt nhẹ nhàng ở 50 độ C" }
          ],
          files: [
            {
              locale: "en-US",
              chanel: null,
              type: "banner",
              fileId: "image0001",
              fileUrl: "optional image url"
            },
            {
              locale: "vi-VN",
              chanel: null,
              type: "banner",
              fileId: "image0002"
            }
          ]
        },
        {
          key: "washing_40_normal_level_1",
          displayValues: [
            { locale: "en-US", value: "Normal at 40 degree" },
            { locale: "vi-VN", value: "Giặt bình thường ở 40 độ" }
          ],
          files: [
            {
              locale: "en-US",
              chanel: null,
              type: "banner",
              fileId: "image0001",
              fileUrl: "optional image url"
            },
            {
              locale: "vi-VN",
              chanel: null,
              type: "banner",
              fileId: "image0002"
            }
          ]
        }
      ]
    }
  ],
  // options: null,
  options: [
    {
      key: "washing_50_veryGentle",
      displayValues: [
        { locale: "en-US", value: "Very Gentle at 50 degree" },
        { locale: "vi-VN", value: "Giặt nhẹ nhàng ở 50 độ C" }
      ],
      files: [
        {
          locale: "en-US",
          chanel: null,
          type: "banner",
          fileId: "image0001",
          fileUrl: "optional image url"
        },
        {
          locale: "vi-VN",
          chanel: null,
          type: "banner",
          fileId: "image0002"
        }
      ]
    },
    {
      key: "washing_40_normal",
      displayValues: [
        { locale: "en-US", value: "Normal at 40 degree" },
        { locale: "vi-VN", value: "Giặt bình thường ở 40 độ" }
      ],
      files: [
        {
          locale: "en-US",
          chanel: null,
          type: "banner",
          fileId: "image0001",
          fileUrl: "optional image url"
        },
        {
          locale: "vi-VN",
          chanel: null,
          type: "banner",
          fileId: "image0002"
        }
      ]
    }
  ]
};

const attributeEntityTextarea_careLabel: AttributeEntity = {
  ...emptyAttributeEntity,
  id: "fashionLaundryCareLabel_textarea",
  name: "fashionLaundryCareLabel_textarea",
  description:
    "Shown as tooltip for the attribute name to provide further note or explanation.",
  createdAt: "01-01-2023",
  updatedAt: "01-01-2023",
  updatedBy: {
    id: "User123",
    name: "Reviewer123"
  },
  isMasterAttribute: true,
  labels: [
    { locale: "en-US", value: "Fashion Laundry Care Label" },
    { locale: "vi-VN", value: "Hướng dẫn chăm sóc sản phẩm" }
  ],
  dataType: AttributeDataTypeEnum["text"],
  isMultipleValues: true,
  inputType: AttributeInputTypeEnum["textArea"],
  default: "default",
  isUnique: false,
  settings: "settings",
  template: [
    {
      ...emptyAttributeTemplate,
      name: "washing",
      description:
        "Shown as tooltip for the attribute name to provide further note or explanation.",
      labels: [
        { locale: "en-US", value: "Washing" },
        { locale: "vi-VN", value: "Giặt" }
      ],
      dataType: AttributeDataTypeEnum["text"],
      isMultipleValues: false,
      inputType: AttributeInputTypeEnum["textArea"],
      default: "overall",
      isUnique: false,
      settings: "settings",
      template: null,
      options: [
        {
          key: "washing_50_veryGentle",
          displayValues: [
            { locale: "en-US", value: "Very Gentle at 50 degree" },
            { locale: "vi-VN", value: "Giặt nhẹ nhàng ở 50 độ C" }
          ],
          files: [
            {
              locale: "en-US",
              chanel: null,
              type: "banner",
              fileId: "image0001",
              fileUrl: "optional image url"
            },
            {
              locale: "vi-VN",
              chanel: null,
              type: "banner",
              fileId: "image0002"
            }
          ]
        },
        {
          key: "washing_40_normal",
          displayValues: [
            { locale: "en-US", value: "Normal at 40 degree" },
            { locale: "vi-VN", value: "Giặt bình thường ở 40 độ" }
          ],
          files: [
            {
              locale: "en-US",
              chanel: null,
              type: "banner",
              fileId: "image0001",
              fileUrl: "optional image url"
            },
            {
              locale: "vi-VN",
              chanel: null,
              type: "banner",
              fileId: "image0002"
            }
          ]
        }
      ]
    }
  ],
  // options: null,
  options: [
    {
      key: "washing_50_veryGentle",
      displayValues: [
        { locale: "en-US", value: "Very Gentle at 50 degree" },
        { locale: "vi-VN", value: "Giặt nhẹ nhàng ở 50 độ C" }
      ],
      files: [
        {
          locale: "en-US",
          chanel: null,
          type: "banner",
          fileId: "image0001",
          fileUrl: "optional image url"
        },
        {
          locale: "vi-VN",
          chanel: null,
          type: "banner",
          fileId: "image0002"
        }
      ]
    },
    {
      key: "washing_40_normal",
      displayValues: [
        { locale: "en-US", value: "Normal at 40 degree" },
        { locale: "vi-VN", value: "Giặt bình thường ở 40 độ" }
      ],
      files: [
        {
          locale: "en-US",
          chanel: null,
          type: "banner",
          fileId: "image0001",
          fileUrl: "optional image url"
        },
        {
          locale: "vi-VN",
          chanel: null,
          type: "banner",
          fileId: "image0002"
        }
      ]
    }
  ]
};

const attributeEntityToggle_careLabel: AttributeEntity = {
  ...emptyAttributeEntity,
  id: "fashionLaundryCareLabel_toggle",
  name: "fashionLaundryCareLabel_toggle",
  description:
    "Shown as tooltip for the attribute name to provide further note or explanation.",
  createdAt: "01-01-2023",
  updatedAt: "01-01-2023",
  updatedBy: {
    id: "User123",
    name: "Reviewer123"
  },
  isMasterAttribute: true,
  labels: [
    { locale: "en-US", value: "Fashion Laundry Care Label" },
    { locale: "vi-VN", value: "Hướng dẫn chăm sóc sản phẩm" }
  ],
  dataType: AttributeDataTypeEnum["boolean"],
  isMultipleValues: true,
  inputType: AttributeInputTypeEnum["toggle"],
  default: false,
  isUnique: false,
  settings: "settings",
  template: [
    {
      ...emptyAttributeTemplate,
      name: "washing",
      description:
        "Shown as tooltip for the attribute name to provide further note or explanation.",
      labels: [
        { locale: "en-US", value: "Washing" },
        { locale: "vi-VN", value: "Giặt" }
      ],
      dataType: AttributeDataTypeEnum["boolean"],
      isMultipleValues: false,
      inputType: AttributeInputTypeEnum["toggle"],
      default: false,
      isUnique: false,
      settings: "settings",
      template: null,
      options: [
        {
          key: "washing_50_veryGentle",
          displayValues: [
            { locale: "en-US", value: "Very Gentle at 50 degree" },
            { locale: "vi-VN", value: "Giặt nhẹ nhàng ở 50 độ C" }
          ],
          files: [
            {
              locale: "en-US",
              chanel: null,
              type: "banner",
              fileId: "image0001",
              fileUrl: "optional image url"
            },
            {
              locale: "vi-VN",
              chanel: null,
              type: "banner",
              fileId: "image0002"
            }
          ]
        },
        {
          key: "washing_40_normal",
          displayValues: [
            { locale: "en-US", value: "Normal at 40 degree" },
            { locale: "vi-VN", value: "Giặt bình thường ở 40 độ" }
          ],
          files: [
            {
              locale: "en-US",
              chanel: null,
              type: "banner",
              fileId: "image0001",
              fileUrl: "optional image url"
            },
            {
              locale: "vi-VN",
              chanel: null,
              type: "banner",
              fileId: "image0002"
            }
          ]
        }
      ]
    }
  ],
  // options: null,
  options: [
    {
      key: "washing_50_veryGentle",
      displayValues: [
        { locale: "en-US", value: "Very Gentle at 50 degree" },
        { locale: "vi-VN", value: "Giặt nhẹ nhàng ở 50 độ C" }
      ],
      files: [
        {
          locale: "en-US",
          chanel: null,
          type: "banner",
          fileId: "image0001",
          fileUrl: "optional image url"
        },
        {
          locale: "vi-VN",
          chanel: null,
          type: "banner",
          fileId: "image0002"
        }
      ]
    },
    {
      key: "washing_40_normal",
      displayValues: [
        { locale: "en-US", value: "Normal at 40 degree" },
        { locale: "vi-VN", value: "Giặt bình thường ở 40 độ" }
      ],
      files: [
        {
          locale: "en-US",
          chanel: null,
          type: "banner",
          fileId: "image0001",
          fileUrl: "optional image url"
        },
        {
          locale: "vi-VN",
          chanel: null,
          type: "banner",
          fileId: "image0002"
        }
      ]
    }
  ]
};

const onDemandMock: AttributeEntity = {
  ...emptyAttributeEntity,
  id: "onDemandMock",
  name:
    "onDemandMock" +
    '301 -  [FE] File Uploader - Data saving for the setting "File type" does not work #301 ',
  dataSettings: null,
  dataType: AttributeDataTypeEnum["file"],
  default: null,
  description: "",
  inputSettings: {
    allowedFileTypes: null,
    maxSizeInBytes: null,
    minSizeInBytes: null
  },
  inputType: AttributeInputTypeEnum["uploader"],
  isMasterAttribute: false,
  isMultipleValues: false,
  isUnique: false,
  isNullable: false,
  labels: [],
  options: null,
  template: null,
  status: AttributeStatusEnum["unpublished"],
  updatedBy: {
    id: "803c1063-4f65-41e2-8881-62fcabd27753",
    name: "Hieu Duc Nguyen"
  },
  createdAt: "2023-12-18T10:24:01.9907689Z",
  updatedAt: "2023-12-18T10:24:01.9907688Z"
};

export function generateMockAttribute({
  inputType,
  dataType
}: {
  inputType: AttributeInputTypeEnum;
  dataType: AttributeDataTypeEnum;
}): AttributeTemplate {
  const result = {
    ...emptyAttributeTemplate,
    dataType,
    inputType
  } as AttributeTemplate;
  const name = `${inputType}+${dataType}`;
  result.name = name;
  const key = (seed: number) => "Key:" + generateId(seed) + ":" + name;

  const options: AttributeObjectOption[] = [];
  switch (dataType) {
    case AttributeDataTypeEnum.text: {
      const option = (seed: number): AttributeObjectOption => ({
        key: key(seed),
        displayValues: [
          { locale: "en-US", value: "Very Gentle at 50 degree" },
          { locale: "vi-VN", value: "Giặt nhẹ nhàng ở 50 độ C" }
        ] as Labels[]
      });
      options.push(
        option(Math.random()),
        option(Math.random()),
        option(Math.random())
      );
      break;
    }
    case AttributeDataTypeEnum.number:
    case AttributeDataTypeEnum.date:
    case AttributeDataTypeEnum.time: {
      const option = (seed: number): AttributeObjectOption => ({
        key: key(seed),
        THIS_SHOULD_BE_REMOVED_displayValues: "Value:" + generateId(seed),
        displayValues: []
      });
      options.push(
        option(Math.random()),
        option(Math.random()),
        option(Math.random())
      );

      break;
    }
    case AttributeDataTypeEnum.file: {
      const option = (seed: number): AttributeObjectOption => ({
        key: key(seed),
        displayValues: [
          { locale: "en-US", value: "Very Gentle at 50 degree" },
          { locale: "vi-VN", value: "Giặt nhẹ nhàng ở 50 độ C" }
        ],
        files: [
          {
            locale: "en-US",
            chanel: null,
            type: "banner",
            fileId: "image0001",
            fileUrl: "optional image url"
          },
          {
            locale: "vi-VN",
            chanel: null,
            type: "banner",
            fileId: "image0002"
          }
        ]
      });
      options.push(
        option(Math.random()),
        option(Math.random()),
        option(Math.random())
      );

      break;
    }
    case AttributeDataTypeEnum.object: {
      const option = (seed: number): AttributeObjectOption => ({
        key: key(seed),
        displayValues: [
          { locale: "en-US", value: "Very Gentle at 50 degree" },
          { locale: "vi-VN", value: "Giặt nhẹ nhàng ở 50 độ C" }
        ],
        [generateId(Math.random())]: ["Value:" + generateId(Math.random())],
        [generateId(Math.random())]: ["Value:" + generateId(Math.random())],
        [generateId(Math.random())]: ["Value:" + generateId(Math.random())],
        [generateId(Math.random())]: ["Value:" + generateId(Math.random())],
        [generateId(Math.random())]: ["Value:" + generateId(Math.random())]
      });
      options.push(
        option(Math.random()),
        option(Math.random()),
        option(Math.random()),
        option(Math.random()),
        option(Math.random())
      );

      break;
    }
  }
  result.options = options;

  return result;
}

export function generateCombinationArray() {
  const combination: [
    keyof typeof AttributeInputTypeEnum,
    keyof typeof AttributeDataTypeEnum
  ][] = [];
  const inputTypes = Object.values(AttributeInputTypeEnum);
  inputTypes.forEach((inputType) => {
    const validDataType = dataTypesMapping[inputType];
    validDataType.forEach((dataType) => {
      combination.push([inputType, dataType]);
    });
  });
  return combination;
}

function generateEveryCombination() {
  const generated = { ...emptyAttributeEntity };
  generated.name = "Every Combination";
  generated.id = "EveryCombination";
  generated.inputType = AttributeInputTypeEnum.form;
  generated.dataType = AttributeDataTypeEnum.object;

  const combination: AttributeTemplate[] = [];
  const inputTypes = Object.values(AttributeInputTypeEnum);
  inputTypes.forEach((inputType) => {
    const validDataType = dataTypesMapping[inputType];
    validDataType.forEach((dataType) => {
      const generated = generateMockAttribute({ dataType, inputType });
      combination.push(generated);
    });
  });

  generated.template = combination;
  return generated;
}

function generateFullOptionValue() {
  const generated = { ...emptyAttributeEntity };
  generated.name = "Full Option Value";
  generated.id = "FullOptionValue";
  generated.inputType = AttributeInputTypeEnum.selection;
  generated.dataType = AttributeDataTypeEnum.object;

  const combination: AttributeTemplate[] = [];
  const inputTypes = [AttributeInputTypeEnum.selection];
  inputTypes.forEach((inputType) => {
    const validDataType = dataTypesMapping[inputType];
    validDataType.forEach((dataType) => {
      const generated = generateMockAttribute({ dataType, inputType });
      combination.push(generated);
    });
  });

  generated.template = combination;
  return generated;
}

const withEveryInputDataTypeCombination = generateEveryCombination();
const fullOptionValue = generateFullOptionValue();

export const attributeMocks = {
  get: {
    attributes: [
      onDemandMock,
      fullOptionValue,
      withEveryInputDataTypeCombination,
      attributeEntityForm_careLabel,
      attributeEntityTextarea_careLabel,
      attributeEntityToggle_careLabel
    ],
    attributeDetail: {
      inputType: {
        simple: attributeEntitySimple,
        form: {
          default: attributeEntityForm,
          oneNested: attributeEntityForm_oneTemplate
        }
      }
    }
  }
};
