import { CategoryEntity } from "@cm/domain/entities/categoryEntity";
import { CategoryAttribute } from "@cm/domain/valueObjects/categoryAttribute";
import { ProductEntity } from "@pm/models/ProductEntity";
import { ProductAttributesMap } from "@pm/types/ProductAttributesMap";
import { atom } from "jotai";
import { Labels } from "../../../common/domain/valueObjects/Label";

export interface CategoryAttributeWithOriginal extends CategoryAttribute {
  categoryId: string;
}

export interface ProductManagementEntityState {
  productId: string | null;
  isLoading: boolean;
  isVariantLoading: boolean;
  product: ProductEntity | null;
  productVariants: ProductEntity[] | null;
  categoryList: CategoryEntity[]; // this list should be ordered
  overwriteCategoryIds: string[] | null;
  isUpdated: boolean;
  attributesMap: ProductAttributesMap;
  attributeSettingMap: Record<string, CategoryAttributeWithOriginal>;
  attributeGroupMap: Record<string, Labels[]>;
  validations: Record<string, boolean>;
}

export const productStateAtom = atom<ProductManagementEntityState>({
  productId: null,
  isLoading: true,
  isVariantLoading: true,
  product: null,
  productVariants: null,
  categoryList: [],
  overwriteCategoryIds: null,
  isUpdated: false,
  attributesMap: {},
  attributeSettingMap: {},
  attributeGroupMap: {},
  validations: {}
});

export const productIdAtom = atom<string | null>(null);
