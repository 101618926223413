export const translationObject = {
  "common.logout": "Logout",
  "common.action": "Action",
  "common.add": "Add",
  "common.apply": "Apply",
  "common.areYouSureContinue": "Are you sure you want to continue?",
  "common.attribute": "Attribute",
  "common.attributeType": "Attribute type",
  "common.attributeType.system.tooltip":
    "This attribute is generated by system and unable to change.",
  "common.cancel": "Cancel",
  "common.continue": "Continue",
  "common.common": "Common",
  "common.config": "Config",
  "common.created": "Created",
  "common.delete": "Delete",
  "common.process": "Proceed",
  "common.description": "Description",
  "common.displayText": "Display Text",
  "common.displayImage": "Display Image",
  "common.decimal": "Decimal",
  "common.deleteDraft": "Delete draft",
  "common.draft": "Draft",
  "common.edit": "Edit",
  "common.editCategories": "Edit categories",
  "common.id": "ID",
  "common.input": "Input",
  "common.key": "Key",
  "common.lastUpdated": "Last updated",
  "common.loading": "Loading",
  "common.locale": "Locale",
  "common.min": "Min",
  "common.minCharacter": "Min character",
  "common.master": "Master",
  "common.max": "Max",
  "common.maxCharacter": "Max character",
  "common.multiple": "Multiple",
  "common.validFrom": "Valid from",
  "common.validTo": "Valid to",
  "common.numberType": "Number type",
  "common.minValue": "Min value",
  "common.maxValue": "Max value",
  "common.validFileType": "File type",
  "common.minSize": "Min size (MB)",
  "common.maxSize": "Max size (MB)",
  "common.na": "N/A",
  "common.name": "Name",
  "common.no": "No",
  "common.notNow": "Not now",
  "common.pim": "PIM",
  "common.publish": "Publish",
  "common.unpublish": "Unpublish",
  "common.publishAll": "Publish All",
  "common.unpublishAll": "Unpublish All",
  "common.optional": "optional",
  "common.optionsValue": "Options Value",
  "common.published": "Published",
  "common.remove": "Remove",
  "common.required": "Required",
  "common.reset": "Reset",
  "common.rounded": "Rounded",
  "common.save": "Save",
  "common.saveDraft": "Save draft",
  "common.searchable": "Searchable",
  "common.searchFilter": "Search and filter",
  "common.select": "Select",
  "common.selectPlaceholder": "Select",
  "common.settings": "Settings",
  "common.single": "Single",
  "common.status": "Status",
  "common.system": "System",
  "common.total": "Total",
  "common.typeHere": "Type here",
  "common.update": "Update",
  "common.values": "values",
  "common.unpublished": "Unpublished",
  "common.validation": "Validation",
  "common.validation.textLengthRange": "Text length range",
  "common.validation.nameAlreadyExists": "Object field has duplicated name",
  "common.view": "View",
  "common.viewInLocale": "View in locale",
  "common.editInLocale": "Edit in locale",
  "common.yes": "Yes",
  "common.yesUpdate": "Yes and update",
  "common.deleteConfirmation": "Delete confirmation",
  "common.publishConfirmation": "Publish confirmation",
  "common.on": "On",
  "common.off": "Off",
  "common.variantDependent": "Variant Dependent",
  "common.variantKey": "Variant Key",
  "common.dataType": "Data type",
  "common.dataTypes.text": "Text",
  "common.dataTypes.email": "Email",
  "common.dataTypes.phone": "Phone",
  "common.dataTypes.number": "Number",
  "common.dataTypes.date": "Date",
  "common.dataTypes.time": "Time",
  "common.dataTypes.dateTime": "Date time",
  "common.dataTypes.file": "File",
  "common.dataTypes.object": "Object",
  "common.dataTypes.boolean": "Boolean",
  "common.inputType": "Input type",
  "common.inputTypes.uploader": "File uploader",
  "common.inputTypes.form": "Form",
  "common.inputTypes.selection": "Selection",
  "common.inputTypes.simple": "Simple field",
  "common.inputTypes.textarea": "Textarea",
  "common.inputTypes.toggle": "Toggle",
  "common.confirmDelete.detailed": "This will affect",
  "common.confirmDelete.highlight": "cannot be reversed.",
  "common.confirmDelete.question": "Are you sure you want to continue?",
  "common.language.german": "German",
  "common.language.english": "English",
  "common.language.vietnamese": "Vietnamese",
  "common.dev.todo": "TODO",
  "common.createNew": "Create new",
  "common.attributeName": "Attribute name",
  "common.categories": "Categories",
  "common.information": "Information",
  "common.variants": "Variants",
  "common.publications": "Publications",
  "common.requireInputMessage": "Please input value!",
  "common.patternInputMessage": "The value isn't valid!",
  "common.roundedNumberMessage": "The value must be a rounded number!",
  "common.addValue": "Add value",
  "common.uploadDraggerText": "Click or drag file(s) to this area to upload",
  "common.upload": "Upload",
  "common.apiErrorTitle": "Could not perform the action",
  "common.internalError": "Internal error, please reload the page!",
  "common.preview": "Preview",
  "common.productId": "Product ID",
  "common.close": "Close",
  "common.annotating": "Annotating",
  "common.group": "Group",
  "common.errorDuplicated": "Found duplicated key, changes reverted",
  "common.saleChannel": "Sale channel",
  "common.saleAndLocale": "Sales channel & Locale",
  "common.allChannels": "All channels",
  "common.allLocales": "All locales",

  "attribute.id": "Attribute ID",
  "attributeDetails.labelIn": "Label in",
  "attributeDetails.newProductTemplateName": "New product template name",
  "attributeDetails.newAttribute": "New attribute",
  "attributeDetails.inputType": "Input Type",
  "attributeDetails.inputTypes.simpleField": "Simple field",
  "attributeDetails.attributeName": "Attribute name",
  "attributeDetails.objectFieldLabel": "Object field",
  "attributeDetails.addNewInput.modelTitle": "Add new input field",
  "attributeDetails.inputTypes.simpleField.isUnique": "Unique value",
  "attributeDetails.inputTypes.simpleField.isMultipleValues": "Multiple value",
  "attributeDetails.inputTypes.selection.selectionType": "Selection type",
  "attributeDetails.inputTypes.selection.selectionLook": "Selection look",
  "attributeDetails.inputTypes.selection.fileType": "File type",
  "attributeDetails.inputTypes.file.selectionLook": "Selection look",
  "attributeDetails.inputTypes.form.childInputLayout": "Child input layout",
  "attributeDetails.inputSettings.layoutOrientation.vertical": "Vertical",
  "attributeDetails.inputSettings.layoutOrientation.horizontal": "Horizontal",
  "attributeDetails.inputSettings.fileTypes.placeholder":
    "Separate different file format names with commas. For example: PNG,JPG,GIF",
  "attributeDetails.modal.disableMasterAttributeModal.title":
    "Changing attribute characteristic",
  "attributeDetails.modal.disableMasterAttributeModal.body":
    "You are about to change this attribute to normal attribute [or vice versa] <br><br> This will affect all of the created products & product templates and CANNOT BE REVERSED.",
  "attributeDetails.modal.enableMasterAttributeModal.title":
    "Making master attribute",
  "attributeDetails.modal.enableMasterAttributeModal.body":
    "Master attribute will affect all of the created products & product templates",
  "attributeDetails.optionsValue.addValues": "Add values",
  "attributeDetails.optionsValue.importJson": "Import JSON",
  "attributeDetails.optionsValue.valueTable": "Value table",
  "attributeDetails.settings.masterAttribute": "Master attribute",
  "attributeDetails.settings.id.autoGenerated":
    "auto generated after publishment",
  "attributeDetails.settings.valuesChangedByLocales":
    "Values changed by locales",
  "attributeDetails.settings.valuesChangedBySalesChannels":
    "Values changed by sales channels",
  "attributeDetails.settings.appearanceLabelByLocale":
    "Appearance label by locale",
  "attributeDetails.settings.editConfig": "Edit config",
  "attributeDetails.settings.editConfig.textRegex": "Text regex",
  "attributeDetails.settings.editConfig.defaultValue": "Default value",
  "attributeDetails.draftEditText": "You are editing draft version of the attribute",
  "attributeDetails.draftViewText": "You are viewing draft version of the attribute",
  "attributeList.pageName": "Attribute Management",
  "attributeList.searchFilter": "Search and Filter",
  "attributeList.searchPlaceholder": "Search by name",
  "attributeList.filterAllType": "All type",
  "attributeList.resetButton": "Reset",
  "attributeList.applyButton": "Apply",
  "attributeList.confirmDelete.text":
    "You are about to delete the attribute name '{0}'",
  "attributeList.confirmDelete.detailed": "the selected attribute and",
  "attributeTable.header.name": "Name",
  "attributeTable.header.masterData": "Master data",
  "attributeTable.header.inputTypes": "Input types",
  "attributeTable.header.dataTypes": "Data types",
  "attributeTable.header.lastUpdate": "Last update",
  "attributeTable.header.valueByLocales": "Value by locales",
  "attributeTable.header.valueByChannels": "Value by channels",
  "createAttribute.modelTitle": "Create new attribute",
  "createAttribute.nameLabel": "Name",
  "createAttribute.namePlaceholder": "What is the name of this attribute?",
  "createAttribute.descriptionPlaceholder": "Put some note for this attribute",
  "createAttribute.inputType": "Input type",
  "createAttribute.nameRequireMessage": "Please input attribute name!",
  "createAttribute.inputTypeRequireMessage": "Please select an input type!",
  "createAttribute.dataTypeRequireMessage": "Please select a data type!",
  "createAttribute.inputType.simpleField": "Simple field",
  "createAttribute.inputType.toggle": "Toggle",
  "createAttribute.inputType.selection": "Selection",
  "createAttribute.inputType.fileUploader": "File uploader",
  "createAttribute.inputType.form": "Form",
  "createAttribute.masterAttributeMessage":
    "Master attribute will show in all categories and products",

  "category.header.title": "Categories management",
  "category.tree.baseCategory": "Base category",
  "category.tree.baseTooltip":
    " All attributes in this category will be applied to all other categories and products.",
  "category.filter.searchFilter": "Search and filter",
  "category.filter.searchPlaceholder": "Search by name",
  "category.filter.reset": "Reset",
  "category.filter.apply": "Apply",
  "category.detail.breadcrumb": "Category",
  "category.header.update": "Update",
  "category.info.created": "Created",
  "category.info.categoryId": "Category ID",
  "category.info.lastUpdated": "Last updated",
  "category.info.parent": "Parent",
  "category.createNew.title": "Create new Category",
  "category.createNew.name": "Category name",
  "category.createNew.nameRequireMessage": "Please input attribute name!",
  "category.createNew.namePlaceholder": "New Category name",
  "category.createNew.description": "Description",
  "category.createNew.descriptionPlaceholder":
    "Say some words about this Category.",
  "category.createNew.parentCategory": "Parent Category",
  "category.createNew.parentCategoryPlaceholder": "Select",
  "category.common.attributes": "Attributes",
  "category.common.settings": "Settings",
  "category.common.save": "Save",
  "category.common.action": "Action",
  "category.common.requiredMessage": "{0} is required!",
  "category.common.na": "N/A",
  "category.details.name": "Name",
  "category.details.description": "Description",
  "category.details.importAttributes": "Import attributes",
  "category.details.countAttributes": "attributes",
  "category.details.countGroups": "groups",
  "category.details.addGroup": "Add group",
  "category.details.importSelectedAttribute": "Import selected attribute",
  "category.details.viewAttribute": "View",
  "category.details.attributesFound": "attributes found",
  "category.details.searchAttributePlaceholder":
    "Type name or ID of attribute to find",
  "category.settings.inputType": "Input type",
  "category.settings.dataType": "Data type",
  "category.settings.defaultValue": "Default value",
  "category.settings.selectionType": "Selection type",
  "category.settings.settingName": "Setting name",
  "category.settings.addSetting": "Add setting",
  "category.settings.optionsValue": "Options value",
  "category.settings.key": "Key",
  "category.settings.value": "Value",
  "category.settings.remove": "Remove",
  "category.settings.values": "values",
  "category.settings.on": "On",
  "category.settings.off": "Off",
  "category.settings.yes": "Yes",
  "category.settings.no": "No",
  "category.settings.nameRequireMessage": "Please input setting name!",
  "category.settings.inputTypeRequireMessage": "Please select input type!",
  "category.settings.errorAddDuplicate":
    "The setting name is already existing!",
  "category.addGroup.name": "Add new group",
  "category.addGroup.nameRequireMessage": "Please input group name!",
  "category.addGroup.labelRequireMessage": "Please input group label in",

  "product.tree.allProducts": "All products",
  "product.tree.products": "products",
  "product.tree.searchPlaceholder": "Search by name",
  "product.createNew.product": "New Product",
  "product.createNew.variant": "New Variant",
  "product.createNew.title": "Create new Product",
  "product.createNewVariant.title": "Create new Variant",
  "product.createNew.name": "Product name",
  "product.createNew.nameRequireMessage": "Please input product name!",
  "product.createNew.namePlaceholder": "New Product name",
  "product.createNew.description": "Note for Product",
  "product.createNew.descriptionPlaceholder":
    "Say some words about this Product.",
  "product.createNew.categoriesPlaceholder": "Select",
  "product.createNew.categoriesRequireMessage": "Please select a categories!",
  "product.createNew.mainProduct": "Main product",
  "product.createNew.mainProductPlaceholder": "Search for product",
  "product.createNew.mainProductRequireMessage": "Please select a product!",
  "product.details.productName": "Product name",
  "product.details.thumbnailImage": "Thumbnail image",
  "product.details.contentFilled": "Content filled",
  "product.delete": "Delete product",
  "product.variantsTable.variantProduct": "Variant product",
  "product.variantsTable.addVariant": "Add variant",
  "product.edit.categories": "Edit categories",
  "product.edit.categoriesNotice":
    "You can select multiple categories. Contact your admin for further instruction.",
  "product.edit.categorySettingOverwrite": "Overwrite settings",
  "product.edit.categoryRemoveConfirm":
    "Are you sure you want to continue removing categories?",
  "product.edit.categoryRemoveAttributeNotice":
    "The following attributes will be removed from main product (and its variants):",
  "product.details.draftText": "You are editing draft version of the product",
  "product.edit.externalAnnotationButton":
    "The process will take a while and block this product page in the mean time.",
  "product.edit.externalAnnotationButtonDisabled":
    "You need at least one thumbnail product image to use this feature. The more images added, the more precise annotation",
  "product.edit.externalAnnotationInProgress": "AI annotation in progress",
  "product.edit.cannotPublish": "Cannot publish product",
  "product.edit.dataInvalidContent": "You data contains error, please check!",
  "product.edit.cannotPublishContent":
    "The current locale(s) are not fully filled. The content-filled percentage of each locale is not 100% yet. Please enter all the required attributes for every locale first.",
  "product.edit.cannotPublishUnsaved":
    "You have unsaved data, please save them first before trying this function.",
  "product.edit.cannotUnpublish": "Cannot unpublish product",
  "product.edit.cannotUnpublishContent":
    "Cannot unpublish product, please check again.",
  "product.edit.variantIncomplete": "Variant Incomplete",
  "product.edit.variantIncompleteContent": "Missing required data in variants:",
  "product.edit.noChannel": "Product doesn't have chanel",
  "product.edit.noChannelContent":
    "To publish a product, that product needs to have at least 1 channel",
  "product.detail.externalAnnotationConfirmTitle":
    "Product is about to be annotated",
  "product.detail.externalAnnotationConfirmDescription":
    "Please note that during the annotation process, this product, as well as its related attributes & categories will be blocked from editing & update, until the annotation is complete.",
  "product.detail.statusRefresh": "Refresh to check progress",
  "product.detail.confirmDelete.text": "You are about to deleting product.",
  "product.detail.confirmDelete.detailed1":
    "All product data, including its variants will be deleted &",
  "product.detail.confirmDelete.detailed2":
    "Product will also be deleted on the connected sales channel automatically."
};
