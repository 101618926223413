import useProductEntity from "@pm/hooks/useProductEntity";
import { useUIState } from "@pm/hooks/useUIState";
import { ProductAttributesMap } from "@pm/types/ProductAttributesMap";
import { ContentFilledDropdown } from "@pm/ui/molecules/ContentFilledDropdown";
import ProductNameEdit from "@pm/ui/molecules/ProductNameEdit";
import { LanguageSelectorHeader } from "@pm/ui/organisms/languageRadioGroup/LanguageRadioGroup";
import AttributesEditor, { FormValue } from "@shared/ui/attributes-editor";
import { Spin } from "antd";
import { useCallback, useContext, useMemo } from "react";
import { styled } from "styled-components";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";
import {
  borders,
  themeColors
} from "../../../../../../common/ui/styles/themeColors";
import ProductDetailContext from '../context';

const Container = styled.div`
  .product-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid ${borders.colorBorderSecondary};
    flex-wrap: wrap;
    .locale-selector {
      display: flex;
      align-items: center;
    }
  }
  .content-filled-section {
    .content-filled-label {
      color: ${themeColors.colorPrimaryActive};
    }
    .content-filled-label-icon {
      margin-left: 8px;
      color: ${themeColors.colorPrimaryActive};
    }
  }
  .product-detail-section {
    padding: 24px 16px 0 16px;
  }
`;

interface Props {
  variantId?: string;
}

export const ProductDetailForm: React.FC<Props> = ({
  variantId,
}): JSX.Element => {
  const {
    uiState: { isEditMode, isPublishRequested },
  } = useUIState();
  const {
    productState: { product, productVariants },
    setProductName,
    setProductAttributes,
    setVariantName,
    setVariantAttributes,
    setValidationState,
  } = useProductEntity();
  const {
    productState: {
      attributesMap,
      isLoading: isAttributesLoading,
      attributeSettingMap,
      attributeGroupMap,
    }
  } = useProductEntity();

  const { t, defaultLocale } = useCommonUiStore();

  const attributeMapFiltered = useMemo(() => {
    const variantOnly = !!variantId;
    const attMapFiltered: ProductAttributesMap = {};
    Object.keys(attributesMap).forEach((a) => {
      if (variantOnly && attributeSettingMap[a]?.isVariantDependent === true) {
        attMapFiltered[a] = attributesMap[a];
      } else if (
        !variantOnly &&
        attributeSettingMap[a]?.isVariantDependent === false
      ) {
        attMapFiltered[a] = attributesMap[a];
      }
    });

    return attMapFiltered;
  }, [attributesMap, variantId, attributeSettingMap]);

  const data = useMemo(() => {
    if (!variantId) return product;
    return productVariants?.find((x) => x.id === variantId);
  }, [variantId, product, productVariants]);

  const updateAttribute = useCallback(
    (v: FormValue) => {
      if (variantId) setVariantAttributes(variantId, v);
      else setProductAttributes(v);
    },
    [variantId, setVariantAttributes, setProductAttributes]
  );

  const updateValidation = useCallback(
    (isValid: boolean) => {
      if (variantId) setValidationState(variantId, isValid);
      else if (product?.id) setValidationState(product?.id, isValid);
    },
    [variantId, product, setValidationState]
  );

  const updateName = useCallback(
    (v: any) => {
      if (variantId) setVariantName(variantId, v);
      else setProductName(v);
    },
    [variantId, setVariantName, setProductName]
  );

  const productContext = useContext(ProductDetailContext);
  const isDefaultLocale = productContext.locale === defaultLocale?.code;

  if (isAttributesLoading || !data) return <Spin className="loading" />;

  return (
    <Container>
      <section className="product-detail-header">
        <div className="locale-selector">
          <LanguageSelectorHeader
            label={
              isEditMode ? t["common.editInLocale"] : t["common.viewInLocale"]
            }
            locale={productContext.locale}
            onChange={productContext.setLocale}
          />
        </div>
        { productContext.channel ?
          <div className="content-filled-section">
            <ContentFilledDropdown
              locale={productContext.locale}
              channel={productContext.channel}
              product={data}
            />
          </div>
        : null }
      </section>
      <section className="product-detail-section">           
        <AttributesEditor
          editable={isEditMode}
          locale={productContext.locale}
          useDefault={isDefaultLocale}
          channel={productContext.channel ?? null}
          onChange={updateAttribute}
          templates={attributeMapFiltered}
          settings={attributeSettingMap}
          groups={attributeGroupMap}
          value={data.attributes}
          onValidationUpdated={updateValidation}
          showError={isPublishRequested}
        >
          <ProductNameEdit value={data.name} onUpdate={updateName} />
        </AttributesEditor>
      </section>
    </Container>
  );
};
