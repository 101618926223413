export default function cloneDeep<T>(obj: T): T {
  if (!obj) return obj;

  if (window.structuredClone) {
    try {
      return window.structuredClone(obj);
    } catch {
      // continue use JSON
    }
  }

  return JSON.parse(JSON.stringify(obj));
}
