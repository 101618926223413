import { atom, useAtom, useAtomValue } from "jotai";
import {
  emptyLocaleWithNullableCode,
  GetLocaleAPIResponse,
  GetLocaleResponse,
} from "../../valueObjects/GetLocaleResponse";
import { GetChannelResponse } from "../../valueObjects/GetChannelResponse";
import { TranslationKey } from "../../../../features/i18n/translation";
import { translationObject } from "../../../../features/i18n/translations";
import { SetAtom, SetAtomGeneric } from "../../../types/jotaiHelperTypes";

const localesAtom = atom<GetLocaleResponse[]>([]);
const transformLocaleAtom = atom<GetLocaleAPIResponse>(emptyLocaleWithNullableCode);
const defaultLocaleAtom = atom((get) =>
  get(localesAtom).find((x) => x.isActive && x.isDefault)
);

const systemLocalesAtom = atom<GetLocaleResponse[]>([]);

// ATOM that handle current active UI locale, store to LocalStorage
const LANG_KEY = "pim_language";
const activeSystemLocaleAtom = atom<GetLocaleResponse | undefined>(undefined);
const activeSystemLocaleWrapper = atom<
  GetLocaleResponse | undefined,
  [string],
  void
>(
  (get) => {
    get(activeSystemLocaleAtom); // register for deps
    const systemLocales = get(systemLocalesAtom);
    const userLocale = localStorage.getItem(LANG_KEY);
    if (userLocale) {
      const target = systemLocales.find((x) => x.code === userLocale);
      if (target) return target;
    }

    return systemLocales.find(
      (x) => x.isDefault === true && x.isActive === true
    );
  },
  (get, set, update) => {
    const systemLocales = get(systemLocalesAtom);
    const target = systemLocales.find((x) => x.code === update);
    if (!target) return; // saving something we don't support
    if (target.isDefault) {
      localStorage.removeItem(LANG_KEY);
    } else {
      localStorage.setItem(LANG_KEY, target.code);
    }
    set(activeSystemLocaleAtom, target);
  }
);

// Current UI Language Mapping
const uiLanguageMapAtom =
  atom<Record<TranslationKey, string>>(translationObject);
const uiLanguageMapSetter = atom<null, [Record<string, string>], void>(
  null,
  (_get, set, value) => {
    if (typeof value !== "object" || !value) return;
    Object.keys(value).forEach((k) => {
      if (typeof value[k] !== "string") delete value[k];
    });
    // filter all non confirming values
    const updatedValueMap = Object.assign({}, translationObject, value);
    set(uiLanguageMapAtom, updatedValueMap);
  }
);

const channelsAtom = atom<GetChannelResponse[]>([]);

type UiStoreReturn = {
  locales: GetLocaleResponse[];
  setLocales: SetAtom<GetLocaleResponse[]>;
  defaultTransFormLocale: GetLocaleAPIResponse;
  setDefaultTransFormLocale: SetAtom<GetLocaleAPIResponse>;
  defaultLocale?: GetLocaleResponse;
  systemLocales: GetLocaleResponse[];
  setSystemLocales: SetAtom<GetLocaleResponse[]>;
  activeSystemLocale?: GetLocaleResponse;
  setActiveSystemLocale: SetAtomGeneric<[string], void>;
  channels: GetChannelResponse[];
  setChannels: SetAtom<GetChannelResponse[]>;
  t: Record<TranslationKey, string>;
  setLanguageResource: SetAtomGeneric<[Record<string, string>], void>;
};

export const useCommonUiStore = (): UiStoreReturn => {
  const [locales, setLocales] = useAtom(localesAtom);
  const [defaultTransFormLocale, setDefaultTransFormLocale] =
    useAtom(transformLocaleAtom);
  const defaultLocale = useAtomValue(defaultLocaleAtom);
  const [systemLocales, setSystemLocales] = useAtom(systemLocalesAtom);
  const [activeSystemLocale, setActiveSystemLocale] = useAtom(
    activeSystemLocaleWrapper
  );
  const [channels, setChannels] = useAtom(channelsAtom);

  const t = useAtomValue(uiLanguageMapAtom);
  const [, setLanguageResource] = useAtom(uiLanguageMapSetter);

  const result: UiStoreReturn = {
    locales,
    setLocales,
    defaultTransFormLocale,
    setDefaultTransFormLocale,
    defaultLocale,
    systemLocales,
    setSystemLocales,
    activeSystemLocale,
    setActiveSystemLocale,
    channels,
    setChannels,
    t,
    setLanguageResource,
  };
  return result;
};
