import RichTextEditor from "@pm/ui/molecules/RichTextEditor";
import { BaseInputProps } from "../../types";
import Label from "../Label";
import { DEFAULT_TEXT } from "../../utils";
import { useContext, useMemo } from "react";
import FormSettingContext from "../../context/FormSettingContext";
import { ItemContainer } from "../ItemContainer";
import useValidation from "../../hooks/useValidation";
import { ValidationFunction, validationIsRequired } from "../rules";
import ErrorText from "../ErrorText";
import { styled } from "styled-components";
import { colorSeedTokens } from "../../../../../../common/ui/styles/themeColors";
import useFormWideValidator from "../../hooks/useFormWideValidator";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

const TextAreaCover = styled.div<{ $borderColor?: string }>`
  border: 1px solid ${(p) => p.$borderColor || '#ddd'};
  border-radius: 6px;
`

export default function InputTextArea(props: BaseInputProps) {
  const { value, onUpdate, isRequired, isArrayItem } = props;
  const formSetting = useContext(FormSettingContext);
  const { editMode } = formSetting;

  const { t } = useCommonUiStore();

  const validationRules = useMemo(() => {
    if (!editMode) return [];
    const rules: ValidationFunction[] = [];
    if (isRequired) rules.push(validationIsRequired(t["common.requireInputMessage"]))
    return rules;
  }, [isRequired, editMode, t]);

  const validationResult = useValidation(value, validationRules, formSetting.showValidationError);
  useFormWideValidator(editMode, validationResult.isValid, validationResult.errorMessages);

  if (editMode) {
    return (
      <ItemContainer>
        { isArrayItem ? null : <Label template={props.template} isRequired={isRequired} /> }
        <TextAreaCover $borderColor={validationResult.isValid ? undefined : colorSeedTokens.colorError}>
          <RichTextEditor value={value} onChange={onUpdate} />
        </TextAreaCover>
        <ErrorText messages={validationResult.errorMessages} status={validationResult.status}/>
      </ItemContainer>
    );
  } else {
    return (
      <ItemContainer>
        { isArrayItem ? null : <Label template={props.template} isRequired={isRequired} /> }
        <div>
          {value ? <RichTextEditor readOnly value={value} /> : DEFAULT_TEXT}
        </div>
      </ItemContainer>
    );
  }
}
