import { useParams } from "react-router-dom";
import "./categoryDetails.scss";
import { useCallback, useEffect, useState } from "react";
import { categoryApiService } from "../../../common/services/categoryApiService";
import DetailsHeader from "./detailsHeader/DetailsHeader";
import GeneralInformation from "./generalInformation/GeneralInformation";
import DetailsContent from "./detailsContent/DetailsContent";
import { Spin } from "antd";
import { useCategoryPageState } from "../../../domain/hooks/useCategoryPageState";
import { useDispatchCategoryUpsert } from "@cm/domain/hooks/useCategoryUpsertListener";

interface Props {}

export const CategoryDetails: React.FC<Props> = (): JSX.Element => {
  const { id: categoryId } = useParams();
  const [state, setState] = useCategoryPageState();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { dispatchCategoryUpsert } = useDispatchCategoryUpsert();

  const callApi = useCallback(async () => {
    if (!categoryId) {
      return;
    }

    setState({ currentCategory: null });
    const category = await categoryApiService.getCategory(categoryId);

    setState({ currentCategory: category, updatedCategory: category });
  }, [categoryId]);

  useEffect(() => {
    callApi();
  }, [categoryId]);

  const handleUpdateClicked = async () => {
    setIsUpdating(true);

    const data = state.updatedCategory!;
    const category = await categoryApiService.updateCategory(data.id, data);

    if (category.id) {
      setState({ currentCategory: category, updatedCategory: category });
      dispatchCategoryUpsert(undefined);
    }

    setIsUpdating(false);
  };

  if (state.currentCategory === undefined) {
    return <></>;
  }

  if (state.currentCategory === null || !state.allCategories) {
    return <Spin className="loading-category" />;
  }

  return (
    <div className="category-details">
      <DetailsHeader
        onUpdateClicked={handleUpdateClicked}
        isUpdating={isUpdating}
      />
      <GeneralInformation />
      <DetailsContent />
    </div>
  );
};

export default CategoryDetails;
