import type React from "react";
import { Select } from "antd";
import { yesNoOptions } from "./SelectOptions";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { useCommonUiStore } from "../../../domain/state/stores/CommonUiStore";
import { useMemo } from "react";

export const BooleanSelect: React.FC<{ booleanValue: React.ReactNode }> = ({
  booleanValue,
  /**
   * If the element is used as part of a Form.Item, then have to pass in props from parent
   * https://stackoverflow.com/questions/64559702/ant-design-does-not-detect-custom-input-component-in-form-item-react
   */
  ...rest
}): JSX.Element => {
  const { t } = useCommonUiStore();
  const yesNoOpts = useMemo(() => yesNoOptions(t), [t]);
  const defaultValue =
    yesNoOpts.find((option) => option.label === booleanValue) ??
    yesNoOpts[1];
  return (
    <Select
      data-test={dMap["select"]}
      defaultValue={defaultValue}
      options={yesNoOpts}
      {...rest}
    />
  );
};
