import { Select } from "antd";
import { numberTypeUiMapping } from "../../../../../common/helpers/attributeUiMappings";
import { FormColumn } from "../formColumnType";
import { TranslationMap } from "../../../../../../../features/i18n/translation";

export const numberTypeSelectRender = (t: TranslationMap): Pick<FormColumn, "render"> => ({
  render: (value) => {
    const finalOptions = Object.entries(numberTypeUiMapping(t)).map(
      ([attributeType, data]) => ({
        value: attributeType,
        label: data?.label
      })
    );

    const defaultValue = finalOptions.find((option) => option.label === value);
    return <Select defaultValue={defaultValue} options={finalOptions} />;
  }
});
