import { Flex, Popover } from "antd";
import { useCommonUiStore } from "../../../../common/domain/state/stores/CommonUiStore";
import { useMemo } from "react";
import { FlagChip } from "@pm/ui/molecules/FlagChip";
import { FlagKey } from "@pm/mock/flags";
import { styled } from "styled-components";

const ActiveLangContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
  padding: 10px;
  color: rgba(255,255,255,0.65);
  cursor: default;
`

const LangMenu = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  algin-items: center;
  padding: 8px 10px;
  cursor: pointer;
  transition: background 0.3s ease;
  border-radius: 4px;
  &:hover {
    background-color: #eee;
  }
`

export default function LanguageSelector() {
  const { 
    systemLocales,
    activeSystemLocale,
    setActiveSystemLocale, 
  } = useCommonUiStore();

  const content = useMemo(() => {
    const items = systemLocales.map(l => (
      <LangMenu key={l.id} onClick={() => setActiveSystemLocale(l.code)}>
        <FlagChip key={l.code} flag={(l.icon || l.code) as FlagKey} marginTop={0}/> {l.name}
      </LangMenu>
    ));

    return <div>{items}</div>;
  }, [systemLocales, setActiveSystemLocale]);

  const flag = (activeSystemLocale?.icon || activeSystemLocale?.code) as FlagKey;

  return (
    <Popover content={content} placement="right" arrow>
      <ActiveLangContainer>
        <FlagChip flag={flag} width={36}/>
        { activeSystemLocale?.name }
      </ActiveLangContainer>
    </Popover>
  )
}