import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import { useCommonUiStore } from "../../../domain/state/stores/CommonUiStore";

const Container = styled.div`
  margin: 16px 0;
`;

interface Props {
  onDeleteClicked: () => void;
  children: React.ReactNode;
}

const ConfirmDeleteModal = forwardRef(
  ({ children, onDeleteClicked }: Props, ref) => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const { t } = useCommonUiStore();

    useImperativeHandle(ref, () => ({
      setIsOpen: setIsOpenModal
    }));

    return (
      <Modal
        destroyOnClose
        forceRender={false}
        maskClosable={false}
        title={t["common.deleteConfirmation"]}
        okText={t["common.delete"]}
        cancelText={t["common.notNow"]}
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        onOk={onDeleteClicked}
        okButtonProps={{ danger: true }}
        width={360}
      >
        <Container>
          {children}
          <br />
          <div>{t["common.confirmDelete.question"]}</div>
        </Container>
      </Modal>
    );
  }
);

export default ConfirmDeleteModal;
