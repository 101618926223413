import { productsApiService } from "@pm/api/ProductsApiService";
import { AssetMinimumInfo, DependentValue } from "@pm/models/ProductEntity";
import {
  productIdAtom, ProductManagementEntityState, productStateAtom
} from "@pm/state/productManagementEntityState";
import { publicationStateAtom } from "@pm/state/productPublicationState";
import { FormValue } from "@shared/ui/attributes-editor";
import { Modal } from "antd";
import { useAtom } from "jotai";
import { useCallback } from "react";
import { useCommonUiStore } from "../../../common/domain/state/stores/CommonUiStore";

export default function useProductEntity() {
  const { t } = useCommonUiStore();
  const [productId] = useAtom(productIdAtom);
  const [productState, setProductState] = useAtom(productStateAtom);
  const [publicationState] = useAtom(publicationStateAtom);
  const { product, productVariants } = productState;

  const setSingleState =
    (name: keyof ProductManagementEntityState) => (value: any) => {
      setProductState((prev: ProductManagementEntityState) => {
        if (prev[name] === value) return prev;
        return {
          ...prev,
          [name]: value
        };
      });
    };

  const loadProduct = async () => {
    if (!productId) return;
    setProductState((v) => ({
      ...v, 
      product: null, 
      overwriteCategoryIds: null, 
      categoryList: [], 
      isLoading: true,
      isUpdated: false,
    }));
    const productResponse = await productsApiService.getProduct(productId);
    setProductState((v) => ({
      ...v,
      product: productResponse,
      isLoading: false
    }));
  };
  
  const loadProductVariants = async (preserveContent: boolean) => {
    if (!productId) return;
    const currentVariantIds = productVariants?.map((x) => x.id) ?? [];
    setProductState((v) => ({ 
      ...v, 
      productVariants: null, 
      isVariantLoading: true 
    }));

    const response = await productsApiService.getProductVariants(productId, "");
    if (preserveContent) {
      // keep the current editing content on each refresh
      response.items.forEach((v) => {
        const existing = productVariants?.find((x) => x.id === v.id);
        if (existing) Object.assign(v, existing);
      });
    }

    setProductState((v) => ({
      ...v,
      productVariants: response.items,
      isVariantLoading: false
    }));

    const newVariantIds = response.items
      .map((x) => x.id)
      .filter((x) => !currentVariantIds.includes(x));
    return newVariantIds;
  };

  const updateProductCategory = (categoryIds: string[]) => {
    setProductState((v) => ({
      ...v,
      overwriteCategoryIds: categoryIds,
      isUpdated: true
    }));
  };

  const saveProduct = async () => {
    if (!product) return;
    const updatedProduct = {
      ...product,
      categories:
        productState.overwriteCategoryIds?.map((c) => ({
          id: c,
          name: "",
          appearanceName: []
        })) ?? product.categories,
      id: product.id
    };

    // cleanup product & variants
    Object.keys(productState.attributeSettingMap).forEach((k) => {
      if (productState.attributeSettingMap[k].isVariantDependent === true) {
        if (updatedProduct.attributes[k]) delete updatedProduct.attributes[k];
      }
    });
    (productVariants ?? []).forEach((productVariant) => {
      Object.keys(productState.attributeSettingMap).forEach((k) => {
        if (productState.attributeSettingMap[k].isVariantDependent !== true) {
          if (productVariant.attributes[k]) delete productVariant.attributes[k];
        }
      });
    });

    await productsApiService.batchUpdateProduct(
      product.id,
      updatedProduct,
      productVariants ?? []
    );

    setProductState((v) => ({
      ...v,
      isUpdated: false
    }));

    await loadProduct();
    await loadProductVariants(false);
  };

  const publishProduct = async (channel: string | null): Promise<boolean> => {
    if (!product || !productId) return false;
    if (productState.isUpdated) await saveProduct();

    async function handleProductCompleteness(): Promise<boolean> {
      if (!productId) return false;
      // fetch latest product data & test for completeness
      const productData = await productsApiService.getProduct(productId);

      // generally channel values are not filled, only got overriden, so test with channel=null here
      const notCompletedPair = productData.locales
        .filter((x) => x.completeness < 100 && x.channel === null)
        .map((x) => x.value);

      if (notCompletedPair.length > 0) {
        return new Promise((r) => {
          Modal.error({
            title: t["product.edit.cannotPublish"],
            content: t["product.edit.cannotPublishContent"],
            onOk: () => {
              r(false);
            },
            okText: t["common.close"]
          });
        });
      }
      return true;
    }

    async function handleVariantCompleteness(): Promise<boolean> {
      if (!productId) return false;
      // test each variant, one after another
      const variantData = await productsApiService.getProductVariants(
        productId,
        ""
      );
      const inCompleteVariants = variantData.items.filter((v) => {
        const inCompleteLocales = v.locales.filter((x) => x.completeness < 100);

        return inCompleteLocales.length > 0;
      });

      if (inCompleteVariants.length) {
        return new Promise((r) => {
          Modal.error({
            title: t["product.edit.variantIncomplete"],
            content: `${
              t["product.edit.variantIncompleteContent"]
            } ${inCompleteVariants.map((x) => x.id).join(", ")}`,
            onOk: () => {
              r(false);
            },
            okText: t["common.close"]
          });
        });
      }
      return true;
    }

    const publishProduct = await handleProductCompleteness();
    if (!publishProduct) return false;
    const publishVariant = await handleVariantCompleteness();
    if (!publishVariant) return false;

    // now actually save it

    try {
      const response = await productsApiService.publishProduct(
        product.id,
        publicationState.locales.map((x) => x.code),
        channel ? publicationState.channels.filter(x => x.code === channel) : publicationState.channels,
      );

      return response;
    } catch (err: any) {
      console.log(err);
      Modal.error({
        title: t["common.apiErrorTitle"],
        content: err.message
      });
    }

    return false;
  };

  const setProductName = useCallback(
    (name: DependentValue<string>[]) => {
      setProductState((v) => {
        if (!v.product) return v;
        const updatedProduct = {
          ...v.product,
          name
        };
        return {
          ...v,
          product: updatedProduct,
          isUpdated: true
        };
      });
    },
    [setProductState] // safe to use because of callback values
  );

  const setProductThumbnail = useCallback(
    (thumbnail: DependentValue<AssetMinimumInfo>[]) => {
      setProductState((v) => {
        if (!v.product) return v;
        const updatedProduct = {
          ...v.product,
          thumbnail
        };
        return {
          ...v,
          product: updatedProduct,
          isUpdated: true
        };
      });
    },
    [setProductState] // safe to use because of callback values
  );

  const setProductAttributes = useCallback(
    (attributes: FormValue) => {
      setProductState((v) => {
        if (!v.product) return v;
        const updatedProduct = {
          ...v.product,
          attributes
        };

        return {
          ...v,
          product: updatedProduct,
          isUpdated: true
        };
      });
    },
    [setProductState] // safe to use because of callback values
  );

  const setVariantName = useCallback(
    (variantId: string, name: DependentValue<string>[]) => {
      setProductState((v) => {
        const targetVariant = v.productVariants?.find(
          (x) => x.id === variantId
        );
        if (!targetVariant) return v;
        const updatedVariant = {
          ...targetVariant,
          name
        };

        return {
          ...v,
          productVariants:
            v.productVariants?.map((x) =>
              x.id === updatedVariant.id ? updatedVariant : x
            ) ?? null,
          isUpdated: true
        };
      });
    },
    [setProductState] // safe to use because of callback values
  );

  const setVariantAttributes = useCallback(
    (variantId: string, attributes: FormValue) => {
      setProductState((v) => {
        const targetVariant = v.productVariants?.find(
          (x) => x.id === variantId
        );
        if (!targetVariant) return v;

        const updatedVariant = {
          ...targetVariant,
          attributes
        };

        return {
          ...v,
          productVariants:
            v.productVariants?.map((x) =>
              x.id === updatedVariant.id ? updatedVariant : x
            ) ?? null,
          isUpdated: true
        };
      });
    },
    [setProductState] // safe to use because of callback values
  );

  const setVariantThumbnail = useCallback(
    (variantId: string, thumbnail: DependentValue<AssetMinimumInfo>[]) => {
      setProductState((v) => {
        const targetVariant = v.productVariants?.find(
          (x) => x.id === variantId
        );
        if (!targetVariant) return v;

        const updatedVariant = {
          ...targetVariant,
          thumbnail
        };

        return {
          ...v,
          productVariants:
            v.productVariants?.map((x) =>
              x.id === updatedVariant.id ? updatedVariant : x
            ) ?? null,
          isUpdated: true
        };
      });
    },
    [setProductState] // safe to use because of callback values
  );

  // note: we can use Jotai Provider to cleanup all state on unmount, but that's for refaactoring these
  const clearProductVariants = () => {
    setProductState(v => ({
      ...v,
      productVariants: null
    }));
  };
  const clearProduct = () => {
    setProductState(v => ({
      ...v,
      product: null,
    }));
  };

  const setValidationState = useCallback((id: string, valid: boolean) => {
    setProductState((v) => {
      const newValidations = {
        ...v.validations,
        [id]: valid
      }
      return {
        ...v,
        validations: newValidations
      }
    })
  }, [setProductState])

  return {
    productState,
    loadProduct,
    loadProductVariants,
    saveProduct,
    publishProduct,
    updateProductCategory,
    setProductName,
    setProductAttributes,
    setProductThumbnail,
    setVariantName,
    setVariantAttributes,
    setVariantThumbnail,
    clearProductVariants,
    clearProduct,
    // data setters
    setAttributesMap: setSingleState("attributesMap"),
    setAttributeSettingMap: setSingleState("attributeSettingMap"),
    setAttributeGroupMap: setSingleState("attributeGroupMap"),
    setCategoryList: setSingleState("categoryList"),
    setValidationState,
  };
}
