import { commonConstants } from "../../../common/constants/commonConstants";
import { ApiErrorResponse, isApiErrorResponse } from "../../../common/domain/valueObjects/ApiErrorResponse";
import { showApiErrorNotification } from "../../../common/modules/showNotifications";
import { sortObjectFields } from "../../../common/modules/sortObjectFields";
import {
  ApiListRequest,
  ApiListResponse
} from "../../../common/types/commonTypes";
import { getToken } from "../../../features/authentication/getToken";
import { AttributeEntity } from "../models/entities/attributeEntity";
import { AttributeReadMode } from "../types/attributeCommonTypes";
import { CreateAttributeRequest } from "../models/valueObjects/createAttributeRequest";
import { attributeConstants } from "../common/constants/constants";
import { addIdsToTemplates } from "../common/helpers/addIdsToTemplates";

const baseUrl = `${commonConstants.api.baseUrl}/${commonConstants.api.version}/${attributeConstants.api.base}`;

const defaultHeaders = new Headers();
defaultHeaders.append("Accept", "application/json");

const buildSearchParams = (
  request: ApiListRequest,
  customParams?: URLSearchParams
): URLSearchParams | undefined => {
  if (!request) {
    return;
  }

  let params = customParams;
  if (!customParams) {
    params = new URLSearchParams();
  }

  params!.append("pageSize", request.pageSize.toString());
  params!.append("page", (request.page - 1).toString());

  if (request.sorts && request.sorts.length) {
    const sorts: string[] = [];
    request.sorts.forEach((s) => {
      if (s.order === "ascend") {
        sorts.push(`${s.field} asc`);
      } else if (s.order === "descend") {
        sorts.push(`${s.field} desc`);
      }
    });
    params!.append("sort", JSON.stringify(sorts));
  }

  if (request.search && request.search.length) {
    const search = request.search.map((s) => [
      s.operation,
      s.field,
      s.condition
    ]);
    params!.append("search", JSON.stringify(search));
  }

  return params;
};

const fetcher = async (
  url: string,
  method: string,
  body?: any
): Promise<any> => {
  const token = getToken();

  if (!token) {
    // TODO: handle user un-authenticated
  }

  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Authorization", `Bearer ${JSON.parse(token!)}`);
  if (body) {
    headers.append("Content-Type", "application/json");
  }

  const finalUrl = `${baseUrl}/${url}`;

  return new Promise((resolve) => {
    fetch(finalUrl, { headers, method, body: JSON.stringify(body) }).then(
      async (response) => {
        let responseObject;
        try {
          responseObject = await response.json();
        } catch {
          // empty code
        }

        resolve(responseObject);
      }
    );
  });
};

const getFetcher = async (
  url: string,
  params?: URLSearchParams
): Promise<any> => {
  if (params) {
    url = url.concat(`?${params.toString()}`);
  }

  return fetcher(url, "GET");
};

const postFetcher = (url: string, body: any): Promise<any> => {
  return fetcher(url, "POST", body);
};

const putFetcher = (url: string, body: any): Promise<any> => {
  return fetcher(url, "PUT", body);
};

const deleteFetcher = (url: string): Promise<any> => {
  return fetcher(url, "DELETE");
};

// TODO: for now to avoid code conflict I'm not refactor this service to use
// the global ApiService src\common\services\ApiService.ts but it should be refactored asap
export class ApiService {
  public getAttributes(
    request: ApiListRequest
  ): Promise<ApiListResponse<AttributeEntity>> {
    return getFetcher("attributes", buildSearchParams(request));
  }

  public async getAttribute(
    attributeId: string | undefined,
    mode: AttributeReadMode = "read"
  ): Promise<AttributeEntity> {
    if (!attributeId) return Promise.reject();
    let response = await getFetcher(`attributes/${attributeId}?mode=${mode}`);

    /** likely 404 */
    if (response === undefined) {
      if (mode === "read") {
        response = await getFetcher(`attributes/${attributeId}?mode=edit`);
      }
    }

    if (isApiErrorResponse(response)) {
      showApiErrorNotification(response);
    }

    response = addIdsToTemplates(response);
    response = sortObjectFields(response);

    return response;
  }

  public async getAttributeByName(
    attributeName: string | undefined,
    mode: AttributeReadMode = "read"
  ): Promise<AttributeEntity> {
    if (!attributeName) return Promise.reject();
    let response = await getFetcher(
      `attributes/name/${attributeName}?mode=${mode}`
    );

    /** likely 404 */
    if (response === undefined) {
      if (mode === "read") {
        response = await getFetcher(
          `attributes/name/${attributeName}?mode=edit`
        );
      }
    }

    if (isApiErrorResponse(response)) {
      showApiErrorNotification(response);
    }

    response = addIdsToTemplates(response);
    response = sortObjectFields(response);

    return response;
  }

  public async createAttribute(
    data: CreateAttributeRequest
  ): Promise<AttributeEntity | ApiErrorResponse> {
    const response = await postFetcher("attributes", data);
    if (isApiErrorResponse(response)) {
      return response
    }
    const newAttribute = await this.getAttribute(response.id);
    this.putAttribute(response.id, newAttribute);
    return newAttribute;
  }

  public putAttribute(
    attributeId: string,
    data: AttributeEntity
  ): Promise<AttributeEntity> {
    return putFetcher(`attributes/${attributeId}`, data);
  }

  public deleteAttributeDraft(attributeId: string): Promise<AttributeEntity> {
    return postFetcher(`attributes/${attributeId}/discard`, {});
  }

  public deleteAttribute(attributeId: string): Promise<void> {
    return deleteFetcher(`attributes/${attributeId}`);
  }

  public publishAttribute(attributeId: string): Promise<void> {
    return postFetcher(`attributes/${attributeId}/publish`, {});
  }
}

export const apiService = new ApiService();
