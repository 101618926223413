import React, { useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import { borders } from "../../../../../common/ui/styles/themeColors";
import { ProductDetailForm } from "@pm/ui/pages/productDetailsPage/productDetailForm/ProductDetailForm";
import { useUIStateReadOnly } from "@pm/hooks/useUIState";
import { ThumbnailImage } from "../thumbnailImage/ThumbnailImage";
import { Divider } from "antd";
import ExternalAnnotation from "@pm/ui/molecules/ExternalAnnotation";
import { productsApiService } from "@pm/api/ProductsApiService";
import useProductEntity from "@pm/hooks/useProductEntity";
import { useCommonUiStore } from "../../../../../common/domain/state/stores/CommonUiStore";
import ProductDetailContext from '../../pages/productDetailsPage/context'
import { AssetMinimumInfo, DependentValue } from "@pm/models/ProductEntity";
import getLocalizedValue from "@pm/helpers/getLocalizedValue";

const thumbnailSectionWidth = "300px";

// quick fix, refactor this later.
interface DynamicValueObject {
  value: any;
  locale: string | null;
  channel: string | null;
}

const Container = styled.div`
  display: flex;
  padding: 0 16px 20px 16px;

  .thumbnail-section {
    width: ${thumbnailSectionWidth};
  }

  .main-section {
    flex: 1;
    border-left: 1px solid ${borders.colorBorderSecondary};
  }

  .divider {
    padding-right: 16px;

    .ant-divider {
      margin-top: 8px;
    }
  }
`;

export const ProductInformation: React.FC = () => {
  const { t, defaultLocale } = useCommonUiStore();
  const { isEditMode } = useUIStateReadOnly();
  const {
    productState: { product, isUpdated },
    setProductThumbnail,
    loadProduct,
    saveProduct,
  } = useProductEntity();

  const productContext = useContext(ProductDetailContext);
  const isDefaultLocale = productContext.locale === defaultLocale?.code;
  const effectiveLocale: string | null = isDefaultLocale ? null : productContext.locale;

  const handleSetAnnotate = useCallback(async () => {
    if (!product || !product.id) {
      alert(t["common.internalError"]);
      return;
    }
    // call annotation, but have to save them first
    if (isUpdated) await saveProduct();
    await productsApiService.annotateProduct(product.id);
    await loadProduct();
  }, [product, t, isUpdated, saveProduct, loadProduct]);

  const image = useMemo(() => {
    const thumbnails = product?.thumbnail ?? [];
    const target = getLocalizedValue(thumbnails, effectiveLocale, productContext.channel, null);
    return target;
  }, [effectiveLocale, productContext, product]);

  const setProductThumbnailInternal = useCallback((thumbnail: AssetMinimumInfo) => {
    const value: DependentValue<AssetMinimumInfo> = {
      locale: effectiveLocale,
      value: thumbnail,
      channel: productContext.channel ?? null,
    }

    const updatedThumbnails = (product?.thumbnail ?? []).map(x => isDynamicValueMatch(value, x) ? value : x);
    const target = updatedThumbnails.find(x => isDynamicValueMatch(x, value));
    if (!target) updatedThumbnails.push(value);
    setProductThumbnail(updatedThumbnails);
  }, [productContext, product, setProductThumbnail, effectiveLocale])

  if (!product) {
    return null;
  }

  return (
    <Container>
      <section className="thumbnail-section">
        <ThumbnailImage
          isEdit={isEditMode}
          image={image}
          onUploadFinished={setProductThumbnailInternal}
        />
        <div className="divider">
          <Divider />
        </div>
        {isEditMode ? (
          <ExternalAnnotation
            thumbnail={image}
            onAnnotate={handleSetAnnotate}
          />
        ) : null}
      </section>
      <section className="main-section">
        <ProductDetailForm />
      </section>
    </Container>
  );
};

function isDynamicValueMatch(a: DynamicValueObject, b: DynamicValueObject) {
  return (a.channel ?? null) === (b.channel ?? null) && (a.locale ?? null) === (b.locale ?? null)
}