import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import type { InputRef, TableProps } from "antd";
import { Button, Form, Input, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import { AttributeObjectOption } from "../../../apps/attributesManagement/models/valueObjects/attributeObjectOptions";
import { LocaleValues } from "../../types/commonTypes";
import { isLabelGuard } from "../../domain/valueObjects/Label";
import cloneDeep from "../../modules/cloneDeep";
import { useAttributesStore } from "../../../apps/attributesManagement/state/stores/attributesStore";
import { updateAttributeImmer } from "../../../apps/attributesManagement/state/updateAttribute";
import "./ainEditableTable.scss";
import { DefaultOptionType } from "antd/es/select";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { useCommonUiStore } from "../../domain/state/stores/CommonUiStore";
import { showNotifications } from "../../modules/showNotifications";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

type Item = AttributeObjectOption;

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false} size="small">
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  locale: LocaleValues;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = (props) => {
  const {
    title,
    editable,
    locale,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  } = props;

  /** TODO: make this more generic, right now it is really specific to the details page */
  const { isEditing: detailsIsEditing } = useAttributesStore().ui;
  const [cellIsEditing, setCellIsEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  const { t } = useCommonUiStore();

  useEffect(() => {
    if (detailsIsEditing) return;
    setCellIsEditing(false);
  }, [detailsIsEditing]);

  useEffect(() => {
    if (cellIsEditing) {
      inputRef?.current?.focus();
    }
  }, [cellIsEditing]);

  const cancelEditing = () => {
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    setCellIsEditing(false);
  };

  const toggleEdit = () => {
    const formValues = form.getFieldsValue();
    const updatedValue = form.getFieldValue(dataIndex);
    if (cellIsEditing) {
      form.setFieldsValue({ [dataIndex]: updatedValue });
    } else {
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }
    setCellIsEditing(!cellIsEditing);
  };

  const save = async () => {
    try {
      const values = (await form.validateFields()) as Pick<
        AttributeObjectOption,
        "displayValues" | "key"
      >;
      const updated = { ...record };
      if (values.displayValues) {
        const updatedDisplayValues = values.displayValues.map((value) => ({
          ...value,
          value: value.value.trim()
        }));
        updated.displayValues = updatedDisplayValues;
      } else if (values.key) {
        updated.key = values.key.trim();
      }

      toggleEdit();
      handleSave({ ...record, ...updated });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    if (cellIsEditing && detailsIsEditing) {
      // displayValues
      const isDisplayValue =
        dataIndex === "displayValues" && isLabelGuard(record.displayValues[0]);
      if (isDisplayValue) {
        const targetLocaleIndex = record.displayValues.findIndex(
          (displayValue) => displayValue.locale === locale
        );

        childNode = (
          /**
           * Use `Form.List` here instead of single `Form.Item`, because the
           * displayValues is an array.
           * When we change the locale, a "new set of locales" shoudl appear in the form.
           * Use `Form.List` to utilize antd Form capabilities to atomically update for us,
           * so we don't have to manually update
           */
          <Form.List name={dataIndex}>
            {(fields) => (
              <>
                {fields.map((field, fieldIndex) => {
                  if (fieldIndex !== targetLocaleIndex) {
                    return <span key={fieldIndex}></span>;
                  }

                  return (
                    <Form.Item
                      name={[field.name, "value"]}
                      key={fieldIndex}
                      rules={[
                        {
                          required: true,
                          message: `${title} is required.`
                        }
                      ]}
                    >
                      <Input
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        onKeyUp={(event) => {
                          const isEscape = event.key === "Escape";
                          if (!isEscape) return;
                          cancelEditing();
                        }}
                      />
                    </Form.Item>
                  );
                })}
              </>
            )}
          </Form.List>
        );
      } else {
        childNode = (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`
              }
            ]}
          >
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              onKeyUp={(event) => {
                const isEscape = event.key === "Escape";
                if (!isEscape) return;
                cancelEditing();
              }}
            />
          </Form.Item>
        );
      }
    } else {
      /** Workaround to get the cellValue */
      const [, child] = children as any[];
      const cellValue = child?.props?.children;
      const isEmptyCell = cellValue === "";
      childNode = (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {isEmptyCell ? t["common.na"] : children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export const AinEditTable: React.FC<
  TableProps<AttributeObjectOption> & {
    selectedLocale: DefaultOptionType;
    "data-test"?: string;
  }
> = ({ dataSource: passedInDataSource, selectedLocale, ...rest }) => {
  const { draftAttribute, setDraftAttribute } = useAttributesStore().entity;
  const { selectedKeyPath, isEditing } = useAttributesStore().ui;
  const { t } = useCommonUiStore();

  const [raw_dataSource, setDataSource] = useState<
    readonly AttributeObjectOption[] | undefined
  >(passedInDataSource);
  const dataSource = useMemo(() => {
    return (
      raw_dataSource?.map((data, index) => ({
        ...data,
        id: index
      })) ?? []
    );
  }, [raw_dataSource]);
  useEffect(() => {
    setDataSource(passedInDataSource as any);
  }, [passedInDataSource]);

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    const updatedAttribute = updateAttributeImmer(draftAttribute, null, {
      keyPath: selectedKeyPath,
      visistor: (draft) => {
        draft.options = newData;
        return draft;
      }
    });
    setDraftAttribute(updatedAttribute);
    setDataSource(newData);
  };

  const keyColumn = useMemo(() => ({
    title: t["common.key"],
    dataIndex: "key",
    key: "key",
    width: 250,
    editable: true,
    sorter: {
      multiple: 1
    },
    render: (value: any) => <>{value}</>
  }), [t]);

  const languageRender = (
    displayValues: AttributeObjectOption["displayValues"]
  ) => (
    <>
      {
        displayValues.find(
          (displayValue) => displayValue.locale === selectedLocale.value
        )?.value
      }
    </>
  );
  const languageColumn = {
    title: selectedLocale.label,
    dataIndex: "displayValues",
    key: "displayValues",
    editable: true,
    sorter: {
      multiple: 1
    },
    render: languageRender
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    locale?: LocaleValues;
    dataIndex: string;
  })[] = [keyColumn, languageColumn];
  if (isEditing) {
    defaultColumns.push({
      title: t["common.action"],
      dataIndex: "action",
      width: 100,
      // @ts-expect-error TODO: method from antd copy paste, fix type error
      render: (_: any, record: { key: React.Key }) =>
        dataSource.length >= 1 ? (
          <Button
            data-test={dMap["button-remove"]}
            danger
            type="text"
            onClick={() => handleDelete(record.key)}
          >
            {t["common.remove"]}
          </Button>
        ) : null
    });
  }

  const handleSave = (row: Item & { id: number }) => {
    const newData = cloneDeep(dataSource);
    const index = newData.findIndex((_, index) => row.id === index);
    const currentRow = newData[index];
    const updatedRow = {
      ...currentRow,
      ...row
    };

    // make sure key is not duplicated
    if (newData.some((x, i) => updatedRow.key === x.key && i !== index)) {
      showNotifications(t["common.errorDuplicated"], 'error');
      return;
    }
    newData.splice(index, 1, updatedRow);
    setDataSource(newData);
    const updatedAttribute = updateAttributeImmer(draftAttribute, null, {
      keyPath: selectedKeyPath,
      visistor: (draft) => {
        draft.options = newData;
        return draft;
      }
    });
    setDraftAttribute(updatedAttribute);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) =>
        ({
          record,
          locale: selectedLocale.value,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave
        } as Omit<EditableCellProps, "children">)
    };
  });

  return (
    <div data-test={rest["data-test"]} className="ain-editable-table">
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        showSorterTooltip={false}
      />
    </div>
  );
};
