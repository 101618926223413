import { InputNumber as AntNumber } from "antd";
import { useContext, useMemo } from "react";
import { BaseInputProps } from "../../types";
import FormSettingContext from "../../context/FormSettingContext";
import Label from "../Label";
import { DEFAULT_TEXT } from "../../utils";
import { NumberType } from "@pm/models/NumberType";
import { ItemContainer } from "../ItemContainer";
import { ValidationFunction, validationIsRequired, validationRoundedValue } from "../rules";
import useValidation from "../../hooks/useValidation";
import ErrorText from "../ErrorText";
import useFormWideValidator from "../../hooks/useFormWideValidator";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

export default function InputNumber(props: BaseInputProps) {
  const { value, onUpdate, template, isRequired, isArrayItem } = props;
  const formSetting = useContext(FormSettingContext);
  const { editMode } = formSetting;

  const dataSettings = template?.dataSettings;
  const numberType = dataSettings?.numberType;

  const { t } = useCommonUiStore();
  
  const validationRules = useMemo(() => {
    if (!editMode) return [];
    const rules: ValidationFunction[] = [];
    if (isRequired) {
      rules.push(validationIsRequired(t["common.requireInputMessage"]))
    }
    if (numberType == NumberType.Rounded) {
      rules.push(validationRoundedValue(t["common.roundedNumberMessage"]))
    }
    
    return rules;
  }, [isRequired, editMode, numberType, t]);

  const validationResult = useValidation(value, validationRules, formSetting.showValidationError);

  // form-wide validation
  useFormWideValidator(editMode, validationResult.isValid, validationResult.errorMessages);

  if (editMode) {
    return (
      <ItemContainer>
        { isArrayItem ? null : <Label template={props.template} isRequired={isRequired} /> }
        <AntNumber
          placeholder={t["common.typeHere"]}
          value={value}
          onChange={onUpdate}
          step={numberType === NumberType.Decimal ? 0.01 : undefined}
          style={{ width: "100%" }}
          status={validationResult.status}
        />
        <ErrorText messages={validationResult.errorMessages} status={validationResult.status}/>
      </ItemContainer>
    );
  } else {
    return (
      <ItemContainer>
        { isArrayItem ? null : <Label template={props.template} isRequired={isRequired} /> }
        <div>{value || DEFAULT_TEXT}</div>
      </ItemContainer>
    );
  }
}
