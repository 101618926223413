import React from "react";
import styled from "styled-components";
import { ProductPublicationLocale } from "./ProductPublicationLocale";
import { Flex } from "antd";
import { useCommonStore } from "../../../../../common/domain/state/stores/useCommonStore";

const Container = styled(Flex)`
  margin: 25px;
`;

export const ProductPublications: React.FC = () => {
  const { ui } = useCommonStore();
  const { channels } = ui;
  
  return (
    <Container vertical={true} gap={20}>
      { channels.map(c => <ProductPublicationLocale key={c.code} channel={c.code}/> )}
    </Container>
  );
};
