import { useLoadAttributes } from "@pm/hooks/useLoadAttributes";
import useProductEntity from "@pm/hooks/useProductEntity";
import { productIdAtom } from "@pm/state/productManagementEntityState";
import { GeneralInformation } from "@pm/ui/organisms/GeneralInformation";
import { ProductPublications } from "@pm/ui/organisms/productPublications/ProductPublications";
import { ProductVariants } from "@pm/ui/organisms/productVariants/ProductVariants";
import { Spin, Tabs, TabsProps } from "antd";
import { useAtom } from "jotai";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { styled } from "styled-components";
import { useCommonUiStore } from "../../../../../common/domain/state/stores/CommonUiStore";
import { useLocaleOptions } from "../../../../../common/hooks/useLocaleOptions";
import { ProductInformation } from "../../organisms/productInformation/ProductInformation";
import ProductDetailContext, { ProductDetailContextProps } from './context';

const Container = styled.section`
  .ant-tabs {
    margin: 0;

    .ant-tabs-nav {
      margin: unset;
      padding: 0 24px;
    }
  }
`;

const LoadingSpin = styled(Spin)`
  display: block;
  text-align: center;
  padding-top: 16px;
`;

type TabKeys = "information" | "variants" | "publications";

export const ProductDetailPage: React.FC = () => {
  const { id } = useParams();
  const [productId, setProductId] = useAtom(productIdAtom);
  const {
    productState: { isLoading },
    loadProduct,
    clearProductVariants,
    clearProduct,
  } = useProductEntity();

  // hook for registering product loading side affect
  // TODO: refactor project structure
  useLoadAttributes();

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useCommonUiStore();

  const viewParam = searchParams.get("view");

  const changeDetailsView = useCallback(
    (tabKey: string) => {
      setSearchParams({ view: tabKey });
    },
    [setSearchParams]
  );

  useEffect(
    () => () => {
      // clean up data in state for new
      clearProduct();
      clearProductVariants();
    },
    []
  );

  useEffect(() => {
    if (!id) return;
    setProductId(id);
  }, [id]);

  useEffect(() => {
    if (!productId) return;
    loadProduct();
  }, [productId]);

  // page-scoped locale display
  const { selectedLocale, localeOptions } = useLocaleOptions();
  const [query] = useSearchParams();
  const initialLocale = localeOptions.find(x => x.value === query.get('lang')); // this apply once
  let initialChannel = query.get('channel'); // this apply once
  if (initialChannel === 'null') initialChannel = null;
  
  const [formLocale, setFormLocale] = useState<string | null>(
    initialLocale?.value as string || 
    selectedLocale?.value as string || 
    null);
  const [formChannel, setFormChannel] = useState<string | null>(initialChannel || null);
  
  const productDetailContextValue = useMemo<ProductDetailContextProps>(() => ({
    locale: formLocale,
    setLocale: setFormLocale,
    channel: formChannel,
    setChannel: setFormChannel,
  }), [formLocale, formChannel]);  

  const tabs: TabsProps["items"] & { key: TabKeys }[] = useMemo(() => ([
    {
      key: "information",
      forceRender: true,
      label: t["common.information"],
      children: <ProductInformation />
    },
    {
      key: "variants",
      forceRender: true,
      label: t["common.variants"],
      children: <ProductVariants />
    },
    {
      key: "publications",
      forceRender: true,
      label: t["common.publications"],
      children: <ProductPublications />
    }
  ]), [t]);

  if (isLoading) {
    return <LoadingSpin />;
  }

  return (
    <ProductDetailContext.Provider value={productDetailContextValue}>
      <Container>
        <GeneralInformation />
        <Tabs
          defaultActiveKey={viewParam ?? "information"}
          items={tabs}
          onChange={changeDetailsView}
        />
      </Container>
    </ProductDetailContext.Provider>
  );
};
