import { createContext } from "react";

export interface ProductDetailContextProps {
    locale: string | null;
    setLocale: (e: string) => void;
    channel: string | null,
    setChannel: (c: string | null) => void;
}

export default createContext<ProductDetailContextProps>({
    locale: null,
    setLocale: () => {},
    channel: null,
    setChannel: () => {},
})