import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Form, Modal, Select, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { CreateProductRequest } from "@pm/models/CreateProductRequest";
import { productsApiService } from "@pm/api/ProductsApiService";
import { styled } from "styled-components";
import { productRouteConstants } from "@pm/productRoutes";
import _ from "lodash";
import { ProductEntity } from "@pm/models/ProductEntity";
import { LabelText } from "@shared/ui/atoms/LabelText";
import { themeColors } from "../../../../../common/ui/styles/themeColors";
import { getDate } from "../../../../../common/modules/dateFormat";
import { useCommonUiStore } from "../../../../../common/domain/state/stores/CommonUiStore";
import getLocalizedValue from "@pm/helpers/getLocalizedValue";

const ProductForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 24px;
    }
  }

  .ant-modal-header {
    margin-bottom: 16px;
  }
`;

const PreviewProduct = styled.div`
  background-color: #f5f5f5;
  margin-top: 8px;
  padding: 16px;

  img {
    width: 48px;
    display: inline-block;
    vertical-align: top;
  }

  .info {
    width: calc(100% - 80px);
    display: inline-block;
    padding: 0 16px;

    .name {
      font-weight: 600;
      color: ${themeColors.colorPrimary};
    }

    .label {
      width: 100px;
      display: inline-block;
      font-weight: 600;
    }
  }
`;

interface Props {
  locale: string;
}

const DebounceTime = 800;

const CreateVariantModal = forwardRef((props: Props, ref) => {
  const [form] = Form.useForm<CreateProductRequest>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductEntity>();
  const [products, setProducts] = useState<ProductEntity[] | undefined>([]);
  const [productOptions, setProductOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const navigate = useNavigate();

  const { t } = useCommonUiStore();

  const { locale } = props;

  useImperativeHandle(ref, () => ({ setIsOpen }));

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen, form]);

  const debouncedSearch = _.debounce(async (keyword) => {
    if (!keyword) {
      return;
    }

    setProducts(undefined);
    const response = await productsApiService.getProducts({
      page: 1,
      pageSize: 10,
      search: [
        {
          field: "name",
          operation: "ct",
          condition: keyword
        }
      ]
    });

    setProducts(response.items);
  }, DebounceTime);

  useEffect(() => {
    if (!products?.length) {
      setProductOptions([]);
      return;
    }

    setProductOptions(
      products.map((x) => ({ value: x.id, label: `${x.name} - ${x.id}` }))
    );
  }, [products]);

  const handleSelectedProduct = (value: string) => {
    setSelectedProduct(products?.find((x) => x.id === value));
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    await productsApiService.createProduct(values);

    navigate(
      `/${productRouteConstants.productList}/${values.mainProductId}?view=variants`
    );
    setIsOpen(false);
  };

  return (
    <Modal
      title={t["product.createNewVariant.title"]}
      okText={t["common.createNew"]}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={handleOk}
      width={800}
      destroyOnClose
    >
      <ProductForm
        form={form}
        name="create-variant"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        layout="horizontal"
      >
        <Form.Item<CreateProductRequest>
          label={t["product.createNew.mainProduct"]}
          name="mainProductId"
          rules={[
            {
              required: true,
              message: t["product.createNew.mainProductRequireMessage"]
            }
          ]}
        >
          <Select
            options={productOptions}
            showSearch
            loading={!products}
            onSearch={debouncedSearch}
            onSelect={handleSelectedProduct}
            filterOption={false}
            placeholder={t["product.createNew.namePlaceholder"]}
          />
        </Form.Item>

        {!selectedProduct ? null : (
          <div>
            <LabelText>{t["common.preview"]}</LabelText>
            <PreviewProduct>
              <img
                alt={getLocalizedValue(selectedProduct.thumbnail, locale, null, null)?.fileName}
                src={getLocalizedValue(selectedProduct.thumbnail, locale, null, null)?.fileLink}
              />
              <span className="info">
                <div className="name">{getLocalizedValue(selectedProduct.name, locale, null, "")}</div>
                <div>
                  <span className="label">{t["common.categories"]}</span>
                  {selectedProduct.categories.map((x) => (
                    <Tag>{x.name}</Tag>
                  ))}
                </div>
                <div>
                  <span className="label">{t["common.productId"]}</span>
                  <span>{selectedProduct.id}</span>
                </div>
                <div>
                  <span className="label">{t["common.created"]}</span>
                  <span>{getDate(selectedProduct.createdAt)}</span>
                </div>
              </span>
            </PreviewProduct>
          </div>
        )}
      </ProductForm>
    </Modal>
  );
});

export default CreateVariantModal;
