import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";

interface Props {
  children: any;
  ["data-row-key"]: string;
}

export const DraggableRow: React.FC<Props> = ({
  children,
  ...props
}): JSX.Element => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });
  const style: any = {
    transform: CSS.Transform.toString(transform),
    transition: transition
  };

  if (children[0]?.props.record.children) {
    style.backgroundColor = "#f5f5f5";
  }
  if (isDragging) {
    style.backgroundColor = "#dbfff7";
  }

  return (
    <tr ref={setNodeRef} style={style}>
      {isDragging
        ? children
        : children.map((x: any, i: any) => {
            if (i !== 0) {
              return x;
            }

            x.props.record.attributes = attributes;
            x.props.record.listeners = listeners;
            return x;
          })}
    </tr>
  );
};
