import { AttributeTemplate } from "@am/models/valueObjects/attributeTemplate";
import FormSettingContext from "../context/FormSettingContext";
import { useContext, useMemo } from "react";
import { Labels } from "../../../../../common/domain/valueObjects/Label";

export default function useLabel(
  template: AttributeTemplate | null | undefined | Labels[],
): string {
  const formSetting = useContext(FormSettingContext);
  return useMemo(() => {
    if (!template) return "";

    const labels = Array.isArray(template) ? template as Labels[] : template?.labels;
    const target = (labels ?? []).find(
      (x) => x.locale === formSetting.locale,
    );
    
    // @ts-ignore
    return target?.value || template?.name;
  }, [formSetting, template]);
}