import { AttributeTemplate } from "@am/models/valueObjects/attributeTemplate";
import { useContext, useMemo } from "react";
import FormSettingContext from "../context/FormSettingContext";
import { DefaultOptionType } from "antd/es/select";

export default function useOptions(
  template: AttributeTemplate | null | undefined,
): DefaultOptionType[] {
  const formSetting = useContext(FormSettingContext);

  return useMemo<DefaultOptionType[]>(() => {
    if (!template) return [];

    const localizedOptions = template.options?.map((x) => {
      return {
        label:
          x.displayValues.find((d) => d.locale === formSetting.locale)?.value ||
          x.key,
        value: x.key,
      };
    });

    return localizedOptions || [];
  }, [template, formSetting]);
}
