import React from "react";
import { TranslationMap } from "../../../../features/i18n/translation";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";

export const yesNoOptions = (t: TranslationMap): { label: React.ReactNode; value: boolean }[] => ([
  {
    label: <span data-test={dMap["option-yes"]}>{t["common.yes"]}</span>,
    value: true
  },
  {
    label: <span data-test={dMap["option-no"]}>{t["common.no"]}</span>,
    value: false
  }
]);
