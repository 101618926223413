import { useAtom, useAtomValue } from "jotai";

import {
  ProductManagementUIState,
  uiStateAtom,
} from "@pm/state/productManagementUIState";
import { categoryApiService } from "@cm/common/services/categoryApiService";
import { useCommonStore } from "../../../common/domain/state/stores/useCommonStore";
import { LocaleValues } from "../../../common/types/commonTypes";

export const useUIState = () => {
  const [uiState, setUIState] = useAtom(uiStateAtom);
  const { ui: { defaultLocale }} = useCommonStore();

  const setSingleState =
    (name: keyof ProductManagementUIState) => (value: any) => {
      setUIState((prev: ProductManagementUIState) => {
        if (prev[name] === value) return prev;
        return {
          ...prev,
          [name]: value,
        };
      });
    };

  // load all categories, unless already loaded
  const loadAllCategories = async () => {
    const categories = await categoryApiService.getCategories(defaultLocale?.code as LocaleValues);

    setUIState((v) => ({
      ...v,
      allCategories: categories.items,
    }));
  };

  return {
    uiState,
    setIsEditMode: setSingleState("isEditMode"),
    setIsSidebarOpen: setSingleState("isSidebarOpen"),
    setPublishRequested: setSingleState("isPublishRequested"),
    loadAllCategories,
  };
};

export const useUIStateReadOnly = () => useAtomValue(uiStateAtom);
