import { Select } from "antd";
import { BooleanSelect } from "../../../../../../../common/ui/molecules/selection/BooleanSelect";
import { yesNoOptions } from "../../../../../../../common/ui/molecules/selection/SelectOptions";
import { FormColumn } from "../formColumnType";
import { TranslationMap } from "../../../../../../../features/i18n/translation";
import { useMemo } from "react";

export const booleanSelectRender: Pick<FormColumn, "render"> = {
  render: (value) => {
    return <BooleanSelect booleanValue={value} />;
  }
};

/**
 * TODO: consolidate with normal boolean renderer or `BooleanSelect`
 */
export const booleanSelectWithText = (
  truthy: string,
  falsy: string,
  t: TranslationMap,
): Pick<FormColumn, "render"> => {
  const options: { label: string; value: unknown }[] = [
    {
      label: truthy,
      value: true
    },
    {
      label: falsy,
      value: false
    }
  ];
  const yesNoOpt = yesNoOptions(t);
  return {
    render: (value) => {
      const defaultValue = yesNoOpt.find(
        (option) => option.label === value
      );

      return <Select defaultValue={defaultValue} options={options} />;
    }
  };
};

export const booleanSelectWithCustomLabels = (
  truthyLabel: string,
  falsyLabel: string,
  placeholderTruthy?: boolean
): Pick<FormColumn, "render"> => {
  const options: { label: string; value: unknown }[] = [
    {
      label: truthyLabel,
      value: true
    },
    {
      label: falsyLabel,
      value: false
    }
  ];
  return {
    render: (value) => (
      <Select
        defaultValue={options.find(
          (option) => option.value === (value ?? false)
        )}
        placeholder={options[placeholderTruthy ? 0 : 1].label}
        options={options}
      />
    )
  };
};
