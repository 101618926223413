import { productsApiService } from "@pm/api/ProductsApiService";
import {
  AssetMinimumInfo,
  DependentValue,
  ProductEntity,
} from "@pm/models/ProductEntity";
import { LabelText } from "@shared/ui/atoms/LabelText";
import { Button, Col, Row, Select } from "antd";
import { useAtomValue } from "jotai";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useCommonStore } from "../../../../../common/domain/state/stores/useCommonStore";
import { AttributeInputTypeEnum } from "@am/models/enums/attributeInputTypeEnum";
import { ProductDetailForm } from "@pm/ui/pages/productDetailsPage/productDetailForm/ProductDetailForm";
import { ThumbnailImage } from "../thumbnailImage/ThumbnailImage";
import { TableDataType, VariantsTable } from "./VariantsTable";
import { useUIStateReadOnly } from "@pm/hooks/useUIState";
import { productIdAtom } from "@pm/state/productManagementEntityState";
import useProductEntity from "@pm/hooks/useProductEntity";
import ProductDetailContext from '@pm/ui/pages/productDetailsPage/context'
import { addValue } from "@pm/helpers/dependantControl";
import getLocalizedValue from "@pm/helpers/getLocalizedValue";
import { AttributeTemplate } from "@am/models/valueObjects/attributeTemplate";

const Container = styled(Row)`
  .thumbnail-image {
    width: 100%;
    background-color: #f3f3f3;
    padding-bottom: 16px;
    padding-left: 16px;

    .preview-image .ant-image,
    .ant-upload-drag,
    .placeholder {
      height: 130px !important;
    }

    .anticon-picture {
      font-size: 30px;
    }
  }

  .variant-list {
    border-right: 1px solid #d9d9d9;
  }

  .actions {
    padding: 16px;

    > label {
      text-transform: lowercase;
    }

    .locale {
      margin-left: 16px;

      .ant-select {
        min-width: 185px;
        margin-left: 8px;
      }
    }

    .add-button {
      float: right;
    }

    @media screen and (max-width: 1100px) {
      position: relative;
      height: 90px;

      > label {
        position: absolute;
        margin-top: 40px;
      }

      .locale {
        position: absolute;
        margin-left: 0;
        margin-top: 0;
      }
    }
  }
`;

export const ProductVariants: React.FC = () => {
  const productId = useAtomValue(productIdAtom);
  const {
    productState: { productVariants , isVariantLoading },
    loadProductVariants,
    setVariantThumbnail
  } = useProductEntity();

  const { isEditMode } = useUIStateReadOnly();

  const {
    ui: { defaultLocale, defaultTransFormLocale }
  } = useCommonStore();
  const [locale, setLocale] = useState<string>("");
  useEffect(() => {
    if (defaultLocale) setLocale(defaultLocale.code);
  }, [defaultLocale]);

  const { 
    productState: { attributesMap, attributeSettingMap } 
  } = useProductEntity();
  const [dataSource, setDataSource] = useState<TableDataType[]>();
  const [selectedVariant, setSelectedVariant] = useState<ProductEntity>();
  const changingVariantId = useRef<string>();
  const {
    ui: { locales, t }
  } = useCommonStore();

  const productContext = useContext(ProductDetailContext);
  const effectiveLocale: string | null = productContext.locale === defaultLocale?.code ? null : productContext.locale;

  const resolveAttributeValueText = (value: DependentValue<any>[], locale: string | null, channel: string | null, attributeTemplate: AttributeTemplate) => {
    const targetValue = getLocalizedValue(value, locale, channel, "");
    if (targetValue === undefined || targetValue === null || targetValue === '') {
      return "--";
    }

    const translateSingleText = (text: string): string => {
      const translatedValue = (attributeTemplate.options ?? []).find(x => x.key === text);
      return translatedValue?.displayValues.find(d => d.locale === locale)?.value ?? text ?? "";
    }

    if (!Array.isArray(targetValue)) {
      return translateSingleText(targetValue);
    }

    if (!targetValue.length) {
      return "--";
    }

    // we need to translate the lables using templates
    const translatedValues = targetValue.map(translateSingleText);
    return translatedValues.join(', ');
  };

  const buildDataSource = (locale: string, channel: string | null) => {
    if (!productVariants) {
      return;
    }
    if (productVariants.length === 0) {
      setDataSource([]);
    }

    
    const simpleAttributes: any[] = Object.entries(attributesMap)
    .filter(([key, _]) => attributeSettingMap[key]?.isVariantDependent === true)
    .filter(
      ([_, value]) =>
        (value as any)?.inputType === AttributeInputTypeEnum.simple || 
        (value as any)?.inputType === AttributeInputTypeEnum.selection
    );

    const dataSourceTemp = productVariants.map((variant) => ({
      key: variant.id,
      name: variant.name,
      thumbnail: variant.thumbnail,
      attributes:
        simpleAttributes?.map(([key, x]) => ({
          value: resolveAttributeValueText(variant.attributes[x.name], locale, channel, attributesMap[key]),
          label:
            x.labels?.find((x: any) => x.locale === locale)?.value ?? x.name
        })) || []
    }));
    setDataSource(dataSourceTemp);
  };

  useEffect(() => {
    loadProductVariants(false);
  }, [productId]);

  useEffect(() => {
    const variant = productVariants?.find((x) => x.id === selectedVariant?.id);
    if (variant) {
      setSelectedVariant({ ...variant });
    }
  }, [isEditMode]);

  useEffect(() => {
    if (!attributesMap) {
      return;
    }

    buildDataSource(locale, productContext.channel);
  }, [attributesMap, productVariants, selectedVariant, isEditMode, locale, productContext]);

  const handleAddVariant = async () => {
    if (!productId) {
      return;
    }

    await productsApiService.createProduct({ mainProductId: productId });
    const newVariantIds = await loadProductVariants(true);
    if (newVariantIds && newVariantIds.length > 0) {
      handleRowClicked(newVariantIds[0]);
    }
  };

  const handleRowClicked = (variantId: string) => {
    changingVariantId.current = variantId;
    setSelectedVariant({} as any);
  };

  useEffect(() => {
    if (!selectedVariant || selectedVariant.attributes) {
      return;
    }

    const variant = productVariants?.find(
      (x) => x.id === changingVariantId.current
    );
    if (!variant) {
      return;
    }

    setSelectedVariant(variant);
  }, [selectedVariant]);

  const handleDeleteVariant = async (id: string) => {
    await productsApiService.deleteProduct(id);
    await loadProductVariants(true);
    setSelectedVariant(undefined);
  };

  const handleUploadThumbnailFinished = (thumbnail: AssetMinimumInfo) => {
    if (!productVariants?.length || !selectedVariant) {
      return;
    }
    
    const value: DependentValue<AssetMinimumInfo> = {
      locale: defaultLocale?.code === productContext.locale ? defaultTransFormLocale.code : productContext.locale,
      channel: productContext.channel,
      value: thumbnail,
    }
    const updatedThumbnails = addValue(selectedVariant.thumbnail ?? [], value);
    setVariantThumbnail(selectedVariant.id, updatedThumbnails);
  };

  return (
    <Container>
      <Col span={12} className="variant-list">
        <div className="actions">
          <LabelText>
            {productVariants?.length ?? 0} {t["common.variants"]}
          </LabelText>
          <span className="locale">
            <span>{t["common.locale"]}: </span>
            <Select
              defaultValue={defaultLocale?.code}
              onChange={setLocale}
              options={locales?.map((x) => ({ value: x.code, label: x.name }))}
            />
          </span>
          {!isEditMode ? null : (
            <Button
              className="add-button"
              type="primary"
              onClick={handleAddVariant}
            >
              {t["product.variantsTable.addVariant"]}
            </Button>
          )}
        </div>
        <VariantsTable
          isEdit={isEditMode}
          dataSource={dataSource}
          loading={isVariantLoading}
          selectedVariantId={selectedVariant?.id}
          onRowClick={handleRowClicked}
          onDeleteVariant={handleDeleteVariant}
        />
      </Col>

      <Col span={12}>
        {selectedVariant?.attributes ? (
          <div>
            <ThumbnailImage
              isEdit={isEditMode}
              className="thumbnail-image"
              image={getLocalizedValue(selectedVariant?.thumbnail, effectiveLocale, productContext.channel, null)}
              onUploadFinished={handleUploadThumbnailFinished}
            />
            <ProductDetailForm variantId={selectedVariant.id} />
          </div>
        ) : null}
      </Col>
    </Container>
  );
};
