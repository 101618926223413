import { styled } from "styled-components";
import { colorSeedTokens } from "../../../../../common/ui/styles/themeColors";

interface ErrorTextProps {
  messages?: string[];
  status?: string;
}

const DivError = styled.div<{color?: string}>`
  color: ${v => v.color || 'red'};
`

export default function ErrorText(props: ErrorTextProps) {
  if (!props.messages || props.messages.length === 0) return null;
  if (props.status !== "error") return;
  
  return (
    <>
      { props.messages.map((m, idx) => <DivError key={idx} color={colorSeedTokens.colorError}>{m}</DivError>) }
    </>
  )
}