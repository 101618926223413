import { CategoryListResponse } from "@cm/domain/valueObjects/categoryListResponse";
import { atom } from "jotai";

export interface ProductManagementUIState {
  isEditMode: boolean;
  isSidebarOpen: boolean;
  isPublishRequested: boolean,
  allCategories?: CategoryListResponse[];
}

export const uiStateAtom = atom({
  isEditMode: false,
  isSidebarOpen: window.innerWidth > 1130,
  isPublishRequested: false,
} as ProductManagementUIState);
