import { AttributeStatusEnum } from "@am/models/enums/attributeStatus";
import { CategoryListResponse } from "@cm/domain/valueObjects/categoryListResponse";
import { productsApiService } from "@pm/api/ProductsApiService";
import useProductEntity from "@pm/hooks/useProductEntity";
import { useUIState } from "@pm/hooks/useUIState";
import { productRouteConstants } from "@pm/productRoutes";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { Button, Col, Flex, MenuProps, Modal, Row, Space, Tag } from "antd";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { useCommonUiStore } from "../../../../common/domain/state/stores/CommonUiStore";
import { useCommonStore } from "../../../../common/domain/state/stores/useCommonStore";
import { isApiErrorResponse } from "../../../../common/domain/valueObjects/ApiErrorResponse";
import { getDate } from "../../../../common/modules/dateFormat";
import { showApiErrorNotification } from "../../../../common/modules/showNotifications";
import { AinIcon } from "../../../../common/ui/atoms/AinIcon";
import { themeColors } from "../../../../common/ui/styles/themeColors";
import { routeConstants } from "../../../../features/routes/routeConstants";
import DisableDropdownButton from "../molecules/DisableDropdownButton";
import ProductDetailContext from '../pages/productDetailsPage/context';
import EditProductCategoryModal from "./modals/EditProductCategoryModal";
import ConfirmDeleteModal from "../../../../common/ui/molecules/modals/ConfirmDeleteModal";
import ChannelSelector from "../molecules/ChannelSelector";
import getLocalizedValue from "@pm/helpers/getLocalizedValue";

const Container = styled.section`
  background: ${themeColors.grey3};
  border-bottom: 1px solid ${themeColors.colorBorder};
  padding: 24px;

  .header-breadcrumb-app-title {
    color: ${themeColors.colorTextDescription};
  }

  .product-name {
    font-weight: 600;
    font-size: 20px;
    color: #000000a6;
    margin: 12px 0 !important;
  }

  .categories {
    margin-right: 8px;
  }

  .text-wrapper {
    display: flex;
    align-items: center;
    gap: 30px
  }

  .label {
    font-weight: 600;
    color: #000000a6;
    min-width: 90px
  }

  .product-id {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(100% - 90px);
  }

  .status {
    background-color: #e6fffb;
    color: #08979c;
    text-transform: capitalize;
  }

  .ant-col {
    margin: 3px 0;
  }

  .ai-annotation {
    background-color: #ffffff;
    padding: 4px 8px;
    border-radius: 16px;
  }

  .action-buttons {
    text-align: right;

    .draft-text {
      margin-bottom: 8px;
    }

    .ant-dropdown-button {
      display: inline-block;
      width: initial;
      margin-left: 8px;
    }

    button:not(.ant-btn-compact-item) {
      margin-left: 8px;
    }
  }
`;

export const GeneralInformation: React.FC = () => {
  const navigate = useNavigate();
  const {
    uiState: { isEditMode, allCategories, isPublishRequested },
    setIsEditMode,
    setPublishRequested,
  } = useUIState();

  const {
    productState: { overwriteCategoryIds, isUpdated, product, productVariants, validations },
    loadProduct,
    loadProductVariants,
    updateProductCategory,
    saveProduct,
    publishProduct
  } = useProductEntity();
  const { ui } = useCommonStore();
  const { defaultTransFormLocale } = ui;

  const confirmDeleteModal = useRef<any>(null);

  const { t, defaultLocale, channels } = useCommonUiStore();
  const productContext = useContext(ProductDetailContext);
  const useDefaultLocale = defaultLocale?.code === productContext.locale;

  const publishable = useMemo(() => {
    if (!channels || channels.length == 0) return false;
    return true;
  }, [channels]);

  const isBlocked = useMemo(() => product?.isBlocked === true, [product]);

  const [showEditCategory, setShowEditCategory] = useState(false);

  const productCategoryIds = useMemo(() => {
    return overwriteCategoryIds ?? product?.categories.map((x) => x.id) ?? [];
  }, [product, overwriteCategoryIds]);

  const productCategories = useMemo(() => {
    if (!allCategories) return product?.categories;
    return productCategoryIds
      .map((x) => allCategories.find((a) => x === a.id))
      .filter((x) => !!x) as CategoryListResponse[];
  }, [productCategoryIds, allCategories, product]);

  const updateCategory = useCallback((categoryIds: string[]) => {
    updateProductCategory(categoryIds);
  }, []);

  useEffect(() => {
    return () => {
      setIsEditMode(false);
      setPublishRequested(false);
    };
  }, []);

  const isDataValid = useMemo(() => {
    if (product && !validations[product.id]) return false;
    if (productVariants && productVariants?.some(x => validations[x.id] === false)) return false;
    return true;
  }, [product, productVariants, validations]);

  const handleConfirmDelete = async () => {
    if (!product) return;
    const response = await productsApiService.deleteProduct(product?.id);
    confirmDeleteModal.current.setIsOpen(false);

    if (isApiErrorResponse(response)) {
      showApiErrorNotification(response);
    } else {
      setIsEditMode(false);
      navigate(`/${productRouteConstants.productList}`);
    }
  };

  const productName = useMemo(() => {
    const names = product?.name ?? [];
    const targetLocale = useDefaultLocale ? defaultTransFormLocale.code : productContext.locale;
    const target = getLocalizedValue(names, targetLocale, productContext.channel ?? null, "");
    return target;
  }, [product, productContext, useDefaultLocale, defaultTransFormLocale]);

  const handleUnpublishClicked = useCallback(async () => {
    if (!product) return;
    const response = await productsApiService.unpublishProduct(product.id, null, productContext.channel);

    if (response) {
      setIsEditMode(false);
    } else {
      Modal.error({
        title: t["product.edit.cannotUnpublish"],
        content: t["product.edit.cannotUnpublishContent"],
        okText: t["common.close"]
      });
    }
  }, [t, product]);

  const handleSaveDraftClicked = async () => {
    await saveProduct();
    setIsEditMode(false);
  };

  const handlePublishClick = async () => {
    if (!isDataValid) {
      setPublishRequested(true); // allow error status to show
      Modal.error({
        title: t["product.edit.cannotPublish"],
        content: t["product.edit.dataInvalidContent"],
        okText: t["common.close"]
      });
      return;
    } else {
      setPublishRequested(false); // clear status
    }
    if (!publishable) {
      Modal.error({
        title: t["product.edit.noChannel"],
        content: t["product.edit.noChannelContent"],
        okText: t["common.close"]
      });
      return;
    }

    const result = await publishProduct(productContext.channel);

    if (result) {
      await loadProduct();
      setIsEditMode(false);
    }
  };

  const handleDeleteDraftClicked = useCallback(async () => {
    if (!product) return;
    await productsApiService.discardProduct(product.id);
    setIsEditMode(false);
    await loadProduct();
    await loadProductVariants(false);
    // navigate(`/${productRouteConstants.productList}`);
  }, [product]);

  const handleCancel = useCallback(async () => {
    setIsEditMode(false);
    await loadProduct();
    await loadProductVariants(false);
  }, []);

  const draftActionItems = useMemo<MenuProps["items"]>(() => ([
    {
      key: 0,
      label: t["common.cancel"],
      onClick: handleCancel,
    }, 
    {
      key: 1,
      label: t["common.deleteDraft"],
      disabled: !product?.isHavingDraft,
      onClick: handleDeleteDraftClicked,
    }
  ]), [t, product, handleDeleteDraftClicked, handleCancel]);

  const publishActionItems = useMemo<MenuProps["items"]>(() => ([
    {
      key: "0",
      label: t["common.unpublishAll"],
      onClick: handleUnpublishClicked,
      disabled: !productContext.channel,
    }
  ]), [t, handleUnpublishClicked, productContext])

  if (!product) return null;

  const channelDisplay = (
    <Flex gap={4} align="center">
      <AinIcon icon={productContext.channel ? "shop" : "global"}/>
      { channels.find(x => x.code === productContext.channel)?.name || t["common.allChannels"] }
    </Flex>
  )
  return (
    <Container>
      <Row>
        <Flex align="center" justify="space-between" style={{ width: "100%" }}>
          <Space>
            <Button
              data-test={dMap["button-back"]}
              type="link"
              onClick={() => {
                navigate(`/${productRouteConstants.productList}`);
              }}
              size="small"
            >
              <AinIcon icon="arrow-left" size={12}></AinIcon>
            </Button>
            <span className="header-breadcrumb-app-title">
              {t["common.pim"]}
            </span>
            /<strong>{productName}</strong>
          </Space>
          {isBlocked ? (
            <Flex align="center" className="ai-annotation" gap={5}>
              <AinIcon icon="external-annotation" />
              <span style={{ fontSize: 12 }}>
                {t["product.edit.externalAnnotationInProgress"]}
              </span>
            </Flex>
          ) : null}
        </Flex>
      </Row>
      <Row className="product-name">{productName}</Row>
      <Row>
        <Col className="text-wrapper" span={24}>
          <span className="label">{t["common.categories"]}</span>
          {productCategories?.map((x) => (
            <Tag key={x.id} className="categories">
              <NavLink
                to={`/${routeConstants.categories}/${x.id}`}
                target="_blank"
                rel="noopener"
              >
                {x.name}
              </NavLink>
            </Tag>
          ))}
        </Col>
        <Col span={13}>
          <Row>
            <Col className="text-wrapper" span={12}>
              <span className="label">{t["common.created"]}</span>
              {getDate(product.updatedAt)}
            </Col>
            <Col className="text-wrapper" span={12}>
              <span className="label">{t["common.productId"]}</span>
              <span className="product-id">{product.id}</span>
            </Col>
            <Col className="text-wrapper" span={12}>
              <span className="label">{t["common.lastUpdated"]}</span>
              {getDate(product.updatedAt)}
            </Col>
            <Col className="text-wrapper" span={12}>
              <Flex align="center">
                <span className="label">{t["common.saleChannel"]}</span>
                <Tag>{ channelDisplay }</Tag> 
                <ChannelSelector onChange={productContext.setChannel}/>
              </Flex>
            </Col>
          </Row>
        </Col>
        <Col span={11} className="action-buttons">
          {product.isHavingDraft && isEditMode ? (
            <div className="draft-text">
              {t["product.details.draftText"]}
            </div>
          ) : null}
          {isEditMode ? (
            <>
              <Button
                danger
                data-test={dMap["edit-category-button"]}
                onClick={() => setShowEditCategory(true)}
              >
                {t["common.editCategories"]}
              </Button>
              <DisableDropdownButton 
                items={draftActionItems} 
                disableButton={!isUpdated}
                onButtonClick={handleSaveDraftClicked} 
                buttonText={ t["common.saveDraft"] }/>
              <DisableDropdownButton
                type="primary"
                buttonText={t["common.publishAll"]}
                disableButton={product.isBlocked || !productContext.channel || (!isDataValid && isPublishRequested)}
                disableDropdown={product.status === AttributeStatusEnum.unpublished}
                onButtonClick={handlePublishClick}
                items={publishActionItems}
              />
            </>
          ) : isBlocked ? (
            <>
              <Button
                type="primary"
                data-test={dMap["button-refresh"]}
                onClick={() => window.location.reload()}
              >
                {t["product.detail.statusRefresh"]}
              </Button>
            </>
          ) : (
            <>
              {product.status === AttributeStatusEnum.unpublished && (
                <Button
                  danger
                  data-test={dMap["delete-button"]}
                  onClick={() => confirmDeleteModal.current.setIsOpen(true)}
                >
                  {t["product.delete"]}
                </Button>
              )}
              <Button
                type="primary"
                data-test={dMap["edit-button"]}
                onClick={() => setIsEditMode(true)}
              >
                {t["common.edit"]}
              </Button>
            </>
          )}
        </Col>
      </Row>

      <ConfirmDeleteModal
        ref={confirmDeleteModal}
        onDeleteClicked={handleConfirmDelete}
      >
        <div>{t["product.detail.confirmDelete.text"]}</div>
        <br />
        <div>
          {t["product.detail.confirmDelete.detailed1"]}
          <span className="delete-confirm-highlight">
            {t["common.confirmDelete.highlight"]}
          </span>
          {t["product.detail.confirmDelete.detailed2"]}
        </div>
      </ConfirmDeleteModal>
      <EditProductCategoryModal
        isOpen={showEditCategory}
        onClose={() => setShowEditCategory(false)}
        categoryIds={productCategoryIds}
        updateCategory={updateCategory}
      />
    </Container>
  );
};
