import { useContext, useMemo } from "react";
import { BaseInputProps } from "../../types";
import Label from "../Label";
import FormSettingContext from "../../context/FormSettingContext";
import { DEFAULT_TEXT } from "../../utils";
import useOptions from "../../hooks/useOptions";
import { Select } from "antd";
import { selectSearchFunction } from "../../../../../../common/modules/selectHelper";
import { ItemContainer } from "../ItemContainer";
import { ValidationFunction, validationIsRequired } from "../rules";
import useValidation from "../../hooks/useValidation";
import ErrorText from "../ErrorText";
import useFormWideValidator from "../../hooks/useFormWideValidator";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

export default function InputSelect(props: BaseInputProps) {
  const { value, onUpdate, template, isRequired } = props;
  const formSetting = useContext(FormSettingContext);
  const { editMode } = formSetting;
  const isMultipleValues = template?.isMultipleValues || false;

  const { t } = useCommonUiStore();

  const options = useOptions(template);
  const displayValues = useMemo(() => {
    const valueArray = (Array.isArray(value) ? value : [value]) || [];
    const labelArray = options
      .filter((x) => valueArray.includes(x.value))
      .map((x) => x.label);
    return labelArray || [];
  }, [value, options]);

  const validationRules = useMemo(() => {
    if (!editMode) return [];
    const rules: ValidationFunction[] = [];
    if (isRequired) rules.push(validationIsRequired(t["common.requireInputMessage"]))
    return rules;
  }, [isRequired, editMode, t]);

  const validationResult = useValidation(value, validationRules, formSetting.showValidationError);
  useFormWideValidator(editMode, validationResult.isValid, validationResult.errorMessages);

  if (formSetting.editMode) {
    return (
      <ItemContainer>
        <Label template={props.template} isRequired={isRequired} />
        <Select
          value={value}
          onChange={onUpdate}
          options={options}
          placeholder={t["common.selectPlaceholder"]}
          showSearch
          allowClear
          mode={isMultipleValues ? "multiple" : undefined}
          filterOption={selectSearchFunction}
          style={{ width: "100%" }}
          status={validationResult.status}
        />
        <ErrorText messages={validationResult.errorMessages} status={validationResult.status}/>
      </ItemContainer>
    );
  } else {
    return (
      <ItemContainer>
        <Label template={props.template} isRequired={isRequired} />
        <div>
          {displayValues.length ? displayValues.join(", ") : DEFAULT_TEXT}
        </div>
      </ItemContainer>
    );
  }
}
