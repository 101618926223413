import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Form, Input, Modal } from "antd";
import "./createCategoryModal.scss";
import { CreateCategoryRequest } from "../../../../domain/valueObjects/createCategoryRequest";
import { CategoryListResponse } from "../../../../domain/valueObjects/categoryListResponse";
import { categoryApiService } from "../../../../common/services/categoryApiService";
import { useNavigate } from "react-router-dom";
import { routeConstants } from "../../../../../../features/routes/routeConstants";
import { useDispatchCategoryUpsert } from "../../../../domain/hooks/useCategoryUpsertListener";
import { useCategoryPageState } from "../../../../domain/hooks/useCategoryPageState";
import CategoryTreeSelect from "../../../../../../common/ui/molecules/CategoryTreeSelect";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

interface Props {}

const CreateCategoryModal = forwardRef((props: Props, ref) => {
  const [form] = Form.useForm<CreateCategoryRequest>();
  const [isOpen, setIsOpen] = useState<boolean>();
  const [{ allCategories }] = useCategoryPageState();
  const [parentOptions, setParentOptions] = useState<
    { value: string; label: string }[] | undefined
  >();
  const navigate = useNavigate();
  const { t } = useCommonUiStore();
  const { dispatchCategoryUpsert } = useDispatchCategoryUpsert();

  useImperativeHandle(ref, () => ({ setIsOpen }));

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen, form]);

  useEffect(() => {
    if (!allCategories) {
      return;
    }

    const options = allCategories
      .map((x) => ({
        value: x.id,
        label: resolveCategoryDisplayPath(x)
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setParentOptions(options);
  }, [allCategories]);

  const resolveCategoryDisplayPath = (category: CategoryListResponse) => {
    if (!category.categoryPath) {
      return category.appearanceName || category.name;
    }

    const displayNames: string[] = [];
    const paths = category.categoryPath.split("/");

    paths.forEach((path) => {
      const category = allCategories?.find((x) => x.name === path);
      if (category) {
        displayNames.push(category.appearanceName || category.name);
      }
    });

    return displayNames.join(" / ");
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    const { id } = await categoryApiService.createCategory(values);

    if (!id) {
      return;
    }

    navigate(`/${routeConstants.categories}/${id}`, { state: { isNew: true } });
    setIsOpen(false);
    dispatchCategoryUpsert(undefined);
  };

  return (
    <Modal
      title={t["category.createNew.title"]}
      okText={t["common.createNew"]}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={handleOk}
      width={800}
      destroyOnClose
      className="create-category-modal"
    >
      <Form
        form={form}
        name="create-category"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        layout="horizontal"
      >
        <Form.Item<CreateCategoryRequest>
          label={t["category.createNew.name"]}
          name="name"
          rules={[
            {
              required: true,
              message: t["category.createNew.nameRequireMessage"]
            }
          ]}
        >
          <Input placeholder={t["category.createNew.namePlaceholder"]} />
        </Form.Item>

        <Form.Item<CreateCategoryRequest>
          label={t["category.createNew.parentCategory"]}
          name="parentId"
        >
          <CategoryTreeSelect allCategories={allCategories} />
        </Form.Item>

        <Form.Item<CreateCategoryRequest>
          label={t["category.createNew.description"]}
          name="description"
        >
          <Input.TextArea
            placeholder={t["category.createNew.descriptionPlaceholder"]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default CreateCategoryModal;
