import { Input } from "antd";
import { BaseInputProps } from "../../types";
import { useContext, useMemo } from "react";
import FormSettingContext from "../../context/FormSettingContext";
import { DEFAULT_TEXT } from "../../utils";
import Label from "../Label";
import { ItemContainer } from "../ItemContainer";
import useValidation from "../../hooks/useValidation";
import { ValidationFunction, validationIsRequired } from '../rules'
import ErrorText from "../ErrorText";
import useFormWideValidator from "../../hooks/useFormWideValidator";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

export default function InputText(props: BaseInputProps) {
  const { value, onUpdate, isRequired, isArrayItem } = props;
  const formSetting = useContext(FormSettingContext);
  const { editMode } = formSetting;

  const { t } = useCommonUiStore();

  const validationRules = useMemo(() => {
    if (!editMode) return [];
    const rules: ValidationFunction[] = [];
    if (isRequired) rules.push(validationIsRequired(t["common.requireInputMessage"]))
    return rules;
  }, [isRequired, editMode, t]);

  const validationResult = useValidation(value, validationRules, formSetting.showValidationError);
  useFormWideValidator(editMode, validationResult.isValid, validationResult.errorMessages);

  if (editMode) {
    return (
      <ItemContainer>
        { isArrayItem ? null : <Label template={props.template} isRequired={isRequired} /> }
        <Input
          placeholder={t["common.typeHere"]}
          value={value}
          onChange={(e) => onUpdate(e.target.value)}
          status={validationResult.status}
        />
        <ErrorText messages={validationResult.errorMessages} status={validationResult.status}/>
      </ItemContainer>
    );
  } else {
    return (
      <ItemContainer>
        { isArrayItem ? null : <Label template={props.template} isRequired={isRequired} /> }
        <div>{value || DEFAULT_TEXT}</div>
      </ItemContainer>
    );
  }
}
