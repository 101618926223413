import { useCommonStore } from "../domain/state/stores/useCommonStore";
import { DefaultOptionType } from "antd/es/select";
import {
  ProductListState,
  productListStateAtom
} from "@pm/state/productListState";
import { useAtomState } from "./useAtomState";
import { useMemo } from "react";

export function useLocaleOptions(): {
  /** Api locale as options */
  localeOptions: DefaultOptionType[];
  /** Default locale from user, else from commonConstants */
  defaultLocale: DefaultOptionType | undefined;
  /** Selected locale first from store, else from default */
  selectedLocale: DefaultOptionType | undefined;
} {
  const { selectedLocale: storeLocale } =
    useAtomState<ProductListState>(productListStateAtom);

  const { locales, defaultLocale: appDefaultLocale } = useCommonStore().ui;
  const localeOptions = useMemo(() => (locales ?? []).map((locale) => ({
    label: locale.name,
    value: locale.code
  })), [locales]);

  const localeInOptions = useMemo(() => localeOptions.find(
    (l) => l.value === storeLocale
  ), [localeOptions, storeLocale]);

  const defaultLocale = useMemo(() => localeOptions.find(
    (l) => l.value === appDefaultLocale?.code
  ), [localeOptions, appDefaultLocale]);

  const selectedLocale = localeInOptions ?? defaultLocale;

  return { selectedLocale, localeOptions, defaultLocale };
}
