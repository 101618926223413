import React, { useRef } from "react";
import { Row } from "antd";
import { ConfigMenu } from "./configMenu/ConfigMenu";
import { Outlet, useNavigate } from "react-router-dom";
import { useAttributesStore } from "../../../state/stores/attributesStore";
import { apiService } from "../../../api/ApiService";
import { isApiErrorResponse } from "../../../../../common/domain/valueObjects/ApiErrorResponse";
import { showApiErrorNotification } from "../../../../../common/modules/showNotifications";
import { AttributeInputTypeEnum } from "../../../models/enums/attributeInputTypeEnum";
import { AttributeEntity } from "../../../models/entities/attributeEntity";
import { attributesRouteConstants } from "../../../attributeRoutes";
import { AttributeDetailsPageStyled } from "./AttributeDetailsPageStyled";
import { DetailsHeader } from "./detailsHeader/DetailsHeader";
import { styled } from "styled-components";
import { walkAttributeTemplate } from "@am/common/helpers/walkAttributeTemplate";
import ConfirmDeleteModal from "../../../../../common/ui/molecules/modals/ConfirmDeleteModal";
import { useCommonUiStore } from "../../../../../common/domain/state/stores/CommonUiStore";

type Props = {
  attributeEntity: AttributeEntity;
};

const LayoutContainer = styled.div`
  height: 100vh;
`;

export interface LayoutProps {
  header: React.ReactNode;
  menubar: React.ReactNode;
  content: React.ReactNode;
}

function getShouldShowConfigMenu(attributeEntity: AttributeEntity): boolean {
  const isSelection =
    attributeEntity?.inputType === AttributeInputTypeEnum.selection;
  const isForm = attributeEntity?.inputType === AttributeInputTypeEnum.form;
  let hasNestedSelection = false;
  walkAttributeTemplate(attributeEntity, (element) => {
    /** It is enough to know when there is one nested selection, so we early return for all the others */
    if (hasNestedSelection) return;
    if (element?.inputType === AttributeInputTypeEnum.selection) {
      hasNestedSelection = true;
    }
  });
  const showWhenFormHasNestedSelection = isForm && hasNestedSelection;
  const show = isSelection || showWhenFormHasNestedSelection;
  return show;
}

export const AttributeDetails: React.FC<Props> = ({
  attributeEntity
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const confirmDeleteModal = useRef<any>(null);
  const navigate = useNavigate();
  const { ui } = useAttributesStore();
  const { isEditing, setIsEditing } = ui;

  const { t } = useCommonUiStore();

  /**
   * ISSUE-Xpx2jNIV
   * Show for nested forms, that have selection
   */
  const shouldShowConfigMenu = getShouldShowConfigMenu(attributeEntity);

  if (!attributeEntity) {
    return <>{t["common.loading"]}</>;
  }

  function navigateBackToList() {
    setIsEditing(false);
    navigate(`/${attributesRouteConstants.attributes}`);
  }

  const handleConfirmDelete = async () => {
    const response = await apiService.deleteAttribute(attributeEntity.id);
    confirmDeleteModal.current.setIsOpen(false);

    if (isApiErrorResponse(response)) {
      showApiErrorNotification(response);
    } else {
      navigateBackToList();
    }
  };

  return (
    <AttributeDetailsPageStyled
      className={`details-page-container ${isEditing ? "is-editing" : ""}`}
    >
      <LayoutContainer>
        <DetailsHeader
          attributeEntity={attributeEntity}
          onDelete={() => confirmDeleteModal.current.setIsOpen(true)}
        />
        {shouldShowConfigMenu && <ConfigMenu />}
        <Row>
          <Outlet />
        </Row>
      </LayoutContainer>

      <ConfirmDeleteModal
        ref={confirmDeleteModal}
        onDeleteClicked={handleConfirmDelete}
      >
        <div>
          {t["attributeList.confirmDelete.text"].replace(
            "{0}",
            attributeEntity.name
          )}
        </div>
        <br />
        <div>
          {`${t["common.confirmDelete.detailed"]} ${t["attributeList.confirmDelete.detailed"]}`}
          <span className="delete-confirm-highlight">
            {t["common.confirmDelete.highlight"]}
          </span>
        </div>
      </ConfirmDeleteModal>
    </AttributeDetailsPageStyled>
  );
};
