import styled from "styled-components";
import { Button, Form, Switch } from "antd";
import { useUIState } from "@pm/hooks/useUIState";
import { Label } from "@shared/ui/molecules/Label";
import { Divider } from "@shared/ui/molecules/Divider";
import { LabelText } from "@shared/ui/atoms/LabelText";
import Search from "antd/es/input/Search";
import type { SearchProps } from 'antd/es/input/Search';
import { ChannelLocaleSelector } from "../../../../../../common/ui/molecules/selection/ChannelLocaleSelector";
import { DefaultOptionType } from "antd/es/select";
import { ApiSearchParam } from "../../../../../../common/types/commonTypes";
import { useCallback, useEffect, useState } from "react";
import { useLocaleOptions } from "../../../../../../common/hooks/useLocaleOptions";
import { useAtomState } from "../../../../../../common/hooks/useAtomState";
import {
  ProductListState,
  productListStateAtom
} from "@pm/state/productListState";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

const MenuBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  height: 100%;
  padding: 0px 16px;
`;

const FormItem = styled(Form.Item)`
  .search {
    min-width: 150px;
  }
`;

const FormButton = styled(Button)``;

const MenuBarForm = styled(Form)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  ${LabelText} {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
  }
  ${FormItem} {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
  }
  ${FormButton} {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 1100px) {
    gap: 8px;
  }
`;

export const MenuBar: React.FC = () => {
  const { uiState, setIsSidebarOpen } = useUIState();
  const { defaultLocale } = useLocaleOptions();
  const [preSearchValue, setPreSearchValue] = useState<string>('')
  const { 
    searchProductNameValue,
    selectedChannel,
    selectedLocale,
    setState 
  } = useAtomState<ProductListState>(productListStateAtom);
  const [form] = Form.useForm();

  const { t } = useCommonUiStore();

  const onSearch: SearchProps['onSearch'] = (searchValue: string) => {
    try {
      if (searchValue === preSearchValue) return;
      onFinish({search: searchValue, channelLocale: form.getFieldValue('channelLocale')})
    } finally {
      setPreSearchValue(searchValue)
    } 
  }

  const onFinish = ({
    search,
    channelLocale,
  }: {
    search?: string;
    channelLocale: string;
  }) => {
    const toks = channelLocale.split(":");
    setState("selectedChannel", toks[0] || null);
    setState("selectedLocale", toks[1]);

    const searchParams: ApiSearchParam[] = [];
    if (search) {
      searchParams.push({
        field: "name",
        operation: "ct",
        condition: search
      });
    }
    setState("searchProductNameValue", searchParams);
  };

  const handleResetSearch = () => {
    form.resetFields();
    form.setFieldValue("channelLocale", ":" + defaultLocale?.value);
    setPreSearchValue('')
  };

  useEffect(() => {
    const search = searchProductNameValue?.[0]?.condition;
    form.setFieldsValue({
      search
    });
  }, [form, searchProductNameValue]);

  useEffect(() => {
    form.setFieldValue("channelLocale", (selectedChannel ?? "") + ":" + selectedLocale);
  }, [selectedLocale, selectedChannel, form])

  const handleChannelLocaleSelect = useCallback((option: DefaultOptionType) => {
    form.setFieldValue("channelLocale", option.value);
  }, [form]);

  

  return (
    <MenuBarContainer>
      <Label
        labelText="Tree Menu"
        for={
          <Switch
            className="tree-switch"
            checked={uiState.isSidebarOpen}
            onChange={() => setIsSidebarOpen(!uiState.isSidebarOpen)}
          />
        }
      />
      <Divider length={22} />
      <MenuBarForm form={form} autoComplete="off" onFinish={onFinish}>
        <LabelText>{t["common.searchFilter"]}</LabelText>
        <FormItem name="search">
          <Search placeholder="Search" className="search" onSearch={onSearch}/>
        </FormItem>
        <LabelText>{t["common.saleAndLocale"]}</LabelText>
        <FormItem name="channelLocale">
          <ChannelLocaleSelector onChange={handleChannelLocaleSelect}/>
        </FormItem>
        <FormButton type="link" onClick={handleResetSearch}>
          {t["common.reset"]}
        </FormButton>
        <FormButton htmlType="submit">{t["common.apply"]}</FormButton>
      </MenuBarForm>
    </MenuBarContainer>
  );
};
