import { Button, Col, Row, Select, Space, Typography } from "antd";
import { DetailsOptionTable } from "./detailsOptionTable/DetailsOptionTable";
import { AttributeEntity } from "../../../../models/entities/attributeEntity";
import { MutableRefObject, useCallback, useState } from "react";
import { AttributeTemplate } from "../../../../models/valueObjects/attributeTemplate";
import { DefaultOptionType } from "antd/es/select";
import { TranslationMap } from "../../../../../../features/i18n/translation";
import { AttributeObjectOption } from "../../../../models/valueObjects/attributeObjectOptions";
import { useAttributesStore } from "../../../../state/stores/attributesStore";
import { updateAttributeImmer } from "../../../../state/updateAttribute";
import {
  DetailsOptionValueStyled,
  OptionValueHeader
} from "./DetailsOptionValueStyled";
import { useCommonStore } from "../../../../../../common/domain/state/stores/useCommonStore";
import { GetLocaleResponse } from "../../../../../../common/domain/valueObjects/GetLocaleResponse";
import { Labels } from "../../../../../../common/domain/valueObjects/Label";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

function createEmptyOption(
  copied: AttributeTemplate | null,
  locales: GetLocaleResponse[],
  t: TranslationMap,
) {
  // get shape of options
  const counter = (copied?.options?.length ?? 0) + 1;
  const localesAsOptions = locales.map<Labels>((l) => ({
    locale: l.code,
    value: ""
  }));
  const emptyOption = {
    key: `${t["common.key"]} ${counter}`,
    displayValues: localesAsOptions
  } as AttributeObjectOption;
  const firstOption = (copied?.options && copied.options[0]) || emptyOption;
  Object.entries(firstOption).forEach((entry) => {
    const key = entry[0] as keyof AttributeObjectOption;
    const requiredKeys: (keyof AttributeObjectOption)[] = [
      "key",
      "displayValues"
    ];
    if (requiredKeys.includes(key)) return; // the required fields in `AttributeObjectOption`

    emptyOption[key] = undefined;
  });
  return emptyOption;
}

function getNumberOfValues(
  attribute: AttributeEntity | AttributeTemplate
): number {
  if (!attribute) return 0;
  if (!attribute.options) return 0;
  if (attribute.options === null) return 0;
  return attribute.options.length;
}

/**
 * # On load
 * - Root: Top level is Selection
 * - Root: Top level is Form with nested Selection
 * - Query param in URL: keyPath query param, which should show up as `selectedAttributeName`
 *
 * # Actions on Page
 * ## Attribute name selector
 * - Top level show up:  "xyz"
 * - Nested show up:     "-> xyz"
 * 3. When changing an attribute
 */
export const DetailsOptionValue: React.FC<{
  selectedTemplate: AttributeTemplate;
  selectedAttributeName: DefaultOptionType | undefined;
  options: DefaultOptionType[];
  onTitleChange: MutableRefObject<(attributeName: DefaultOptionType) => void>;
}> = ({
  selectedTemplate,
  selectedAttributeName,
  options,
  onTitleChange
}): JSX.Element => {
  const { locales: loadedLocales, defaultLocale } = useCommonStore().ui;
  const attribute = useAttributesStore().entity.draftAttribute;
  const { ui, entity } = useAttributesStore();
  const { setDraftAttribute } = entity;
  const { t } = useCommonUiStore();

  const [locale, setLocale] = useState<DefaultOptionType>({
    label: defaultLocale?.name,
    value: defaultLocale?.code
  });

  const handleAddValues = useCallback(() => {
    if (!attribute) return;
    const updated = updateAttributeImmer(attribute, null, {
      keyPath: String(selectedAttributeName?.value),
      visistor: (draft) => {
        if (draft.options === null) {
          draft.options = [];
        }
        const emptyOption = createEmptyOption(draft, loadedLocales, t);
        draft.options?.unshift(emptyOption);
        return draft;
      }
    });
    setDraftAttribute(updated);
  }, [selectedTemplate, t]);

  if (!attribute) return <>No attribute (detailsOptionValue.tsx)</>;

  // header

  const localeOptions = loadedLocales.map((local) => ({
    label: local.name,
    value: local.code
  }));

  return (
    <DetailsOptionValueStyled
      data-test={dMap["details-options-value"]}
      className="details-options-value"
    >
      <OptionValueHeader data-test={dMap["options-value-header"]}>
        <Row className="option-value-header-row" justify="space-between">
          <Col>
            <Space>
              <Typography.Title className="header-row-title" level={5}>
                {t["attributeDetails.optionsValue.valueTable"]}
              </Typography.Title>
              <Select
                data-test={dMap["select-title"]}
                popupClassName="select-title"
                className="title-selector"
                placeholder={"title"}
                options={options}
                defaultValue={selectedAttributeName}
                onSelect={(_, attributeName) =>
                  onTitleChange.current(attributeName)
                }
              />
              <Select
                data-test={dMap["select-locale"]}
                className="locale-selector"
                placeholder={"locale"}
                options={localeOptions}
                defaultValue={locale}
                onSelect={(_, newLocale) => setLocale(newLocale)}
              />
            </Space>
          </Col>

          <Col>
            <Space>
              <span>
                {getNumberOfValues(selectedTemplate)} {t["common.values"]}
              </span>
              {ui.isEditing && (
                <Button
                  data-test={dMap["button-add"]}
                  type="primary"
                  onClick={handleAddValues}
                >
                  {t["attributeDetails.optionsValue.addValues"]}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </OptionValueHeader>

      <DetailsOptionTable
        data-test={dMap["table-options-value"]}
        dataSource={selectedTemplate.options}
        locale={locale}
        template={selectedTemplate}
      />
    </DetailsOptionValueStyled>
  );
};
