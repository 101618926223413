import { Asset } from "@shared/entities/Asset";
import BaseApiService from "../../../common/services/ApiService";
import { commonConstants } from "../../../common/constants/commonConstants";
import { AssetType } from "@shared/entities/AssetType";

export type CreateAssetRequest = {
  file: File;
  fileName: string;
  type: AssetType;
  application?: string;
};

export type CreateAssetResponse = Asset[];

export class AssetApiService extends BaseApiService {
  constructor() {
    super("/asset/assets");
  }

  async getAssetUrl(assetId: string): Promise<string> {
    const assetUrlResponse = await this.getFetcher<string>(`/${assetId}/url`);
    return assetUrlResponse;
  }

  async createAsset(
    requests: CreateAssetRequest[],
  ): Promise<CreateAssetResponse> {
    if (!requests.length) {
      throw new Error("Can not upload empty files");
    }

    const formData = new FormData();
    const payload: any[] = [];

    requests.forEach(({ file, ...rest }) => {
      formData.append("files", file);
      payload.push({
        ...rest,
        application: commonConstants.fileApplication,
      });
    });
    formData.append("payload", payload.map((x) => JSON.stringify(x)).join(","));

    return this.postFetcher<FormData, CreateAssetResponse>("", formData);
  }
}

export const assetApiService = new AssetApiService();
