import { Breadcrumb, Button, Col, Row } from "antd";
import "./detailsHeader.scss";
import { useEffect, useMemo, useState } from "react";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { DeleteOutlined } from "@ant-design/icons";
import { useCategoryPageState } from "../../../../domain/hooks/useCategoryPageState";
import { useCommonUiStore } from "../../../../../../common/domain/state/stores/CommonUiStore";

interface Props {
  onUpdateClicked: () => void;
  isUpdating: boolean;
}

export const DetailsHeader: React.FC<Props> = ({
  onUpdateClicked,
  isUpdating
}): JSX.Element => {
  const { t } = useCommonUiStore();
  const [state] = useCategoryPageState();

  const defaultBreadcrumbItem = useMemo(() => ({
    title: t["category.detail.breadcrumb"] // default
  }), [t]);

  const [breadcrumbItems, setBreadcrumbItems] = useState<ItemType[]>([
    defaultBreadcrumbItem
  ]);

  useEffect(() => {
    if (!state.currentCategory || !state.allCategories) {
      return;
    }

    if (!state.currentCategory.categoryPath) {
      setBreadcrumbItems([
        defaultBreadcrumbItem,
        { title: state.currentCategory.name }
      ]);

      return;
    }

    const paths = state.currentCategory.categoryPath.split("/");
    const breadcrumbItems = paths.map((path) => {
      const found = state.allCategories?.find((x) => x.name === path);

      return { title: found?.name };
    });

    setBreadcrumbItems([defaultBreadcrumbItem, ...breadcrumbItems]);
  }, [state.currentCategory, defaultBreadcrumbItem, state]);

  return (
    <div className="details-header">
      <Row>
        <Col span={17}>
          <Breadcrumb items={breadcrumbItems} />
        </Col>
        <Col span={7} className="action-buttons">
          {state.currentCategory?.id === state.baseCategoryId ? null : (
            <Button danger className="delete-button">
              <DeleteOutlined />
            </Button>
          )}

          <Button type="primary" loading={isUpdating} onClick={onUpdateClicked}>
            {t["category.header.update"]}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DetailsHeader;
