import { useCallback, useContext, useMemo } from "react";
import { BaseInputProps } from "../types";
import { styled } from "styled-components";
import { Button } from "antd";
import DynamicInput from "./DynamicInput";
import FormSettingContext from "../context/FormSettingContext";
import { ItemContainer } from "./ItemContainer";
import Label from "./Label";
import { DeleteOutlined } from "@ant-design/icons";
import { ValidationFunction, validationIsRequiredArray } from "./rules";
import useValidation from "../hooks/useValidation";
import ErrorText from "./ErrorText";
import useFormWideValidator from "../hooks/useFormWideValidator";
import { useCommonUiStore } from "../../../../../common/domain/state/stores/CommonUiStore";

const RemoveButton = styled(Button)`
  border: none;
`;

const ArrayItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 20px;

  .container-input {
    flex: 1;
  }
`

export default function InputArray(props: BaseInputProps) {
  const { template, value, onUpdate, isRequired } = props;
  const { t } = useCommonUiStore();

  const updateArrayByIndex = useCallback(
    (index: number, fieldValue: any) => {
      if (Array.isArray(value)) {
        const newValue = value.map((v, idx: number) =>
          idx === index ? fieldValue : v,
        );
        onUpdate(newValue);
      } else {
        onUpdate([fieldValue]);
      }
    },
    [onUpdate, value],
  );

  const formSetting = useContext(FormSettingContext);
  const { editMode } = formSetting;

  const removeArrayItem = useCallback(
    (index: number) => {
      if (Array.isArray(value)) {
        const newValue = value.filter((v, idx: number) => idx !== index);
        onUpdate(newValue);
      } else {
        onUpdate([]);
      }
    },
    [value, onUpdate],
  );

  const items = useMemo(() => {
    if (!value || !Array.isArray(value)) return null;
    if (!template) return null;

    // use new template isMultiple=false
    const nonArrayTemplate = { ...template, isMultipleValues: false };

    return value.map((x: any, idx: number) => {
      return (
        <ArrayItem key={`key-${idx}`}>
          <div className="container-input">
            <DynamicInput
              onUpdate={(v) => updateArrayByIndex(idx, v)}
              value={x}
              template={nonArrayTemplate}
              isArrayItem
              isRequired={isRequired}
              key={`arr-${idx}`}
            />
          </div>
          {formSetting.editMode ? (
            <RemoveButton onClick={() => removeArrayItem(idx)} icon={<DeleteOutlined />} title={t["common.remove"]}/>
          ) : null}
        </ArrayItem>
      );
    });
  }, [value, formSetting, removeArrayItem, template, updateArrayByIndex, isRequired, t]);

  const addArrayItem = useCallback(() => {
    if (Array.isArray(value)) {
      const newValue = value.concat([null]);
      onUpdate(newValue);
    } else {
      onUpdate([null]);
    }
  }, [value, onUpdate]);

  const validationRules = useMemo(() => {
    if (!editMode) return [];
    const rules: ValidationFunction[] = [];
    if (isRequired) rules.push(validationIsRequiredArray(t["common.requireInputMessage"]))
    return rules;
  }, [isRequired, editMode, t]);

  const validationResult = useValidation(value, validationRules, formSetting.showValidationError);
  useFormWideValidator(editMode, validationResult.isValid, validationResult.errorMessages);

  if (!template) return null;

  return (
    <ItemContainer>
      <Label template={template} isRequired={isRequired}/>
      <ErrorText messages={validationResult.errorMessages} status={validationResult.status}/>
      {items}
      <div>
        {editMode ? (
          <Button onClick={addArrayItem} type="primary">Add new</Button>
        ) : null}
      </div>
    </ItemContainer>
  );
}
