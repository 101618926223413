import deepmerge from "deepmerge";
import { useEffect } from "react";
import { useAttributesStore } from "../../../state/stores/attributesStore";
import { AttributeDetails } from "./AttributeDetailsPage";
import { apiService } from "../../../api/ApiService";
import { useParams } from "react-router-dom";
import { addIdsToTemplates } from "../../../common/helpers/addIdsToTemplates";
import { useUpdateAttributeWithImmer } from "../../../state/atoms/attributeAtomsImmer";
import { AttributeEntity } from "../../../models/entities/attributeEntity";
import { Form } from "antd";
import { debounce } from "../../../../../common/modules/debounce";
import { attributeConstants } from "@am/common/constants/constants";
import { updateAttributeImmer } from "@am/state/updateAttribute";
import { FormProviderProps } from "antd/es/form/context";
import { combineMerge } from "../../../../../common/modules/combineMerge";

function overwritePayload(attributeEntity: AttributeEntity) {
  if (attributeEntity.inputSettings?.selectionType === "multiple") {
    attributeEntity.isMultipleValues = true;
  }
  return attributeEntity;
}

export const AttributeDetailsController: React.FC = (): JSX.Element => {
  const { entity, ui } = useAttributesStore();
  const { draftAttribute, setDraftAttribute } = entity;
  const { isEditing, setIsEditing, lastButtonClick, setLastButtonClick } = ui;
  const { id: attributeName } = useParams();
  const updateAttribute = useUpdateAttributeWithImmer();
  
  const onFormFinish = debounce<FormProviderProps["onFormFinish"]>(
    async (_, { forms }) => {
      let draft: AttributeEntity | null = draftAttribute;
      const isHavingDraft = draft?.isHavingDraft;
      Object.entries(forms).forEach(([treeKey, form]) => {
        const denyList = [attributeConstants.forms["add-new-input-popover"]];
        if (denyList.includes(treeKey)) return;

        const { labels, ...updated } = form.getFieldsValue(
          true
        ) as AttributeEntity; // workaround: labels is set separately - todo: use nested antd form properly?
        draft = updateAttributeImmer(draft, updated, {
          keyPath: treeKey
        });
      });
      // here creates hoisting, just a workaround, update needed!
      if (draft && isHavingDraft) draft.isHavingDraft = isHavingDraft;
      setDraftAttribute(draft);
    },
    attributeConstants.code.debounceTime,
    true
  );

  useEffect(() => {
    if (!draftAttribute) return;
    if (!isEditing) return;

    const withIds = addIdsToTemplates(draftAttribute);
    setDraftAttribute(withIds);
  }, [isEditing, draftAttribute, setDraftAttribute]);

  useEffect(() => {
    (async () => {
      if (draftAttribute) return;
      const response = await apiService.getAttributeByName(attributeName, "edit");
      setDraftAttribute(response);
    })();
  }, [attributeName, draftAttribute]);

  useEffect(() => {
    if (!draftAttribute) return;
    if (!lastButtonClick) return;

    (async function () {
      if (lastButtonClick === "saveDraft" || lastButtonClick === "publish") {
        const updated = overwritePayload(draftAttribute);
        const response = await apiService.putAttribute(
          draftAttribute.id,
          updated
        );

        if (lastButtonClick === 'publish') {
          await apiService.publishAttribute(draftAttribute.id);
          setDraftAttribute(null);
          setIsEditing(false);
          return;
        }

        /**
         * From draft keep the ids.
         * From response  get update timestamp
         */
        const deepMerged = deepmerge(draftAttribute, response, {
          arrayMerge: combineMerge
        });
        setDraftAttribute(deepMerged);
        updateAttribute(draftAttribute?.id, response);
        setIsEditing(false);
      } else if (lastButtonClick === 'deleteDraft') {
        await apiService.deleteAttributeDraft(draftAttribute.id);
        // need to refresh
        setDraftAttribute(null);
      }

    })();
    setLastButtonClick(null);
  }, [lastButtonClick]);

  if (!draftAttribute) return <>Loading...</>;

  return (
    <Form.Provider onFormFinish={onFormFinish}>
      <AttributeDetails attributeEntity={draftAttribute}></AttributeDetails>;
    </Form.Provider>
  );
};
