import { atom } from "jotai";
import {
  ApiSearchParam,
  ApiSortParam
} from "../../../common/types/commonTypes";
import { FilterValue } from "antd/es/table/interface";

export interface ProductListState {
  selectedCategoryIds: string[];
  selectedLocale: string;
  selectedChannel: string | null;
  searchProductNameValue: ApiSearchParam[];
  tablePage: number;
  tablePageSize: number;
  tableFilters: Record<string, FilterValue | null>;
  tableSorts: ApiSortParam[];
}

export const productListStateAtom = atom<ProductListState>({
  searchProductNameValue: [],
  selectedCategoryIds: [],
  selectedLocale: "",
  selectedChannel: null,
  tablePage: 1,
  tablePageSize: 10,
  tableFilters: {},
  tableSorts: []
});
