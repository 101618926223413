import { Button, Form, Table } from "antd";
import "./attributesTable.scss";
import { ColumnsType } from "antd/es/table";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TemplateSetting } from "../../../../domain/valueObjects/templateSetting";
import { SettingInputType } from "../../../../domain/valueObjects/settingInputType";
import ToggleColumnInput from "../../../molecules/toggleColumnInput/ToggleColumnInput";
import SelectionColumnInput from "../../../molecules/selectionColumnInput/SelectionColumnInput";
import { AinIcon } from "../../../../../../common/ui/atoms/AinIcon";
import React from "react";
import ImportCategoriesModal from "../../modals/importCategoriesModal/ImportCategoriesModal";
import { useCategoryPageState } from "../../../../domain/hooks/useCategoryPageState";
import cloneDeep from "../../../../../../common/modules/cloneDeep";
import { CategoryAttribute } from "@cm/domain/valueObjects/categoryAttribute";
import deepMergeOverwriteArray from "../../../../../../common/modules/deepMergeOverwriteArray";
import { dMap } from "@shared/helpers/testing/dataTestSelectorMap";
import { CategoryTemplate } from "@cm/domain/valueObjects/categoryTemplate";
import { attributesRouteConstants } from "@am/attributeRoutes";
import { Link } from "react-router-dom";
import AddGroupButton from "../addGroupButton/AddGroupButton";
import { Labels } from "../../../../../../common/domain/valueObjects/Label";
import { useCommonStore } from "../../../../../../common/domain/state/stores/useCommonStore";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { DraggableRow } from "./DraggableRow";

interface Props {}

interface TableDataType {
  key: React.ReactNode;
  name: string;
  attributeName?: string;
  categoryId: string;
  children?: TableDataType[];
  totalColumn: number;
  group?: string;
  attributeCount?: number;
  displayOrder?: number;
  [key: string]: any;
}

const sortFunction = (a: any, b: any, fieldName: string) => {
  if (a.children) {
    return 0;
  }

  const aIsString = typeof a[fieldName] === "string";
  const bIsString = typeof b[fieldName] === "string";
  if (aIsString || bIsString) {
    const valueA = aIsString ? a[fieldName] : "";
    const valueB = bIsString ? b[fieldName] : "";
    return valueA.localeCompare(valueB);
  }

  if (Array.isArray(a[fieldName]) || Array.isArray(b[fieldName])) {
    return (a[fieldName] ?? []).length - (b[fieldName] ?? []).length;
  }

  return a[fieldName] - b[fieldName];
};

const renderExpandIcon = ({ expanded, onExpand, record }: any) => {
  if (!record.children) {
    return null;
  }

  return expanded ? (
    <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
  ) : (
    <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
  );
};

const countSubStrings = (mainStr: string, subStr: string) => {
  let count = 0;
  let position = mainStr.indexOf(subStr);
  while (position !== -1) {
    count++;
    position = mainStr.indexOf(subStr, position + 1);
  }

  return count;
};

const moveRowInDataSource = (
  source: TableDataType[],
  key: string,
  moveSize: number
): boolean => {
  for (let index = 0; index < source.length; index++) {
    const element = source[index];
    if (element.key === key) {
      const newIndex = index + moveSize;

      // check to make sure the moving attribute in the same group.
      if (newIndex >= 0 && newIndex < source.length) {
        source.splice(index, 1)[0];
        source.splice(index + moveSize, 0, element);
      }
      return true;
    }

    if (element.children?.length) {
      if (moveRowInDataSource(element.children, key, moveSize)) {
        return true;
      }
    }
  }

  return false;
};

const findAttributeRowKey = (
  source: TableDataType[],
  attributeName: string
): string => {
  for (let index = 0; index < source.length; index++) {
    const element = source[index];

    if (element.attributeName === attributeName) {
      return element.key as string;
    }

    if (element.children?.length) {
      const key = findAttributeRowKey(element.children, attributeName);

      if (key) {
        return key;
      }
    }
  }

  return "";
};

const resolveOrder = (data: TableDataType) => {
  const groupVsGroup = !data.children?.length
    ? -1
    : data.children[0].displayOrder ?? -1;
  const groupVsAttributeNotInGroup = data.group ? groupVsGroup : -1;
  const attributeVsAttribute = data.displayOrder ?? groupVsAttributeNotInGroup;

  return attributeVsAttribute;
};

const DragHandle = (props: any) => {
  return (
    <div {...props} className="drag-handler">
      <AinIcon icon="drag-outlined" size={16} />
    </div>
  );
};

export const AttributesTable: React.FC<Props> = (): JSX.Element => {
  const [form] = Form.useForm();
  const [state, setState] = useCategoryPageState();
  const [dataSource, setDataSource] = useState<TableDataType[]>();
  const [columns, setColumns] = useState<ColumnsType<TableDataType>>([]);
  const importCategoriesModal = useRef<any>(null);
  const cachedDataSource = useRef<TableDataType[]>();
  const allSettings = useRef<TemplateSetting[]>();
  const allAttributeNames = useRef<string[]>([]);
  const {
    ui: { defaultLocale, t }
  } = useCommonStore();

  const resolveColumnInput = (
    setting: TemplateSetting,
    value: any,
    disabled: boolean,
    formItemName: string[]
  ) => {
    switch (setting.inputType) {
      case SettingInputType.Toggle:
        return (
          <ToggleColumnInput
            disabled={disabled}
            value={value}
            form={form}
            formItemName={formItemName}
          />
        );

      case SettingInputType.Selection:
        return (
          <SelectionColumnInput
            disabled={disabled}
            value={value}
            isMultiple={setting.isMultipleValues}
            options={setting.options}
            form={form}
            formItemName={formItemName}
          />
        );

      default:
        return value;
    }
  };

  const handleDeleteAttribute = useCallback(
    (record: TableDataType) => {
      const newAttributes: string[] = [];

      cachedDataSource.current = cachedDataSource.current!.map((data) => {
        data.children = data.children!.filter((child) => {
          if (child.children) {
            if (record.key === child.key && record.group === child.group) {
              return false;
            }

            child.children = child.children.filter((x) => {
              if (x.key !== record.key) {
                newAttributes.push(x.attributeName!);
                return true;
              }
            });
          } else {
            if (child.key === record.key) {
              return false;
            }

            newAttributes.push(child.attributeName!);
          }

          return true;
        });

        return data;
      });

      const attributes = state.updatedCategory!.template.attributes.filter(
        (x) => newAttributes.includes(x.name)
      );
      const templates = state.updatedCategory!.templates.map((template) => {
        if (template.categoryId !== state.currentCategory?.id) {
          return template;
        }

        return {
          ...template,
          attributes: template.attributes.filter((x) =>
            newAttributes.includes(x.name)
          )
        };
      });

      const newGroups = { ...state.updatedCategory?.groups };
      if (record.group) {
        delete newGroups[record.group];
      }

      const tempUpdatedCategory = {
        ...state.updatedCategory,
        groups: newGroups,
        templates,
        template: {
          ...state.updatedCategory?.template,
          attributes
        }
      };

      setState({
        updatedCategory: tempUpdatedCategory
      });
      setDataSource(cachedDataSource.current);
      resolveAllAttributeNames(templates);
    },
    [cachedDataSource, state.updatedCategory?.template]
  );

  const stateRef = useRef<any | null>(null);
  stateRef.current = state;

  const handleAddNewGroup = (name: string, labels: Labels[]) => {
    const currentGroups = stateRef.current.updatedCategory!.groups ?? {};
    if (currentGroups[name]) {
      return;
    }

    const groups = {
      ...(stateRef.current.updatedCategory!.groups ?? {}),
      [name]: labels
    };

    const tempCategory = {
      ...stateRef.current.updatedCategory,
      groups
    };

    setState({
      currentCategory: { ...tempCategory },
      updatedCategory: { ...tempCategory }
    });
  };

  const renderCategoryCell = useCallback(
    (value: string, record: TableDataType) => {
      if (!record.children) {
        return (
          <span className="attribute-name">
            {record.categoryId !== state.currentCategory?.id ? null : (
              <>
                <DragHandle
                  {...(record as any).attributes}
                  {...(record as any).listeners}
                />
                <span
                  className="delete"
                  onClick={() => handleDeleteAttribute(record)}
                >
                  <AinIcon icon="delete-outlined-gray" size={16} />
                </span>
              </>
            )}

            <Link
              className="value"
              to={`/${attributesRouteConstants.attributes}/${record.attributeName}/config`}
              target="_blank"
            >
              {value}
            </Link>
          </span>
        );
      }

      return (
        <div className={`expander-cell ${record.group ? "group" : ""}`}>
          <span>
            {!record.group ||
            record.categoryId !== state.currentCategory?.id ? null : (
              <>
                <DragHandle
                  {...(record as any).attributes}
                  {...(record as any).listeners}
                />
                <span
                  className="delete"
                  onClick={() => handleDeleteAttribute(record)}
                >
                  <AinIcon icon="delete-outlined-gray" size={16} />
                </span>
              </>
            )}
            <span className="category-name">{value}</span>
            <span className="category-action">
              {record.group ? null : (
                <span>
                  {`${record.children.filter((x) => x.group)?.length || 0} ${
                    t["category.details.countGroups"]
                  } | `}
                </span>
              )}
              <span>
                {`${record.attributeCount ?? record.children?.length ?? 0} ${
                  t["category.details.countAttributes"]
                }`}
              </span>

              {record.categoryId !== state.currentCategory?.id ? null : (
                <>
                  {record.group ? null : (
                    <AddGroupButton onAddNewGroup={handleAddNewGroup} />
                  )}
                  <Button
                    data-test={dMap["button-import"]}
                    size="small"
                    onClick={() =>
                      importCategoriesModal.current?.open(
                        allAttributeNames.current,
                        record.group
                      )
                    }
                  >
                    {t["category.details.importAttributes"]}
                  </Button>
                </>
              )}
            </span>
          </span>
        </div>
      );
    },
    [t, state.currentCategory?.id]
  );

  const defaultColumns = useMemo(
    () => [
      {
        title: t["category.common.attributes"],
        dataIndex: "name",
        key: "name",
        onCell: (record: TableDataType) => ({
          className: `custom-cell ${record.children ? "category-cell" : ""} ${
            record.categoryId === state.currentCategory?.id ? "" : "disabled"
          }`,
          colSpan: record.children ? record.totalColumn : 1
        }),
        render: renderCategoryCell,
        sorter: (a: TableDataType, b: TableDataType) =>
          sortFunction(a, b, "name")
      },
      {
        title: t["common.required"],
        dataIndex: "isRequired",
        key: "isRequired",
        onCell: (record: TableDataType) => ({
          className: "custom-cell",
          colSpan: record.children ? 0 : 1
        }),
        render: (value: boolean, record: TableDataType) => {
          const formItemName = [record.attributeName!, "isRequired"];
          const disabled = record.categoryId !== state.currentCategory?.id;

          return (
            <ToggleColumnInput
              disabled={disabled}
              value={value}
              form={form}
              formItemName={formItemName}
            />
          );
        },
        sorter: (a: TableDataType, b: TableDataType) =>
          sortFunction(a, b, "isRequired")
      },
      {
        title: t["common.variantDependent"],
        dataIndex: "isVariantDependent",
        key: "isVariantDependent",
        onCell: (record: TableDataType) => ({
          className: "custom-cell",
          colSpan: record.children ? 0 : 1
        }),
        render: (value: boolean, record: TableDataType) => {
          const formItemName = [record.attributeName!, "isVariantDependent"];
          const disabled = record.categoryId !== state.currentCategory?.id;

          return (
            <ToggleColumnInput
              disabled={disabled}
              value={value}
              form={form}
              formItemName={formItemName}
            />
          );
        },
        sorter: (a: TableDataType, b: TableDataType) =>
          sortFunction(a, b, "isVariantDependent")
      },
      {
        title: t["common.variantKey"],
        dataIndex: "isVariantKey",
        key: "isVariantKey",
        onCell: (record: TableDataType) => ({
          className: "custom-cell",
          colSpan: record.children ? 0 : 1
        }),
        render: (value: boolean, record: TableDataType) => {
          const formItemName = [record.attributeName!, "isVariantKey"];
          const disabled = record.categoryId !== state.currentCategory?.id;

          return (
            <ToggleColumnInput
              disabled={disabled}
              value={value}
              form={form}
              formItemName={formItemName}
            />
          );
        },
        sorter: (a: TableDataType, b: TableDataType) =>
          sortFunction(a, b, "isVariantKey")
      }
    ],
    [t, state.currentCategory?.id]
  );

  const buildDataSource = () => {
    cachedDataSource.current = state.currentCategory!.templates.map(
      (template) => {
        const children = template.attributes.reduce(
          (previous: TableDataType[], attribute) => {
            const baseData: TableDataType = {
              key: `${template.categoryName}|-|${attribute.name}`,
              name: attribute.name,
              attributeName: attribute.name,
              categoryId: template.categoryId,
              totalColumn: columns.length,
              isRequired: attribute.isRequired,
              isVariantDependent: attribute.isVariantDependent,
              isVariantKey: attribute.isVariantKey,
              displayOrder: attribute.displayOrder ?? undefined,
              ...attribute.settings
            };

            if (!attribute.group) {
              // Add attribute not in group
              previous.push(baseData);
              return previous;
            }

            baseData.group = attribute.group;
            baseData.key = `${template.categoryName}|${attribute.group}|${attribute.name}`;
            const existing = previous.find((x) => x.group === attribute.group);
            const groups = state.currentCategory?.groups ?? {};

            if (existing) {
              // Add attribute into a existing group
              existing.children?.push(baseData);
              existing.children?.sort(
                (a, b) => resolveOrder(a) - resolveOrder(b)
              );
              return previous;
            }

            // Initial a new group with first attribute
            previous.push({
              key: `${template.categoryName}|${attribute.group}`,
              name:
                groups[attribute.group]?.find(
                  (x) => x.locale === defaultLocale?.code
                )?.value || attribute.group,
              categoryId: template.categoryId,
              totalColumn: columns.length,
              group: attribute.group,
              children: [baseData]
            });
            return previous;
          },
          []
        );

        // Add them empty groups to children
        if (template.categoryId === state.currentCategory?.id) {
          for (const groupName in state.currentCategory?.groups) {
            const labels = state.currentCategory?.groups[groupName];
            if (children.every((x) => x.group !== groupName)) {
              children.push({
                key: `${template.categoryName}|${groupName}`,
                name:
                  labels?.find((x) => x.locale === defaultLocale?.code)
                    ?.value || groupName,
                categoryId: template.categoryId,
                totalColumn: columns.length,
                group: groupName,
                children: []
              });
            }
          }
        }

        children.sort((a, b) => resolveOrder(a) - resolveOrder(b));

        return {
          key: template.categoryId,
          name: template.categoryName,
          categoryId: template.categoryId,
          totalColumn: columns.length,
          attributeCount: template.attributes.length,
          children
        };
      }
    );

    return cachedDataSource.current;
  };

  const buildColumns = () => {
    const columns: ColumnsType<TableDataType> = allSettings.current!.map(
      (setting) => ({
        title:
          setting.labels?.find((x) => x.locale === defaultLocale?.code)
            ?.value || setting.name,
        dataIndex: setting.name,
        key: setting.name,
        onCell: (record) => ({
          className: "custom-cell",
          colSpan: record.children ? 0 : 1
        }),
        render: (value, record) => {
          const formItemName = [
            record.attributeName!,
            "settings",
            setting.name
          ];
          const disabled = record.categoryId !== state.currentCategory?.id;
          return resolveColumnInput(setting, value, disabled, formItemName);
        },
        sorter: (a, b) => sortFunction(a, b, setting.name)
        // TODO: maybe need to support filter for selection input type
        // filters: Object.entries(SettingRelation).map(([key, relation]) => ({
        //   text: key,
        //   value: relation,
        // })),
        // onFilter: (filterValue, record) => {
        //   return true;
        // },
      })
    );

    return [...defaultColumns, ...columns];
  };

  const resolveAllAttributeNames = (templates: CategoryTemplate[]) => {
    allAttributeNames.current = templates.reduce(
      (previous: string[], current) => {
        if (!current.attributes?.length) {
          return previous;
        }

        current.attributes.forEach((attribute) => {
          if (previous.every((x) => x !== attribute.name)) {
            previous.push(attribute.name);
          }
        });

        return previous;
      },
      []
    );
  };

  useEffect(() => {
    if (!state.currentCategory || !state.allCategories) {
      return;
    }

    const templates = [...state.currentCategory!.templates];
    allSettings.current = templates
      .reverse()
      .reduce((previous: TemplateSetting[], current) => {
        if (!current.settings?.length) {
          return previous;
        }

        current.settings.forEach((setting) => {
          if (previous.every((x) => x.name !== setting.name)) {
            previous.push(setting);
          }
        });

        return previous;
      }, []);

    setColumns(buildColumns());
    resolveAllAttributeNames(
      state.updatedCategory
        ? state.updatedCategory.templates
        : state.currentCategory.templates
    );
  }, [state.currentCategory, state.allCategories]);

  useEffect(() => {
    if (!columns.length) {
      return;
    }

    setDataSource(buildDataSource());
  }, [columns]);

  const handleValuesChanged = (values: any) => {
    const attributeName = Object.keys(values)[0];

    const attributes = state.updatedCategory!.template.attributes.map(
      (attribute) => {
        if (attribute.name !== attributeName) {
          return { ...attribute };
        }

        return deepMergeOverwriteArray(attribute, values[attributeName]);
      }
    );
    const templates = cloneDeep(state.updatedCategory!.templates);
    templates.forEach((template) => {
      if (template.categoryId !== state.currentCategory?.id) {
        return;
      }

      template.attributes = template.attributes.map((attribute) => {
        if (attribute.name !== attributeName) {
          return { ...attribute };
        }

        return deepMergeOverwriteArray(attribute, values[attributeName]);
      });
    });

    const tempUpdatedCategory = {
      ...state.updatedCategory,
      templates,
      template: {
        ...state.updatedCategory?.template,
        attributes
      }
    };
    setState({
      currentCategory: tempUpdatedCategory,
      updatedCategory: tempUpdatedCategory
    });
  };

  const handleImportAttributes = (attributeNames: string[], group?: string) => {
    const newAttributes: CategoryAttribute[] = [];
    const newSettings: any = {};
    const attributes: CategoryAttribute[] = [
      ...state.updatedCategory!.template.attributes
    ];

    allSettings.current?.forEach((setting) => {
      if (setting.default !== undefined || setting.default !== null) {
        newSettings[setting.name] = setting.default;
      }
    });
    attributeNames.forEach((attribute, i) => {
      if (allAttributeNames.current.every((x) => x !== attribute)) {
        newAttributes.push({
          name: attribute,
          isRequired: false,
          isVariantDependent: false,
          isVariantKey: false,
          settings: newSettings,
          group,
          displayOrder: group
            ? attributes.filter((x) => x.group === group).length + i
            : attributes.length + i
        });
      }
    });
    attributes.push(...newAttributes);

    const templates = state.updatedCategory!.templates.map((template) => {
      if (template.categoryId !== state.currentCategory?.id) {
        return template;
      }

      return {
        ...template,
        attributes: [...template.attributes, ...newAttributes].map((x) => ({
          ...x,
          displayOrder: attributes.find((a) => a.name === x.name)?.displayOrder
        }))
      };
    });

    const tempCategory = {
      ...state.updatedCategory,
      template: {
        ...state.updatedCategory?.template,
        attributes
      },
      templates
    };

    setState({
      currentCategory: { ...tempCategory },
      updatedCategory: { ...tempCategory }
    });
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {})
  );

  const sortItemKeys = useMemo(() => {
    const currentCategoryChildren = dataSource?.find(
      (x) => x.categoryId === state.currentCategory?.id
    )?.children;

    if (!currentCategoryChildren?.length) {
      return [];
    }

    const result: string[] = [];
    const attributesInGroup: string[] = [];
    const attributesNotInGroup: string[] = [];

    currentCategoryChildren.forEach((x) => {
      if (x.children) {
        result.push(x.key as string);
        attributesInGroup.push(...x.children.map((x) => x.key as string));
      } else {
        attributesNotInGroup.push(x.key as string);
      }
    });

    result.push(...attributesInGroup);
    result.push(...attributesNotInGroup);

    return result;
  }, [dataSource]);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (!cachedDataSource.current || active.id === over.id) {
      return;
    }

    // check to make sure the moving attribute/group in the same level.
    if (
      countSubStrings(active.id, "|") !== countSubStrings(over.id, "|") ||
      countSubStrings(active.id, "|-|") !== countSubStrings(over.id, "|-|")
    ) {
      return;
    }

    const oldIndex = sortItemKeys.indexOf(active.id);
    const newIndex = sortItemKeys.indexOf(over.id);
    moveRowInDataSource(
      cachedDataSource.current,
      active.id,
      newIndex - oldIndex
    );

    cachedDataSource.current = cloneDeep(cachedDataSource.current);
    setDataSource(cachedDataSource.current);
  };

  useEffect(() => {
    if (!dataSource) {
      return;
    }

    const currentCategoryChildren = dataSource.find(
      (x) => x.categoryId === state.currentCategory?.id
    )!.children!;
    const attributes = state.updatedCategory?.template.attributes?.map((x) => ({
      ...x,
      displayOrder: sortItemKeys.indexOf(
        findAttributeRowKey(currentCategoryChildren, x.name)
      )
    }));

    const templates = cloneDeep(state.updatedCategory!.templates);
    templates.forEach((template) => {
      if (template.categoryId !== state.currentCategory?.id) {
        return;
      }

      template.attributes = template.attributes.map((attribute) => ({
        ...attribute,
        displayOrder:
          attributes?.find((x) => x.name === attribute.name)?.displayOrder ??
          null
      }));
    });

    const tempUpdatedCategory = {
      ...state.updatedCategory,
      templates,
      template: {
        ...state.updatedCategory?.template,
        attributes
      }
    };

    setState({
      updatedCategory: tempUpdatedCategory
    });
  }, [sortItemKeys]);

  return (
    <Form
      className="attributes-table"
      name="category-attributes"
      form={form}
      onValuesChange={handleValuesChanged}
    >
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={sortItemKeys}
          strategy={verticalListSortingStrategy}
        >
          <Table
            bordered
            rowKey="key"
            pagination={false}
            columns={columns}
            dataSource={dataSource}
            loading={!dataSource}
            components={{
              body: {
                row: DraggableRow
              }
            }}
            expandable={{
              expandIcon: renderExpandIcon,
              defaultExpandedRowKeys: [state.currentCategory!.id]
            }}
          />
        </SortableContext>
      </DndContext>
      <ImportCategoriesModal
        ref={importCategoriesModal}
        onImportAttribute={handleImportAttributes}
      />
    </Form>
  );
};

export default AttributesTable;
