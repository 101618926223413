import { createContext } from "react";

export interface ValidationEntry {
  isValid: boolean,
  path: string; // location of the validation entry, unused for now
  errorMessages?: string[];
}

export type FnUpdateValidation = (isValid: boolean, path: string, errorMessages?: string[]) => void;

export interface ValidationUpdater {
  unregister: () => void;
  update: FnUpdateValidation;
}

export interface FormValidation {
  validationEntries: Record<string, ValidationEntry>;
  register: () => ValidationUpdater | null;
}

export default createContext<FormValidation>({
  validationEntries: {},
  register: () => null,
});
