import { Form, Input, InputRef } from "antd";
import "./tableEditableCell.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { EditableContext } from "../tableEditableRow/TableEditableRow";
import { useCommonUiStore } from "../../../../../common/domain/state/stores/CommonUiStore";

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  dataIndex: string;
  record: any;
  handleSaveCell: (record: any) => void;
}

export const TableEditableCell: React.FC<Props> = ({
  title,
  children,
  dataIndex,
  record,
  handleSaveCell,
  ...restProps
}): JSX.Element => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  const { t } = useCommonUiStore();

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    const values = await form.validateFields();

    toggleEdit();
    handleSaveCell({ ...record, ...values });
  };

  let childNode = children;

  childNode = editing ? (
    <Form.Item
      className="table-editable-cell"
      name={dataIndex}
      rules={[
        {
          required: true,
          message: t["category.common.requiredMessage"].replace(
            "{0}",
            title as string
          )
        }
      ]}
    >
      <Input ref={inputRef} onPressEnter={save} onBlur={save} />
    </Form.Item>
  ) : (
    <div
      className={dataIndex ? "table-editable-cell" : undefined}
      onClick={toggleEdit}
    >
      {children}
    </div>
  );

  return <td {...restProps}>{childNode}</td>;
};

export default TableEditableCell;
