import { RouteObject } from "react-router-dom";
import { routeConstants } from "./routeConstants";
import { productRoutes } from "../../apps/productManagement/productRoutes";
import CategoryPage from "../../apps/categoriesManagement/ui/pages/categoryPage/CategoryPage";
import { attributeRoutes } from "../../apps/attributesManagement/attributeRoutes";
import { AppController } from "../../AppController";

const categoriesRouter = {
  path: routeConstants.categories,
  element: <CategoryPage />
};

const categoryDetailsRouter = {
  path: `${routeConstants.categoryDetail}/*`,
  element: <CategoryPage />
};

const appRoutes: RouteObject[] = [
  {
    path: "",
    element: <AppController />,
    // element: <AttributeListing />,
    // errorElement: <ErrorPage />,
    children: [
      ...attributeRoutes,
      categoriesRouter,
      categoryDetailsRouter,
      ...productRoutes // <-- this is the PIM app
      // {
      //   children: [
      //     {
      //       path: PORTAL_ROUTE_CONSTANTS.brandsManagementApp,
      //       children: BrandMangementRoutes,
      //     },
      //   ],
      // },
    ]
  }
];

export default appRoutes;
