import { useCallback, useMemo } from "react";
import { BaseInputProps } from "../types";
import { styled } from "styled-components";
import DynamicInput from "./DynamicInput";
import Label from "./Label";
import { ItemContainer } from "./ItemContainer";

const ObjectInputContainer = styled.div`
  padding: 16px 24px;
  border: 1px solid #e8e8e8;
  margin-bottom: 24px;
  transition: border 0.3s ease;
  &:hover {
    border-color: #aaa;
  }
`;

export default function InputObject(props: BaseInputProps) {
  const { template, value, onUpdate, isArrayItem, isRequired } = props;

  const templates = useMemo(() => template?.template || [], [template]);

  const updateObjectField = useCallback(
    (field: string, fieldValue: any) => {
      const newValue = { ...value, [field]: fieldValue };
      onUpdate(newValue);
    },
    [onUpdate, value],
  );

  // render all fields inside template
  const items = useMemo(() => {
    return templates.map((x) => {
      const field = x.name;
      const fieldValue = (value ?? {})[field];
      const required = isRequired && x.isNullable === false;
      return (
        <DynamicInput
          key={field}
          template={x}
          value={fieldValue}
          onUpdate={(v) => updateObjectField(field, v)}
          isRequired={required}
        />
      );
    });
  }, [templates, updateObjectField, value, isRequired]);

  if (!template) return null;

  return (
    <ItemContainer>
      {isArrayItem ? null : <Label template={template} isRequired={isRequired} />}
      <ObjectInputContainer>{items}</ObjectInputContainer>
    </ItemContainer>
  );
}
